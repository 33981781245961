<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">

      <div class="row mt-3">

        <div class="col pe-0">
          <div class="card">
            <div class="card-body">

              <div class="col-md-12 col-12">

                <h3>GPT Prompts</h3>

                <ul class="nav nav-tabs" v-if="promptTypes">
                  <li v-for="item in promptTypes" class="nav-item">
                    <a
                      href="#"
                      class="nav-link"
                      :class="{ active: item.id == promptType.id }"
                      @click.prevent="setPromptType(item)"
                    >
                      {{ item.name }}
                    </a>
                  </li>
                </ul>

                <div class="mt-3" v-if="!isLoading">
                  <div class="form-group mb-3">
                    <label for="prompt_startup">Startup:</label>
                    <p v-if="isGeneric">
                      <strong>Not Applicable</strong>
                      (this is a global prompt and not startup-specific)
                    </p>
                    <p v-else>
                      <strong>{{ startup.name }}</strong> &nbsp;
                      <router-link :to="{ name: 'startups_selector' }" class="small">[Switch Startup]</router-link>
                    </p>
                  </div>

                  <template v-if="promptType.id === 'pitch_deck_analysis'">
                    <div class="form-group mb-3">
                      <label for="id_system_prompt">Prompt:</label>
                      <div class="form-group mb-3">
                        <textarea
                          id="id_system_prompt"
                          type="text"
                          name="system_prompt"
                          class="form-control"
                          rows="10"
                          required
                          v-model="form.system_prompt"
                        >
                        </textarea>
                      </div>
                    </div>
                  </template>

                  <template v-else>
                    <div class="form-group mb-3">
                      <label for="id_system_prompt">System Prompt:</label>
                      <div class="form-group mb-3">
                        <textarea
                          id="id_system_prompt"
                          type="text"
                          name="system_prompt"
                          class="form-control"
                          rows="10"
                          required
                          v-model="form.system_prompt"
                        >
                        </textarea>
                      </div>
                    </div>

                    <div class="form-group mb-3">
                      <label for="id_user_prompt">User Prompt:</label>
                      <div class="form-group mb-3">
                        <textarea
                          id="id_user_prompt"
                          type="text"
                          name="user_prompt"
                          class="form-control"
                          rows="10"
                          required
                          v-model="form.user_prompt"
                        >
                        </textarea>
                      </div>
                    </div>
                  </template>
                </div>

                <div v-else class="text-center my-4">
                  <loader></loader>
                </div>

                <div v-if="hasError && errors" class="text-danger">
                  <p v-if="errors.error">Field error: {{ errors.error }}</p>
                  <p v-if="errors.available_fields">
                    Available fields:
                    {{ errors.available_fields.join(', ') }}
                  </p>
                </div>

              </div>

            </div>
          </div>
        </div>

      </div>

      <div class="action-footer">
        <div class="container-fluid">
          <div class="card mb-0">
            <div class="card-body">
              <div class="float-start">
                <button class="btn btn-outline-primary" :class="{ disabled: isSubmitting }" @click.prevent="goCancel">
                  Cancel
                </button>
              </div>
              <div class="float-end">
                <div v-if="hasError" class="text-danger me-2">Error. Please check the form.</div>
                <button class="btn btn-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="space space-md" />
      <app-footer></app-footer>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'gpt-prompts',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      promptType: { id: 'email_intro', name: 'Email Intro Prompt' },
      form: {},
      promptTypes: [
        { id: 'email_intro', name: 'Email Intro' },
        { id: 'contact_email_opener', name: 'Combined Email Opener' },
        { id: 'manager_email_opener', name: 'Manager Email Opener' },
        { id: 'make_insights', name: 'Company Insights' },
        { id: 'make_contact_insights', name: 'Contact Insights' },
        { id: 'pitch_deck_analysis', name: 'Pitch Deck Analysis' },
      ],
      errors: {},
      isLoading: false,
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      startup: state => state.startups.current,
      startups: state => state.startups.startups,
      userState: state => state.auth.user,
      emailPrompt: state => state.auth.emailPrompt,
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    isGeneric() {
      return this.promptType.id === 'make_insights' || this.promptType.id === 'make_contact_insights'
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)
    this.load()
  },

  methods: {

    load() {
      this.isLoading = true
      this.$store.dispatch('LOAD_GPT_PROMPT', { promptType: this.promptType.id, startupId: this.startup.id })
        .then((response) => {
          this.isLoading = false
          this.form = response.data
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    submit () {
      this.isSubmitting = true
      this.errors = {}
      this.form.prompt_type = this.promptType.id
      this.$store.dispatch('UPDATE_GPT_PROMPT', { payload: this.form, startupId: this.startup.id })
        .then(() => {
          this.isSubmitting = false
          this.$router.go(-1)
        })
        .catch((error) => {
          this.isSubmitting = false
          this.errors = error.response.data
        })
    },
    
    goCancel() {
      this.$router.go(-1)
    },

    setPromptType(item) {
      this.promptType = item
    }

  },

  watch: {
    promptType() {
      this.load()
    }
  }
}
</script>
