<template>
  <div>
    <div v-if="company">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">{{ company.name }}</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->

      <div class="row">
          <div class="col-lg-12">
          <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2">
            <a href="#" class="btn btn-info" @click.prevent="goEdit">
              <i class="mdi mdi-lead-pencil me-1"></i> <span>Edit</span>
            </a>
            <a href="#" class="btn btn-info" @click.prevent="goDelete">
              <i class="mdi mdi-trash-can-outline me-1"></i> <span>Delete</span>
            </a>
          </div>

          <div class="card">
            <div class="card-body">
              <span>
                <h4 class="header-title mb-3">Investor Information:
                  <span v-if="company.unsubscribed === 'YES'" class="fw-bold me-2 mb-2 " style="color:red">UNSUBSCRIBED</span>
                </h4>
              </span>

              <h5>{{ company.name }}</h5>

              <p class="mb-2">
                {{ company.short_description }}
              </p>

              <h5>Full Description</h5>

              <p class="mb-2">
                {{ company.full_description }}
              </p>


              <p class="mb-2">
                <a href="http://{{ company.website }}" target="_blank">{{ company.website }}</a>
              </p>

              <p class="mb-2"><span class="fw-bold me-2">Investor location:</span>
                <template v-if="company.investor_location && company.investor_location.length">
                  <span v-for="item in company.investor_location" :key="item">
                    <template v-if="item">
                      <country-flag :country='item.code' size='small' />
                      {{ item.name }}
                    </template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2"><span class="fw-bold me-2">Investor cities:</span>
                <template v-if="company.investor_city && company.investor_city.length">
                  <span v-for="item in company.investor_city" :key="city">
                    <template v-if="item">{{ item.name }}</template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2" v-if="company.unsubscribed === 'YES'">
                <span class="fw-bold me-2">Unsubscribe Comment:</span>
                {{ company.unsubscribe_comment }}
              </p>
            </div>
          </div>
        </div> <!-- end col -->
      </div>
      
      <div class="row">

        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4>Are you sure you want to delete '{{ company.name }}'?</h4>
              <button class="btn btn-outline-primary me-2 mt-4" :class="{ disabled: isSubmitting }" @click.prevent="goView">
                Cancel
              </button>
              <button class="btn btn-danger me-2 mt-4" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Delete Investor
              </button>
            </div>
          </div>
        </div> <!-- end col -->

        </div>
    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'company',

  data() {
    return {
      company: null,
      isSubmitting: false
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('LOAD_COMPANY', this.$route.params.id)
      .then((response) => {
        this.company = response.data
      })
  },

  methods: {
    goView() {
      this.$router.push(`/investors/companies/${this.company.id}`)
    },
    goEdit() {
      this.$router.push(`/investors/companies/${this.company.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/investors/companies/${this.company.id}/delete`)
    },
    submit() {
      this.isSubmitting = true
      this.$store.dispatch('LOAD_COMPANY', this.$route.params.id)
        .finally(() => {
          this.isSubmitting = false
          this.$router.push('/investors/companies')
        })
    }
  }
}
</script>
