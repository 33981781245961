<template>
  <div v-if="updateAvailable" class="update-banner">
    New version available! 
    <button class="btn btn-light btn-sm ms-2" @click="refreshPage">
      <i class="mdi mdi-refresh me-1"></i>
      Update Now
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'version-checker',
  
  data() {
    return {
      pollInterval: null,
      currentBuildHash: null,
      updateAvailable: false
    }
  },

  computed: {
    ...mapState({
      // Add any relevant state from Vuex if needed
      isLoggedIn: state => state.auth.isLoggedIn
    })
  },

  created() {
    if (this.isLoggedIn) {
      console.info('[VersionChecker] Initializing version checker')
      this.checkVersion()
      this.startVersionCheck()
    }
  },

  beforeDestroy() {
    this.stopVersionCheck()
  },

  methods: {
    startVersionCheck() {
      console.log('[VersionChecker] Starting version check polling')
      this.stopVersionCheck()
      
      this.pollInterval = setInterval(() => {
        this.checkVersion()
      }, 60000) // Check every minute
    },

    stopVersionCheck() {
      if (this.pollInterval) {
        console.log('[VersionChecker] Stopping version check polling')
        clearInterval(this.pollInterval)
      }
    },

    async checkVersion() {
      try {
        const response = await fetch('/', {
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache'
          }
        })
        const html = await response.text()
        
        // Get current hashes from DOM
        const currentHashes = this.extractHashesFromHTML(document.documentElement.innerHTML)
        // Get latest hashes from fetched HTML
        const latestHashes = this.extractHashesFromHTML(html)

        if (latestHashes.scriptHash && currentHashes.scriptHash !== latestHashes.scriptHash ||
            latestHashes.linkHash && currentHashes.linkHash !== latestHashes.linkHash) {
          console.info('[VersionChecker] New version detected:', {
            from: currentHashes,
            to: latestHashes
          })
          this.updateAvailable = true
        }
      } catch (error) {
        console.error('[VersionChecker] Version check failed:', {
          error: error.message,
          stack: error.stack
        })
      }
    },

    refreshPage() {
      console.info('[VersionChecker] Refreshing page to update to new version')
      window.location.reload()
    },

    extractHashesFromHTML(htmlString) {
        // Extract script hash
        const scriptMatch = htmlString.match(/index-(\w+)\.js/);
        const scriptHash = scriptMatch ? scriptMatch[1] : '';

        // Extract link hash
        const linkMatch = htmlString.match(/index-(\w+)\.css/);
        const linkHash = linkMatch ? linkMatch[1] : '';

        return {
            scriptHash,
            linkHash
        };
    }


  }
}
</script>

<style lang="scss" scoped>
.update-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #4CAF50;
  color: white;
  text-align: center;
  padding: 10px;
  z-index: 99999;
  width: 100%;
  margin: 0;
  box-sizing: border-box;

  .btn {
    &:hover {
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}
</style>