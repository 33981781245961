import axios from 'axios'

const state = {
  campaigns: [],
  contacts: null,
}

const actions = {

  LOAD_REPLY_IO_CAMPAIGNS ({ commit, state }, startupId) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}campaign_list/${startupId}`)
        .then((response) => {
          commit('SET_CAMPAIGNS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_OUTREACH_CONTACTS ({ commit, dispatch, state }, { startupId, searchId, params }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/outreach_contacts/`, { params })
        .then((response) => {
          commit('SET_OUTREACH_CONTACTS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  START_OUTREACH ({ commit, dispatch, state }, { startupId, searchId, campaignId, campaignName, customFields }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      const payload = {
        campaign_id: campaignId,
        campaign_name: campaignName,
        custom_fields: customFields
      }
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/searches/${searchId}/outreach`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  }

}

const getters = {}

const mutations = {
  SET_CAMPAIGNS (state, payload) {
    state.campaigns = payload
  },
  SET_OUTREACH_CONTACTS (state, payload) {
    state.contacts = payload
  },
  CLEAR_OUTREACH (state) {
    state.contacts = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
