<template>
  <div>
    <div v-if="investorAstelSearch">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4>
                Unlock USA Database
              </h4>
              <p>
                Expand your reach with Astel Network's USA database! Get access to exclusive contacts, including
                detailed profiles, verified contact information, and valuable insights. Please note that accessing this
                premium feature requires an additional purchase. Click <b>"Request Access"</b> to learn more
                about pricing and unlock the potential of the USA community. Our customer support
                team is here to assist you.
              </p>
              <button class="btn btn-outline-primary me-2 mt-2" :class="{ disabled: isSubmitting }" @click.prevent="goView">
                Cancel
              </button>
              <button class="btn btn-primary me-2 mt-2" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Request Access
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'searchRequest',

  data() {
    return {
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      investorAstelSearch: state => state.investors.investorAstelSearch,
    })
  },

  mounted() {
    window.scrollTo(0, 0)
    console.log(this.investorAstelSearch.form)
  },

  methods: {
    goView() {
      this.$router.back();
    },
    submit() {
      this.isSubmitting = true
    // api call to save search 

    //   this.$store.dispatch('DELETE_CONTACT', this.$route.params.id)
    //     .then(() => {
    //       this.isSubmitting = false
    //       this.$router.push('/investors/companies')
    //     })
    }
  }
}
</script>
