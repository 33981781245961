<template>
  <div class="wrapper">
    <!-- ========== Left Sidebar Start ========== -->
    <div class="leftside-menu mt-0">

      <div class="p-3 pb-0">
        <div class="mb-3 d-flex" v-if="startup.matching_score_calculation_status!='COMPLETED'">
          <button class="btn btn-outline-primary flex-grow-1" @click.prevent="loadStartup">
            <i class="uil uil-redo"></i>
            Re-Check Match Score Calculation Status
          </button>
        </div>
        
        <div class="mb-3 d-flex">
          <button class="btn btn-outline-primary flex-grow-1" @click.prevent="doSearch">
            <i class="uil uil-redo"></i>
            Refresh Results
          </button>
        </div>

        <div class="mb-4">
          <h4 class="mb-1">Breakdown:</h4>
          <div class="mb-2">
            <v-select
              name="breakdown_field"
              v-model="localBreakdownField"
              :options="breakdownFieldOptions"
              label="label"
              placeholder="Select field"
              :clearable="true"
            >
            </v-select>
          </div>
          <div class="mb-2" v-if="localBreakdownField && localBreakdownField.id == 'investment_countries'">
            <div v-for="(group, index) in breakdownGroups" class="d-flex align-items-center">
              <v-select
                class="flex-grow-1"
                name="breakdown_groups"
                v-model="group.value"
                :options="optionsCountries"
                label="name"
                multiple
                @option:selected="onBreakdownGroupsChange"
                :close-on-select="true"
              >
              </v-select>
              <a href="#" class="ui uil-trash-alt ms-1" @click.prevent="deleteBreakdownGroup(group, index)" title="Delete breakdown group"></a>
            </div>
          </div>
          <div class="mb-2" v-if="localBreakdownField && localBreakdownField.id == 'investor_region'">
            <div v-for="(group, index) in breakdownGroups" class="d-flex align-items-center">
              <v-select
                class="flex-grow-1"
                name="breakdown_groups"
                v-model="group.value"
                :options="optionsRegions"
                label="name"
                multiple
                @option:selected="onBreakdownGroupsChange"
                :close-on-select="true"
              >
              </v-select>
              <a href="#" class="ui uil-trash-alt ms-1" @click.prevent="deleteBreakdownGroup(group, index)" title="Delete breakdown group"></a>
            </div>
          </div>
          <div class="mb-2" v-if="localBreakdownField && localBreakdownField.id == 'investor_type'">
            <div v-for="(group, index) in breakdownGroups" class="d-flex align-items-center">
              <v-select
                class="flex-grow-1"
                name="breakdown_groups"
                v-model="group.value"
                :options="optionsInvestorTypes"
                label="name"
                multiple
                @option:selected="onBreakdownGroupsChange"
                :close-on-select="true"
              >
              </v-select>
              <a href="#" class="ui uil-trash-alt ms-1" @click.prevent="deleteBreakdownGroup(group, index)" title="Delete breakdown group"></a>
            </div>
          </div>
          <div>
            <a href="#" @click.prevent="addBreakdownGroup">+ Add Custom Group</a>
          </div>
          <v-select
              class="flex-grow-1 mt-4"
              name="breakdown_groups"
              v-model="localBreakdownTier"
              placeholder="Select Tier"
              :reduce="x => x.id"
              :options="breakdownTierOptions"
              :close-on-select="true"
            >
          </v-select>
        </div>

        <h4 class="mb-0">Filter results:</h4>
        <div class="mt-1 d-flex">
          <button class="btn btn-outline-primary flex-grow-1" @click.prevent="onMatchInvestors()">
            <i class="uil uil-filter"></i>
            Match Investors
          </button>
        </div>
        <div class="small pt-1">
          <p class="mb-0 text-center">Apply filters based on startup profile.</p>
        </div>
      </div>

      <div class="p-3">
        <form class="g-3 align-items-center form-side-bar" @submit.prevent="doSearch">
          <div class="row">
            <div v-for="(group, gIndex) in form" :key="group" class="p-0">
              <div class="card search-field-group p-2 mb-2">
                <div v-for="(item, index) in group.fields" :key="index">
                  <v-select
                    name="field"
                    v-model="item.field"
                    :options="searchFields"
                    label="field_label"
                    placeholder="Select field"
                    @option:selected="onFieldChange(item)"
                  >
                  </v-select>
                  <v-select
                    v-if="item.field"
                    name="condition"
                    v-model="item.condition"
                    :options="item.field.conditions"
                    label="name"
                    @open="onConditionOpen(item)"
                  >
                  </v-select>
                  <template v-if="item.field">
                    <template v-if="item.field.field_type === 'string'">
                      <v-select
                        name="filter_value"
                        v-model="item.value"
                        :options="tagOptions"
                        @search="searchTags"
                        multiple
                        taggable
                        :close-on-select="false"
                      >
                      </v-select>
                    </template>
                    <template v-if="item.field.field_type === 'integer'">
                      <input
                        id="id_filter_value"
                        type="number"
                        name="filter_value"
                        class="form-control"
                        v-model="item.value"
                      >
                    </template>
                    <template v-if="item.field.field_type === 'choicefield'">
                      <v-select
                        name="filter_value"
                        v-model="item.value"
                        :options="item.field.options.map((x) => ({ id: x[0], label: x[1] }))"
                        :reduce="(x) => x.id"
                        multiple
                        :close-on-select="false"
                      >
                      </v-select>
                    </template>
                    <template v-if="item.field.field_type === 'stringchoicefield'">
                      <v-select
                        name="filter_value"
                        v-model="item.value"
                        :options="item.field.options.map((x) => ({ id: x[0], label: x[1] }))"
                        :reduce="(x) => x.label"
                        multiple
                        taggable
                        :close-on-select="false"
                      >
                      </v-select>
                    </template>
                    <template v-if="item.field.field_type === 'foreignkey'">
                      <v-select
                        name="filter_value"
                        v-model="item.value"
                        :options="item.field.options.map((x) => ({ id: x[0], label: x[1] }))"
                        :reduce="(x) => x.id"
                        multiple
                        :close-on-select="false"
                      >
                      </v-select>
                    </template>
                    <template v-if="item.field.field_type === 'city'">
                      <v-select
                        name="filter_value"
                        v-model="item.value"
                        :options="optionsCities"
                        @search="(search, loading) => { this.searchCities(item, search, loading) }"
                        :reduce="x => x.id"
                        label="name"
                        multiple
                        :close-on-select="false"
                      >
                      </v-select>
                    </template>
                    <template v-if="item.field.field_type === 'country'">
                      <v-select
                        name="filter_value"
                        v-model="item.value"
                        :options="optionsCountries"
                        :reduce="x => x.id"
                        label="name"
                        multiple
                        :close-on-select="false"
                      >
                      </v-select>
                    </template>
                    <template v-if="item.field.field_type === 'tag'">
                      <v-select
                        name="filter_value"
                        v-model="item.value"
                        :options="tagOptions"
                        @search="searchTags"
                        multiple
                        taggable
                        :close-on-select="false"
                        :clear-search-on-select="false"
                      >
                      </v-select>
                    </template>
                  </template>

                  <div class="float-end">
                    <a href="#" class="ui uil-trash-alt me-1" @click.prevent="deleteField(group, index, gIndex)" title="Delete this field"></a>
                  </div>

                  <div v-if="group.fields.length > 1" class="d-flex justify-content-center my-2">
                    <template v-if="index === 0">
                      <div class="form-check mx-2">
                        <input class="form-check-input" type="radio" :id="`group_operator_${gIndex}_${index}_and`" v-model="group.operator" value="AND">
                        <label class="form-check-label" :for="`group_operator_${gIndex}_${index}_and`">
                          AND
                        </label>
                      </div>
                      <div class="form-check mx-2">
                        <input class="form-check-input" type="radio" :id="`group_operator_${gIndex}_${index}_or`" v-model="group.operator" value="OR">
                        <label class="form-check-label" :for="`group_operator_${gIndex}_${index}_or`">
                          OR
                        </label>
                      </div>
                    </template>
                    <template v-else-if="index + 1 !== group.fields.length">
                      {{ group.operator }}
                    </template>
                  </div>
                </div>
                
                <div class="mt-2">
                  <a href="#" @click.prevent="addField(group)">+ Add Field</a>
                </div>

              </div>
              <div v-if="gIndex != form.length - 1" class="text-center pb-2">
                <span>AND</span>
              </div>
            </div>

            <div class="mt-2 d-flex">
              <a href="#" class="btn btn-outline-primary flex-grow-1" @click.prevent="addFilter">
                <i class="uil uil-filter"></i>
                Add Filter
              </a>
            </div>

            <div class="mt-4 d-flex">
              <a href="#" class="btn btn-outline-danger flex-grow-1" @click.prevent="clearFilters">
                <i class="uil uil-trash"></i>
                Remove All Filters
              </a>
            </div>

            <div class="mt-4">
              <hr class="mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="default_exclude" v-model="default_exclude">
                <label class="form-check-label" for="default_exclude">Exclude unsuitable investor types</label>
              </div>
            </div>

            <div class="mt-1 mb-4">
              <hr class="mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="hide_ring_fence" v-model="hide_ring_fence">
                <label class="form-check-label" for="hide_ring_fence">Hide Ringfence</label>
              </div>
            </div>
             <div class="mt-1 mb-4">
              <hr class="mb-3">
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="hide_undeliverable_contact" v-model="hide_undeliverable_contact">
                <label class="form-check-label" for="hide_undeliverable_contact">Hide Undeliverable Contact</label>
              </div>
            </div>

             <div class="mb-3 d-flex">
              <button class="btn btn-outline-primary flex-grow-1" @click.prevent="doSearch" :disabled="startup.matching_score_calculation_status!='COMPLETED'">
                <i class="uil uil-redo"></i>
                Apply Filters
              </button>
            </div>

          </div>
        </form>

      </div>

      <div class="clearfix"></div>
      <!-- Sidebar -left -->

    </div>
    <!-- Left Sidebar End -->

    <div class="content-page">
      <div class="content">
        <div class="row" v-if="startup.matching_score_calculation_status!='COMPLETED'">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h4>
                    Matching Score is being generated for {{startup.name}}. Please wait for a few minutes and click 'Re-Check Match Score Calculation Status' on the left sidebar.
                  </h4>
                  <button class="btn btn-primary me-2 mt-2" :class="{ disabled: isSubmitting }" @click.prevent="loadStartup">
                    Re-Check Status
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="startup.matching_score_calculation_status=='COMPLETED'">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  
                  <ul class="nav nav-tabs" v-if="matchFields && matchFields.length">
                    <li class="nav-item" v-for="group in matchFields">
                      <a
                        href="#"
                        class="nav-link"
                        :class="{ active: matchGroup === group.name }"
                        @click.prevent="addMatchFilters(group.name)"
                      >
                        {{ group.name }}
                      </a>
                    </li>
                  </ul>
                  <div class="refresh-container" v-if="filtersChanged || companiesLoading">
                    <template v-if="filtersChanged">
                      <p style="text-align: center; margin-bottom: 10px;">Filters have changed</p>
                      <button class="btn btn-primary btn-sm " @click.prevent="doSearch">
                        <i class="uil uil-redo"></i>
                        Refresh Result
                      </button>
                    </template>
                    <div v-else-if="companiesLoading" class="d-flex flex-column align-items-center">
                      <loader></loader>
                      <p>Our AI Analyst is evaluating your deal and identifying the best matches for you</p>
                    </div>
                  </div>
                  <div :class="{'blurred': filtersChanged || companiesLoading}" class="table-data">
                    <div class="table-container">
                      <table class="table table-hover">
                        <thead>
                          <tr>
                            <th class="orderable" :style="{ width: siteCompany.is_whitelabel ? '40%' : '20%' }">
                              <a href="?sort=name">Name</a>
                            </th>
                            <th v-if="!siteCompany.is_whitelabel" class="orderable" style="width: 60%">
                              Insights
                            </th>
                            <th class="orderable" :style="{ width: siteCompany.is_whitelabel ? '30%' : '5%' }">
                              Score
                            </th>
                            <th class="orderable" :style="{ width: siteCompany.is_whitelabel ? '30%' : '15%' }">
                              <a href="?sort=tags">Tags</a>
                            </th>
                          </tr>
                        </thead>

                        <tbody v-if="companies && companies.results">
                          <tr v-for="(company, index) in companies.results" :key="index"
                            @click="$router.push(`/investors/companies/${company.id}/`)" class="cursor-pointer">
                            <td>
                              <router-link :to="`/investors/companies/${company.id}/`">
                                <h4>{{ company.name }}</h4>
                              </router-link>
                              <span class="large">
                                <span class="badge text-green text-bg-dark" v-if="company.is_good_relation">GR</span>
                                <span class="badge text-green" style="background-color: green; color: white;" v-if="company.is_deal_dashboard">DD</span>
                                <i class="uil uil-user text-green" v-if="company.met_in_person"></i>
                                <i class="uil uil-phone text-green" v-if="company.over_call"></i>
                                <i class="uil uil-envelope text-green" v-if="company.over_email"></i>
                              </span>
                            </td>
                            <td class="insights-cell" v-if="!siteCompany.is_whitelabel">
                              <div class="py-2" v-if="company.insights_loading">
                                <loader size="48px"></loader>
                              </div>
                              <div v-else-if="Array.isArray(company.insights) && company.insights.length" class="insights-content">
                                <ul class="insights-list">
                                  <li v-for="item in company.insights" class="insight-item">
                                    <div class="insight-details">{{ item.details }}</div>
                                    <div class="small" v-if="item.link">
                                      [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div v-else>
                                --
                              </div>
                            </td>
                            <td>
                              <div v-if="company.matching_score">
                                {{ $filters.percentage(company.matching_score) }}
                              </div>
                              <div v-else>
                                --
                              </div>
                            </td>
                            <td>
                              <a href="#" @click.stop="">
                                <template v-if="company.industry_sector_tags && company.industry_sector_tags.length">
                                  <span v-for="tag in company.industry_sector_tags.slice(0, 4)" :key="tag" class="d-block pre">
                                    {{ tag }}
                                  </span>
                                </template>
                                <template v-else>
                                  —
                                </template>
                                <template v-if="company.industry_sector_tags && company.industry_sector_tags.length > 4">
                                  <span class="d-block pre">
                                    ...
                                  </span>
                                </template>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <pagination :items="companies" :currentPage="page" :perPage="perPage" @pagechanged="onPageChanged">
                  </pagination>
                </div>
              </div>
            </div>
          </div>
        </div> <!-- end row -->
      </div> <!-- end containt-page -->
    </div>
  
    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">

              <!-- <button class="btn btn-outline-primary me-2" :class="{ disabled: isSubmitting || loadingInsights }" @click.prevent="goGetInsights">
                <loader v-if="isSubmitting || loadingInsights" size="20px" class="d-inline"></loader>
                <i v-else class="uil uil-atom"></i>
                Get Insights
              </button> -->

              <button class="btn btn-primary" @click.prevent="goNext">
                <i class="uil uil-users-alt"></i>
                Review Contacts
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SearchMixin from '@/mixins/search'
import {isInitialLoadOrReload} from '@/utils'

export default {
  name: 'companies',

  mixins: [SearchMixin],

  data() {
    return {
      page: 1,
      perPage: 20,
      isSubmitting: false,
      module: 'companies',
    }
  },

  created(){
    this.$nextTick(() => {
      if (isInitialLoadOrReload()) {
        this.resetSharedState();
      }
    });
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      startup: state => state.startups.current,
      matchGroup: state => state.investors.matchGroup,
      companiesLoading: state => state.investors.companiesLoading,
      siteCompany: state => state.auth.siteCompany,
    })
  },

  mounted() {
    if (this.user && !this.user.can_search) {
      this.$router.push({ name: 'startup_profile' })
      return
    }
    this.$store.dispatch('LOAD_STARTUP', this.startup.id)
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
  },

  methods: {
    loadStartup() {
      Promise.all([
        this.$store.dispatch('LOAD_STARTUP', this.startup.id)
      ])
      if (this.startup.matching_score_calculation_status == 'COMPLETED') {
        this.doSearch();
      }
    },

    loadStartupContent() {
      return Promise.all([
        this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId: this.startup.id }),
        this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id })
      ])
    },

    onMatchInvestors() {
      this.loadStartupContent().then(() => {
        this.$store.commit('SET_CURRENT_SEARCH', null)
        this.addMatchFilters()
        this.localBreakdownField = null
        this.localBreakdownTier = null
        this.filtersChanged = false
        this.doSearch()
      })
    },

    onPageChanged(pageNumber) {
      this.page = pageNumber
      this.doSearch()
    },

    goNext() {
      this.$router.push({ name: 'investors_contacts' })
    },

    getDomainFromURL(url) {
      if (!url) return ''
      const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
      return domain
    }
    
  }
}
</script>

<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  width: 100%;
  table-layout: fixed;
}

.insights-cell {
  /* width: 60%;
  max-width: 60%; */
  vertical-align: top;
}

.insights-content {
  /* max-height: 200px; */
  overflow-y: auto;
}

.insights-list {
  padding-left: 20px; /* Provide space for bullets */
  margin: 0;
}

.insight-item {
  margin-bottom: 10px;
}

.insight-details {
  word-wrap: break-word;
  white-space: normal;
}

/* Ensure long URLs don't break the layout */
.insight-item a {
  word-break: break-all;
}
</style>