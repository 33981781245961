<template>
  <div>
    <div v-if="company">

      <div class="row">
        <div class="col-lg-12">

          <div class="card">
            <div class="card-body">

              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goEdit">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
              </div>

              <h3 class="mb-4">
                {{ company.name }}
                <span
                  v-if="company.unsubscribed === 'YES'"
                  class="small fw-bold me-2 mb-2 "
                  style="color:red"
                >
                  UNSUBSCRIBED
                </span>
              </h3>

              <ul class="nav nav-tabs nav-bordered mb-4" role="tablist">
                <li class="nav-item" role="presentation">
                  <a href="#" class="nav-link" role="tab" @click.prevent="goProfile">
                    Profile
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a href="#" class="nav-link active" role="tab" @click.prevent="goContacts">
                    Contacts ({{ company.contact_count }})
                  </a>
                </li>
              </ul>

              <div class="table-data" v-if="contacts && contacts.results && contacts.results.length">
                <div class="table-container">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th class="orderable">
                          <a href="#">Full name</a>
                        </th>
                        <th>
                          Title
                        </th>
                        <th class="orderable">
                          <a href="#">Email</a>
                        </th>
                        <th class="orderable">
                          <a href="#">Email status</a>
                        </th>
                        <th class="orderable">
                          <a href="#">Phone number</a>
                        </th>
                      </tr>
                    </thead>

                    <tbody v-if="contacts && contacts.results">
                      <tr v-for="contact in contacts.results" :key="contact.id">
                        <td>
                          <router-link :to="`/contacts/${contact.id}/`">
                            {{ contact.first_name }}
                            <template v-if="contact.has_access">
                              <span>{{ contact.last_name }}</span>
                            </template>
                            <template v-else>
                              <span class="blur-lg">LastName</span>
                            </template>
                          </router-link>
                          <span class="mx-2">
                            <span class="badge text-white text-bg-dark" v-if="contact.is_good_relation">GR</span>
                            <span class="badge text-green" style="background-color: green; color: white;" v-if="contact.is_deal_dashboard">DD</span>
                            
                            <i class="uil uil-user text-green" v-if="contact.met_in_person"></i>
                            <i class="uil uil-phone text-green" v-if="contact.over_call"></i>
                            <i class="uil uil-envelope text-green" v-if="contact.over_email"></i>
                          </span>
                        </td>
                        <td>
                          <template v-if="contact.title && contact.title.length">
                            <span v-for="title in contact.title" :key="title" class="me-2">
                              {{ title.name }}
                            </span>
                          </template>
                          <template v-else>
                            —
                          </template>
                        </td>
                        <td>
                          <template v-if="contact.has_access">
                            <span>{{ contact.email || '—' }}</span>
                          </template>
                          <template v-else>
                            <span class="blur-lg">{{contact.first_name}}.lastname{{contact.id}}@astelventures.com</span>
                          </template>
                        </td>
                        <td>
                          {{ contact.email_status || '—' }}
                        </td>
                        <td>
                          <template v-if="contact.has_access">
                            <span>{{ contact.phone_number || '—' }}</span>
                          </template>
                          <template v-else>
                            <span class="blur-lg">9876543210</span>
                          </template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  
                  <pagination
                    :items="contacts"
                    :currentPage="page"
                    :perPage="perPage"
                    @pagechanged="onPageChanged"
                  >
                  </pagination>

                </div>
              </div>

              <div v-else-if="contacts">
                <p>No contacts yet.</p>
              </div>

            </div>
          </div>
        </div> <!-- end col -->
      </div>


    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'company-contacts',

  data() {
    return {
      company: null,
      contacts: null,
      page: 1,
      perPage: 2
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('LOAD_COMPANY', this.$route.params.id)
      .then((response) => {
        this.company = response.data
      })
    this.$store.dispatch('LOAD_COMPANY_CONTACTS', { companyId: this.$route.params.id })
      .then((response) => {
        this.contacts = response.data
      })
  },

  methods: {
    goView() {
      this.$router.push(`/investors/companies/${this.company.id}`)
    },
    goEdit() {
      this.$router.push(`/investors/companies/${this.company.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/investors/companies/${this.company.id}/delete`)
    },
    goProfile() {
      this.$router.push(`/investors/companies/${this.company.id}`)
    },
    goContacts() {
      this.$router.push(`/investors/companies/${this.company.id}/contacts`)
    },
    onPageChanged(pageNumber) {
      const offset = (pageNumber - 1) * this.perPage
      const data = { companyId: this.$route.params.id, params: { offset } }
      this.$store.dispatch('LOAD_COMPANY_CONTACTS', data)
        .then(() => {
          this.page = pageNumber
        })
    },
  }
}
</script>
