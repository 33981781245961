<template>
  <div>
   <app-header></app-header>
    <div class="" v-if="instance">
      <div class="col-12">
            <ul class="nav nav-tabs" v-if="EngagementForms">
              <li v-for="item in EngagementForms" class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ active: item.id == EngagementForm.id}"
                  @click.prevent="setEngagementForm(item)"
                >
                  {{ item.name }}
                </a>
              </li>
            </ul>
      </div>
      <div v-if="EngagementForm.id === 'questions'"  class="row">
        <div class="col-sm-12">
          
          <div  class="card">
            <div class="card-body py-0 text-center">
              <h3 class="pt-2 mb-1">Engagement Call Info Form</h3><br>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12">
          
          <div  class="card">
            <div class="card-body">
                <h4 class="header-title">
                    Fill out prior to the call:
                  </h4>
                  <br>
          
               <div class="form-group mb-3">
                <label for="id_astel_account_manager_email">Astel Account Manager Email:<span :style="requiredField"> *</span></label>
                <input id="id_astel_account_manager_email" type="email" name="astel_account_manager_email" class="form-control" v-model="instance.astel_account_manager_email">
                
                <p v-if="errors.astel_account_manager_email && errors.astel_account_manager_email.length">
                  <span v-for="error in errors.astel_account_manager_email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              
               <div class="form-group mb-3">
                <label for="id_astel_account_manager_name">Astel Account Manager Name:<span :style="requiredField"> *</span></label>
                <input id="id_astel_account_manager_name" type="text" name="astel_account_manager_name" class="form-control" v-model="instance.astel_account_manager_name">
                
                <p v-if="errors.astel_account_manager_name && errors.astel_account_manager_name.length">
                  <span v-for="error in errors.astel_account_manager_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
               <div class="form-group mb-3">
                <label for="id_company_name">Fund Name:<span :style="requiredField"> *</span></label>
                <input id="id_company_name" type="text" name="company_name" class="form-control" required v-model="instance.company_name">

                <p v-if="errors.company_name && errors.company_name.length">
                  <span v-for="error in errors.company_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_company_website">Fund Website:<span :style="requiredField"> *</span></label>
                <input id="id_company_website" type="text" name="company_website" class="form-control" required v-model="instance.company_website">

                <p v-if="errors.company_website && errors.company_website.length">
                  <span v-for="error in errors.company_website" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_investor_location">Country of Investor:<span :style="requiredField"> *</span></label>

                <v-select
                  name="investor_location"
                  v-model="instance.investor_location"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>
                
                <p v-if="errors.investor_location && errors.investor_location.length">
                  <span v-for="error in errors.investor_location" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-2">
                <label for="id_investor_type"> Investor Type:<span :style="requiredField"> *</span></label>

                <v-select name="investor_type" v-model="instance.investor_type"
                  :options="optionsInvestorTypes" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.investor_type && errors.investor_type.length">
                  <span v-for="error in errors.investor_type" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
               <div class="form-group mb-3">
                <label for="id_contact_first_name">Contact First Name:<span :style="requiredField"> *</span></label>
                <input id="id_contact_first_name" type="text" name="contact_first_name" class="form-control" v-model="instance.contact_first_name">
                
                <p v-if="errors.contact_first_name && errors.contact_first_name.length">
                  <span v-for="error in errors.contact_first_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-3">
                <label for="id_contact_last_name">Contact Last Name:<span :style="requiredField"> *</span></label>
                <input id="id_contact_last_name" type="text" name="contact_last_name" class="form-control" v-model="instance.contact_last_name">
                
                <p v-if="errors.contact_last_name && errors.contact_last_name.length">
                  <span v-for="error in errors.contact_last_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_contact_email">Contact Email:<span :style="requiredField"> *</span></label>
                <input id="id_contact_email" type="text" name="contact_email" class="form-control" required v-model="instance.contact_email">

                <p v-if="errors.contact_email && errors.contact_email.length">
                  <span v-for="error in errors.contact_email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

             <div class="form-group mb-3">
                <label for="id_contact_title">Contact Title:<span :style="requiredField"> *</span></label>
                <input id="id_contact_title" type="text" name="contact_title" class="form-control" required v-model="instance.contact_title">

                <p v-if="errors.contact_title && errors.contact_title.length">
                  <span v-for="error in errors.contact_title" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div> 

               <div class="form-group mb-3">
                <label for="id_date_of_call">Date of Call:<span :style="requiredField"> *</span></label>
                <input
                  id="id_date_of_call"
                  type="date"
                  name="date_of_call"
                  class="form-control"
                  v-model="instance.date_of_call"
                >

                <p v-if="errors.date_of_call && errors.date_of_call.length">
                  <span v-for="error in errors.date_of_call" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Questions On The Fund
              </h4>
              <br>
               <div class="form-group mb-3">
                <label for="id_past_funds">Past Funds (Lockup Period, IRR, Management Fees, Performance Fees)
:<span :style="requiredField"> *</span></label>
                <input id="id_past_funds" type="text" name="past_funds" class="form-control" required v-model="instance.past_funds">

                <p v-if="errors.past_funds && errors.past_funds.length">
                  <span v-for="error in errors.past_funds" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div> 
               <div class="form-group mb-3">
                <label for="id_current_fundraise">Current Fundraise (timeline, close, target amount, commitments)
:<span :style="requiredField"> *</span></label>
                <input id="id_current_fundraise" type="text" name="current_fundraise" class="form-control" required v-model="instance.current_fundraise">

                <p v-if="errors.current_fundraise && errors.current_fundraise.length">
                  <span v-for="error in errors.current_fundraise" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div> 
              <div class="form-group mb-3">
                <label for="id_assets_under_management">Assets Under Management:</label>
                <v-number-input
                  v-model="instance.assets_under_management"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.assets_under_management && errors.assets_under_management.length">
                  <span v-for="error in errors.assets_under_management" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-2">
                <label for="id_US_major_fund">Is this a U.S. Major fund?<span :style="requiredField"> *</span></label>

                <v-select
                  name="US_major_fund"
                  v-model="instance.US_major_fund"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.US_major_fund && errors.US_major_fund.length">
                  <span v-for="error in errors.US_major_fund" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
        
              
              <div class="form-group mb-2">
                <label for="id_other_investment_funds">Do you invest in other funds?</label>

                <v-select
                  name="other_investment_funds"
                  v-model="instance.other_investment_funds"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.other_investment_funds && errors.other_investment_funds.length">
                  <span v-for="error in errors.other_investment_funds" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_top_invested_funds">Which top 3 funds do you co-invest with:</label>
                <input id="id_top_invested_funds" type="text" name="top_invested_funds" class="form-control" v-model="instance.top_invested_funds">
                
                <p v-if="errors.top_invested_funds && errors.top_invested_funds.length">
                  <span v-for="error in errors.top_invested_funds" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_lp_invested_in_fund">What are some of the LPs that invest in you - especially those that will be ok to having their name shared:</label>
                <input id="id_lp_invested_in_fund" type="text" name="lp_invested_in_fund" class="form-control" v-model="instance.lp_invested_in_fund">
                
                <p v-if="errors.lp_invested_in_fund && errors.lp_invested_in_fund.length">
                  <span v-for="error in errors.lp_invested_in_fund" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
           </div>
          </div>
          
        </div>

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
             <h4 class="header-title">
                Fund's Investment Thesis
              </h4>
              <br>
               <div class="form-group mb-2">
                <label for="id_investment_stages">Investment Stages:<span :style="requiredField"> *</span></label>

               <v-select name="investor_type" v-model="instance.investment_stages"
                  :options="optionsInvestmentStages" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.investment_stages && errors.investment_stages.length">
                  <span v-for="error in errors.investment_stages" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-2">
                <label for="id_industry_sector_tags">Sector Focus:<span :style="requiredField"> *</span></label>

                <v-select
                  name="industry_sector_tags"
                  v-model="instance.industry_sector_tags"
                  :options="tagOptions"
                  @search="searchTags"
                  multiple
                  taggable
                  :close-on-select="false"
                >
                </v-select>

                <p v-if="errors.industry_sector_tags && errors.industry_sector_tags.length">
                  <span v-for="error in errors.industry_sector_tags" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="row">
                <div class="col-md-6">
                   <div class="form-group mb-3">
                      <label for="id_investment_countries">Geo Focus:</label>

                      <v-select
                        name="investment_countries"
                        v-model="instance.investment_countries"
                        :options="optionsCountries"
                        :reduce="x => x.id"
                        label="name"
                        multiple
                      >
                      </v-select>
                      
                      <p v-if="errors.investment_countries && errors.investment_countries.length">
                        <span v-for="error in errors.investment_countries" :key="error" class="text-danger">{{ error }}</span>
                      </p>
                    </div>
                </div>
              
                <div class="col-md-6">
                   <div class="form-group mb-3">
                      <label for="id_investment_stages">Region Focus:</label>

                    <v-select name="investor_type" v-model="instance.preferred_investor_region"
                        :options="optionsRegion" :reduce="x => x.id" label="name" multiple>
                      </v-select>

                      <p v-if="errors.preferred_investor_region && errors.preferred_investor_region.length">
                        <span v-for="error in errors.preferred_investor_region" :key="error" class="text-danger">{{ error }}</span>
                      </p>
                    </div>
                </div>
              </div>
              
              
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="id_ticket_size_from">Ticket Size From:<span :style="requiredField"> *</span></label>
                    <v-number-input
                      v-model="instance.ticket_size_from"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.ticket_size_from && errors.ticket_size_from.length">
                      <span v-for="error in errors.ticket_size_from" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
              
                <div class="col-md-6">
                   <div class="form-group mb-3">
                    <label for="id_ticket_size_to">Ticket Size To:<span :style="requiredField"> *</span></label>
                    <v-number-input
                      v-model="instance.ticket_size_to"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.ticket_size_to && errors.ticket_size_to.length">
                      <span v-for="error in errors.ticket_size_to" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="id_EBITDA_requirements_from">EBITDA Requirements From:</label>
                    <v-number-input
                      v-model="instance.EBITDA_requirements_from"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.EBITDA_requirements_from && errors.EBITDA_requirements_from.length">
                      <span v-for="error in errors.EBITDA_requirements_from" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="id_EBITDA_requirements_to">EBITDA Requirements To:</label>
                    <v-number-input
                      v-model="instance.EBITDA_requirements_to"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.EBITDA_requirements_to && errors.EBITDA_requirements_to.length">
                      <span v-for="error in errors.EBITDA_requirements_to" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="id_ARR_requirement_from">ARR Requirement From:</label>
                    <v-number-input
                      v-model="instance.ARR_requirement_from"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.ARR_requirement_from && errors.ARR_requirement_from.length">
                      <span v-for="error in errors.ARR_requirement_from" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group mb-3">
                      <label for="id_ARR_requirement_to">ARR Requirement To:</label>
                      <v-number-input
                        v-model="instance.ARR_requirement_to"
                        v-bind="numberInputConfig"
                      />

                      <p v-if="errors.ARR_requirement_to && errors.ARR_requirement_to.length">
                        <span v-for="error in errors.ARR_requirement_to" :key="error" class="text-danger">{{ error }}</span>
                      </p>
                    </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                   <div class="form-group mb-3">
                    <label for="id_valuation_from">Valuation From:</label>
                    <v-number-input
                      v-model="instance.valuation_from"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.valuation_from && errors.valuation_from.length">
                      <span v-for="error in errors.valuation_from" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group mb-3">
                    <label for="id_valuation_to">Valuation To:</label>
                    <v-number-input
                      v-model="instance.valuation_to"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.valuation_to && errors.valuation_to.length">
                      <span v-for="error in errors.valuation_to" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
              </div>
              

              <div class="form-group mb-3">
                <label for="id_ownership_target">Ownership Target:</label>
                <input id="id_ownership_target" type="text" name="ownership_target" class="form-control" v-model="instance.ownership_target">
                
                <p v-if="errors.ownership_target && errors.ownership_target.length">
                  <span v-for="error in errors.ownership_target" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_other_company_metrics">Other Company Metrics:</label>
                <input id="id_other_company_metrics" type="text" name="other_company_metrics" class="form-control" v-model="instance.other_company_metrics">
                
                <p v-if="errors.other_company_metrics && errors.other_company_metrics.length">
                  <span v-for="error in errors.other_company_metrics" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Other Questions
              </h4>
              <br>
              <div class="form-group mb-3">
                <label for="id_other_information">Other Information</label>
                <input id="id_other_information" type="text" name="other_information" class="form-control" v-model="instance.other_information">
                
                <p v-if="errors.other_information && errors.other_information.length">
                  <span v-for="error in errors.other_information" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_video_interview">We had a mini-interview with people from Tenfore Holdings, Curiosity VC and Green European Tech Fund.<br>
                      Questions to ask for video call recordings:<br>
                      1. What advice would you give founders who are looking to pitch their startups to investors in today's market?<br>
                      2. What are some of the things founders should not do during a pitch?
                      or
                      Prediction for 2024 
                      or a question that would be relevant to that investor</label>
                <input id="id_video_interview" type="text" name="video_interview" class="form-control" v-model="instance.video_interview">
                
                <p v-if="errors.video_interview && errors.video_interview.length">
                  <span v-for="error in errors.video_interview" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-2">
                <label for="id_re_engagement_call">Suggested quarterly re-engagement call? (Ask for a call with the GP or Managing Partner if we speak with IR):</label>

                <v-select
                  name="re_engagement_call"
                  v-model="instance.re_engagement_call"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.re_engagement_call && errors.re_engagement_call.length">
                  <span v-for="error in errors.re_engagement_call" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_kyc_partner">We have built partnerships with AML/KYC partners, who can help with the process of onboarding LPs from different jurisdictions. Would you like to be introduced to them?:</label>
                <input id="id_kyc_partner" type="text" name="kyc_partner" class="form-control" v-model="instance.kyc_partner">
                
                <p v-if="errors.kyc_partner && errors.kyc_partner.length">
                  <span v-for="error in errors.kyc_partner" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_startups">Startups to share(Sector/Stage/Geo):</label>
                 
                  <v-select name="startups" v-model="instance.startups"
                            :options="optionsStartups" :reduce="x => x.name" label="name" multiple
                            @option:selected="handleStartupSelection">
                  </v-select>
                  
                  <p v-if="errors.startups && errors.startups.length">
                     <span v-for="error in errors.startups" :key="error" class="text-danger">{{ error }}</span>
                  </p>
              </div>
              
                  <input v-if="showOtherInput" type="text" class="form-control mt-2" v-model="otherStartup" placeholder="Enter other startup">
              
          
               <div class="form-group mb-3">
                <label for="id_muboriz_linkedin_oneliner">One liner for connecting via Muboriz's Linkedin:</label>
                <input id="id_muboriz_linkedin_oneliner" type="text" name="muboriz_linkedin_oneliner" class="form-control" v-model="instance.muboriz_linkedin_oneliner">
                
                <p v-if="errors.muboriz_linkedin_oneliner && errors.muboriz_linkedin_oneliner.length">
                  <span v-for="error in errors.muboriz_linkedin_oneliner" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
            </div>
          </div>
        </div> 

        <div class="card mt-5">
        <div class="card-body">
          <div class="action-footer">
            <div class="container-fluid">
              <div class="card mb-0">
                <div class="card-body">
                  <div class="d-flex align-items-center float-end">
                    <div v-if="hasError" class="text-danger pt-2 me-2">Error. Please check the form.</div>
                    <button class="btn btn-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div v-else>
        <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    
                    <div class="table-data">
                      <div class="table-container">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="orderable" style="width: 20%">
                                <a href="#">Responded By</a>
                              </th>
                              <th class="orderable" style="width: 20%">
                                Contact Name
                              </th>
                              <th class="orderable" style="width: 60%">
                                Fund's Name
                              </th>
                            
                            </tr>
                          </thead>
                        

                          <tbody v-if="engagementcallinfo && engagementcallinfo.results">
                            <tr v-for="(engagementcall, index) in engagementcallinfo.results" :key="index"
                              @click="$router.push(`/engagementcallinfo/${engagementcall.id}/`)" class="cursor-pointer">
                              <td>
                                <router-link :to="`/engagementcallinfo/${engagementcall.id}/`">
                                  <h4>{{ engagementcall.astel_account_manager_name }} </h4>
                                </router-link>
                              </td>
                              <td>
                                  <h4>{{ engagementcall.contact_first_name }} {{ engagementcall.contact_last_name }} </h4>
                              </td>
                              <td>
                                  <h4>{{ engagementcall.company_name }} </h4>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <pagination :items="engagementcallinfo" :currentPage="page" :perPage="perPage" @pagechanged="onPageChanged">
                    </pagination>
                  </div>
                </div>
                </div>
                </div>
        </div>
      </div>
     
      <!-- end row -->
    
    <div v-else class="text-center">
      <loader></loader>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'engagementcallinfo',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },


  data() {
    return {
      instance: null,
      errors: {},
      isSubmitting: false,
      page: 1,
      perPage: 20,
      module: 'engagementcallinfo',
      yesNoOptions: [{id: 'YES', label: 'YES'}, {id: 'No', label: 'NO'}],
      tagOptions: [],
      numberInputConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
        allowBlank: true,
      },
      EngagementForm: { id: 'questions', name: 'Questions' },
      EngagementForms: [
        { id: 'questions', name: 'Questions' },
        { id: 'responses', name: 'Responses' },
      ],
      requiredField: {
        color: 'red',
        fontSize: '18px', 
      },
      showOtherInput: false,
      instance: {
      startups: [],
    },
      otherStartup: ''
    }
  },

  computed: {
    ...mapState({
      optionsInvestorTypes: state => state.investors.investorTypes,
      optionsInvestmentStages: state => state.investors.investmentStages,
      optionsRegion: state => state.investors.regions,
      optionsCountries: state => state.investors.countries,
      optionsStartups: state => {
      const startups = state.startups.startups.results.map(option => {
        const stages = option.current_investment_stage.map((item)=> item.name).join(', ');
        
        const country = option.registered_country.map((item)=> item.name).join(', ');
        const name = `${option.name} (${option.industry_sector_tags} / ${stages} / ${country} )`
        return ({ name: name})
      });
      return [...startups, { id: 'other', name: 'Other' }];
    },
      engagementcallinfo: state => state.engagementcallinfo.engagementcallinfo,
     
    }),
    
    engagementCallId() {
      return this.$route.params.id || (this.current && this.current.id)
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
 
  },



  mounted() {
    window.scrollTo(0, 0)

    if (this.engagementCallId) {
      Promise.all([
        
        this.$store.dispatch('LOAD_STARUP_OPTIONS'),
        this.$store.dispatch('LOAD_SEARCH_OPTIONS'),
        this.$store.dispatch('LOAD_ENGAGEMENT_CALL_INFO', this.engagementCallId)
          .then((response) => {
            this.instance = response.data
          })
      ]).then(() => {
        this.setM2Ms()
      })
    } else {
      this.instance = {}
      this.$store.dispatch('LOAD_SEARCH_OPTIONS')
    }
  },

  methods: {
    searchTags(query, loading) {
      loading(true)
      this.$store.dispatch('SEARCH_TAGS', query)
        .then((response) => {
          this.tagOptions = response.data
        })
        .finally(() => {
          loading(false)
        })
    },

    getResponsesList() {
      this.$store.dispatch('LIST_ENGAGEMENT_CALL_INFO')
    },

    setM2Ms() {
      this.instance.investment_stages = this.instance.investment_stages.map((x) => parseInt(x.id))
      this.instance.investor_type = this.instance.investor_type.map((x) => parseInt(x.id))
      this.instance.startups = this.instance.startups.map((x) => parseInt(x.id))
     
      const countryFields = [
      
        'investor_location',
        'investment_countries',
     
      ]

      for (const field of countryFields) {
        this.instance[field] = this.instance[field].map ? this.instance[field].map((x) => x.code) : this.instance[field].code
      }
    },
   
    goView() {
      this.$router.push(`/engagementcallinfo/${this.instance.id}`)
    },

    loadEngagementCall(pageNumber) {
      if (!pageNumber) {
        pageNumber = this.page
      }
      const offset = (pageNumber - 1) * this.perPage
      return this.$store.dispatch('LIST_ENGAGEMENT_CALL_INFO', { offset })
    },

    onPageChanged(pageNumber) {
      this.loadEngagementCall(pageNumber)
        .then(() => {
          this.page = pageNumber
        })
    },
 
    submit() {
      this.isSubmitting = true
      this.errors = {}
      const selectedStartupsString = (Array.isArray(this.instance?.startups) ? this.instance?.startups : []).join(', ');
      this.instance.startups = selectedStartupsString.replace('Other',this.otherStartup);

      this.$store.dispatch('CREATE_ENGAGEMENT_CALL_INFO', this.instance)
          .then((response) => {
            this.isSubmitting = false
            this.setEngagementForm(this.EngagementForms[1])
          })
          .catch((error) => {
            this.isSubmitting = false
            this.errors = error.response.data
          })
      },
      setEngagementForm(item) {
      this.EngagementForm = item;
      if(item.id === 'responses') {
        this.getResponsesList()
      }
    },
    handleStartupSelection(selectedOptions) {
    console.log(selectedOptions)
    if (selectedOptions && selectedOptions.some(option => option.id === 'other')) {
        this.instance.startups = selectedOptions.map(option => option.name); 
        this.showOtherInput = true;
    } else {
        this.instance.startups = selectedOptions.map(option => option.name); 
        this.showOtherInput = false;
      }

    },

  }
  
}
</script>
