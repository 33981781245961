<template>
  <div>
    <div v-if="instance">
      <div class="row">
        <div class="col pe-0">

          <div class="card">
            <div class="card-body">
              
              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goEdit">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
                <a href="#" class="btn btn-outline-info" @click.prevent="goRingfence">
                  <i class="uil uil-bag-slash me-1"></i>
                  <span>Manage Ringfence</span>
                </a>
                <a href="#" class="btn btn-outline-primary" @click.prevent="goSwitch">
                  <i class="uil uil-exchange me-1"></i>
                  <span>Switch Company</span>
                </a>
              </div>
              
              <h3>{{ instance.name }}</h3>

              <p>Create outreach email introduction using AI.</p>

              <div v-if="isLoading">
                <loader></loader>
                <p class="my-4">Working...</p>
              </div>

              <div v-else>
                <button class="btn btn-primary mb-3" @click.prevent="goGenerate">
                  <i class="uil uil-comment-alt-info me-1"></i>
                  <span>Make New Intro</span>
                </button>
              
                <button class="btn btn-outline-primary ms-2 mb-3" @click.prevent="goEditPrompt">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit GPT Prompt</span>
                </button>
              
                <div class="card mt-3">
                  <div class="card-body">
                    <p class="pre" v-html="intro" v-if="intro"></p>
                    <p v-else>No intro generated yet.</p>
                  </div>
                </div>

              </div>
              
            </div>
          </div>
        </div> <!-- end col -->
      </div>

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">
              <a href="#" class="btn btn-outline-primary me-2" @click.prevent="goEmailIntro">
                <i class="uil uil-comment-alt-info me-1"></i>
                <span>Generate Email Intro</span>
              </a>
              <a href="#" class="btn btn-primary" @click.prevent="goMatch">
                <i class="uil uil-adjust-circle me-1"></i>
                <span>Match Investors</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'startup',

  mixins: [SearchMixin],

  data() {
    return {
      instance: null,
      intro: null,
      isLoading: true,
    }
  },

  computed: {
    ...mapState({
      current: state => state.startups.current
    })
  },
  
  mounted() {
    window.scrollTo(0, 0)
    this.isLoading = true
    const startupId = this.$route.params.id || (this.current && this.current.id)
    if (startupId) {
      this.$store.dispatch('LOAD_STARTUP', startupId)
        .then((response) => {
          this.instance = response.data
          this.intro = this.instance.generated_email_intro || this.intro
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('SET_CURRENT_STARTUP', null)
          this.$router.push({ name: 'startups_selector' })
        })
        .finally(() => {
          this.isLoading = false
        })
    } else {
      this.$router.push({ name: 'startups_selector' })
    }
  },

  methods: {
    goEdit() {
      this.$router.push(`/startups/${this.instance.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.instance.id}/delete`)
    },
    goMatch() {
      this.$router.push('/investors/contacts?match=true')
    },
    goRingfence() {
      this.$router.push(`/startups/${this.instance.id}/ringfence`)
    },
    goSwitch() {
      this.$router.push({ name: 'startups_selector' })
    },
    goGenerate() {
      Swal.fire({
        title: "Generate Email Intro?",
        text: "This will create a new email introduction using AI. Please ensure you have a sufficient balance in your Claude Account.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, I have enough credits!"
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoading = true
          const payload = {
            id: this.instance.id,
            save: true
          }
          this.$store.dispatch('GENERATE_EMAIL_INTRO', payload)
            .then((response) => {
              this.intro = response.data
              Swal.fire({
                title: "Generated!",
                text: "Your email introduction has been created.",
                icon: "success"
              });
            })
            .catch((error) => {
              console.error(error)
              Swal.fire({
                title: "Error",
                text: error.response?.data?.error || "Failed to generate email introduction.",
                icon: "error"
              });
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    goEditPrompt() {
      this.$router.push(`/me/gpt-prompts`)
    },
  }
}
</script>
