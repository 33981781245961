<template>
  <div class="wrapper">
    <div class="leftside-menu mt-0">

      <div class="p-3">
        <button v-if="current" class="float-end btn btn-sm btn-light rounded-pill" @click="setCurrent(null)">
          <i class="uil uil-times"></i>
        </button>
        <h5 class="mb-3">Selected list:</h5>
        <div v-if="current">
          <h3>
            {{ current.saved_search_name }}
          </h3>
          <div class="muted fw-bold">
            #{{ current.id }}
          </div>
          <div>
          by {{ current.account_manager_display }}
          </div>
          <div>
            Hide Ringfence: {{ current.hide_ringfence }}
          </div>
          <div class="pt-2">
            {{ $filters.datetimeformat(current.datetime) }}
          </div>
          <div class="mt-4" v-if="current.file_downloaded">
            <a :href="current.file_downloaded" target="_blank" class="pre fw-bold">
              <i class="uil uil-arrow-circle-down"></i> Download New Search CSV
            </a>
            <br><br>
            <loader size="32px" v-if="current.generatingCsv"></loader>
            <a v-else href="#" @click.prevent="generateCsv">
              <i class="uil uil-redo"></i> Refresh New Search CSV
            </a>
          </div>
          <div class="mt-4" v-else>
            <a href="#" @click.prevent="generateCsv" v-if="!current.file_downloaded">
              <i class="uil uil-arrow-circle-down"></i> Generate New CSV
            </a>
          </div>
          <div class="mt-4" v-if="current.old_file_downloaded">
            <a :href="current.old_file_downloaded" target="_blank" class="pre fw-bold">
              <i class="uil uil-arrow-circle-down"></i> Download Old Search CSV
            </a>
            <br><br>
            <loader size="32px" v-if="current.generatingCsv"></loader>
            <a v-else href="#" @click.prevent="generateOldCsv">
              <i class="uil uil-redo"></i> Refresh Old Search CSV
            </a>
          </div>
          <div class="mt-4" v-else>
            <a href="#" @click.prevent="generateOldCsv" v-if="!current.old_file_downloaded">
              <i class="uil uil-arrow-circle-down"></i> Generate Old CSV
            </a>
          </div>
          <div v-if="errors.has(current.id)">
          <p class="text-danger">
            Error: {{ errors.get(current.id).detail }}
          </p>
        </div>
          <div class="mt-4">
            <loader size="32px" v-if="isDeleting"></loader>
            <a v-else href="#" target="_blank" class="pre text-danger" @click.prevent="deleteSearch(current)">
              <i class="uil uil-trash"></i> Delete This Search
            </a>
          </div>
          <div class="mt-4 d-flex flex-column align-items-start">
            <a href="#" class="btn-sm btn-light mt-2" @click.prevent="viewCompanies(current)">
              <i class="uil uil-moneybag"></i>
              View Investors <span v-if="current.number_of_investors">({{ current.number_of_investors }})</span>
            </a>

            <a href="#" class="btn-sm btn-light mt-2" @click.prevent="viewContacts(current)">
              <i class="uil uil-users-alt"></i>
              View Contacts <span v-if="current.number_of_contacts">({{ current.number_of_contacts }})</span>
            </a>

            <!-- <a v-if="!current.pushed_to_replyio" href="#" class="btn btn-outline-primary mt-4" :class="{ disabled: !current }" @click.prevent="goNext">
              <i class="uil uil-envelope-add"></i>
              Go to Outreach
            </a> -->
            
            <a v-if="!current.pushed_to_replyio" href="#" class="btn btn-outline-primary mt-4" :class="{ disabled: !current }" @click.prevent="goPersonalise">
              <i class="uil uil-comment-check"></i>
              Go to Personalise
            </a>

            <a v-else class="btn btn-outline-primary mt-4" :class="{ disabled: !current }" @click.prevent="goOutreach">
              <i class="uil uil-message"></i>
              View Active Campaign
            </a>
          </div>
        </div>
        <div v-else>
          <p class="muted">No list selected</p>
        </div>

        <div class="clearfix"></div>
        <!-- Sidebar -left -->
      </div>

    </div>
    <!-- Left Sidebar End -->
    
    <div class="content-page">
      <div class="content">
        <div class="row">
          
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="col-12">
                    <div class="table-data">
                      <div class="table-container">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th class="orderable" style="width: 35%">
                                Name
                              </th>
                              <th class="orderable" style="width: 50%">
                                Attributes
                              </th>
                              <th class="orderable" style="width: 15%">
                                Actions
                              </th>
                            </tr>
                          </thead>

                          <tbody v-if="searches">
                            <tr
                              v-for="(search, index) in searches.results"
                              :key="search.id"
                              :class="{ 'tr-active': isCurrent(search) }"
                            >
                              <td class="p-2">
                                <span class="badge bg-secondary text-white" v-if="search.is_astel_search">Astel Network Search</span>
                                <br/>
                                <a href="#" class="fw-bold" @click.prevent="viewCompanies(search)">
                                  {{ search.saved_search_name }}
                                </a>
                                <div class="muted fw-bold">
                                  #{{ search.id }}
                                </div>
                                <div>
                                by {{ search.account_manager_display }}
                                </div>
                                <div>
                                  Hide Ringfence: {{ search.hide_ringfence }}
                                </div>
                                <div class="pt-2">
                                  {{ $filters.datetimeformat(search.datetime) }}
                                </div>
                                <div v-if="search.pushed_to_replyio" class="mt-2">
                                  (pushed to reply.io)
                                </div>
                                <div v-if="isCurrent(search)">
                                  (current)
                                </div>
                                <div class="mt-4" v-if="search.file_downloaded">
                                  <a :href="search.file_downloaded" target="_blank" class="pre fw-bold">
                                    <i class="uil uil-arrow-circle-down"></i> Download New Search CSV
                                  </a>
                                </div>
                                <div class="mt-4" v-if="search.old_file_downloaded">
                                  <a :href="search.old_file_downloaded" target="_blank" class="pre fw-bold">
                                    <i class="uil uil-arrow-circle-down"></i> Download Old Search CSV
                                  </a>
                                </div>
                              </td>
                              <td class="p-2">
                                <span class="badge bg-secondary text-white">
                                  <span v-if="search.is_best_match">Best Matches</span>
                                  <span v-else-if="search.is_career_match">Career Matches</span>
                                  <span v-else-if="search.is_same_year_career_match">Same Year Career Matches</span>
                                  <span v-else-if="search.selected_match_group">
                                    <span v-if="search.breakdown">{{ search.breakdown.label }}: </span>
                                    {{ search.selected_match_group }}
                                  </span>
                                  <span v-else>All Matches</span>
                                  <span v-if="search.tier"> (Tier: {{ search.tier }})</span>
                                </span>
                                <div v-if="getQuery(search)">
                                  <div class="mb-0" v-for="(group, gIndex) in getQuery(search)" :key="gIndex">
                                    <div v-for="(field, fIndex) in group.fields" :key="fIndex" class="mb-0 ms-2">
                                      <span class="fw-bold me-2">{{ $filters.unslugify(field.field_name) }}</span>
                                      <span v-if="field.exclude" class="me-1 small">not:</span>
                                      <span v-for="value in field.value" :key="value" class="badge badge-outline-secondary rounded-pill me-1">{{ getValue(field, value) }}</span>
                                      <div class="small" v-if="fIndex < group.fields.length - 1">{{ group.operator }}</div>
                                    </div>
                                    <div class="small my-1" v-if="gIndex < getQuery(search).length - 1">AND</div>
                                  </div>
                                </div>
                                <div v-else>
                                  <p class="mb-0" v-for="[key, value] in parseQs(search.search_queries_attributes)">
                                    <span class="fw-bold me-2">{{ $filters.unslugify(key) }}</span>
                                    {{ value }}
                                  </p>
                                </div>
                              </td>
                              <td class="p-2">
                                <div class="d-flex flex-column justify-space-between text-center">
                                  <template v-if="!isCurrent(search)">
                                    <button class="btn btn-sm btn-outline-primary" @click.prevent="setCurrent(search)">
                                      Select
                                    </button>
                                  </template>
                                  <template v-else>
                                    <button class="btn btn-sm btn-outline-secondary" @click.prevent="setCurrent(search)">
                                      <i>Selected</i>
                                    </button>
                                  </template>

                                  <a href="#" class="btn-sm btn-light mt-2" @click.prevent="viewCompanies(search)">
                                    <i class="uil uil-moneybag"></i>
                                    {{ search.number_of_investors }} Investors
                                  </a>

                                  <a href="#" class="btn-sm btn-light mt-2" @click.prevent="viewContacts(search)">
                                    <i class="uil uil-users-alt"></i>
                                    {{ search.number_of_contacts }} Contacts
                                  </a>

                                </div>
                              </td>
                              <td>
                                
                              </td>
                              <td>
                              </td>
                            </tr>
                        </tbody>
                      </table>

                      <pagination
                        :items="searches"
                        :currentPage="page"
                        :perPage="perPage"
                        @pagechanged="onPageChanged"
                      >
                      </pagination>

                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">
              <button v-if="!current || current && !current.pushed_to_replyio" class="btn btn-primary" :class="{ disabled: !current }" @click.prevent="goPersonalise">
                <i class="uil uil-comment-check"></i>
                Go To Personalise
              </button>

              <button v-else class="btn btn-primary" :class="{ disabled: !current }" @click.prevent="goOutreach">
                <i class="uil uil-envelope-add"></i>
                Go to Outreach
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swal from 'sweetalert2'
import queryString from 'query-string'

export default {
  name: 'searches',

  data() {
    return {
      page: 1,
      perPage: 20,
      isDeleting: false,
      errors: new Map(),
    }
  },

  computed: {
    ...mapState({
      searches: state => state.investors.searches,
      current: state => state.investors.currentSearch,
      startup: state => state.startups.current,
      optionsInvestorTypes: state => state.investors.investorTypes,
      optionsInvestmentStages: state => state.investors.investmentStages,
      optionsCountries: state => state.investors.countries,
      searchFields: state => state.investors.searchFields,
      astelSearchFields: state => state.astelInvestors.searchFields,
      user: state => state.auth.user,
      siteCompany: state => state.auth.siteCompany,
    })
  },

  mounted() {
    this.$store.dispatch('LOAD_SEARCH_OPTIONS')
    this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id })
  },

  methods: {
    onPageChanged(pageNumber) {
      const offset = (pageNumber - 1) * this.perPage
      this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id, params: { offset } })
        .then(() => {
          this.page = pageNumber
        })
    },
    getQuery (search) {
      try {
        return JSON.parse(search.search_queries_attributes)
      } catch (e) {
        return null
      }
    },
    getValue (field, value) {
      if (field.field_name === 'investor_type') {
        const option = this.optionsInvestorTypes.find((o) => o.id === value)
        return option ? option.name : ''
      } else if (field.field_name === 'investment_stages') {
        const option = this.optionsInvestmentStages.find((o) => o.id === value)
        return option ? option.name : ''
      } else {
        return value
      }
    },
    parseQs (qs) {
      if (!qs) return []
      return Object.entries(queryString.parse(qs))
    },
    setCurrent(search) {
      if (search) {
        this.$store.commit('SET_PERSONALISE_CONTACTS', null)
        if(search?.is_astel_search){
          this.$store.dispatch('LOAD_ASTEL_CURRENT_SEARCH_AND_FETCH_FIELDS', { startupId: this.startup.id, search });
        } else{
          this.$store.dispatch('LOAD_CURRENT_SEARCH_AND_FETCH_FIELDS', { startupId: this.startup.id, search });
        }
      } else {
        this.$store.commit('SET_PERSONALISE_CONTACTS', null)
        this.$store.commit('SET_CURRENT_SEARCH', null)
      }
    },
    goPersonalise() {
      this.$store.dispatch(
        'LOAD_SEARCH', 
        { startupId: this.startup.id, searchId: this.current.id }
      ).then(() => {
        if (this.current.status === "COMPLETED") {
          this.$router.push({ name: 'investors_personalise' })
        } else {
          Swal.fire({
            icon: "info",
            title: "Kindly wait!",
            text: `We are still fetching the Contacts and the Companies for the search ${this.current.saved_search_name}, kindly try again in a few minutes!`,
          });
        }
      })
      .catch(error => {
        console.error('Error loading search:', error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while loading the search. Please try again.",
        });
      });
    },
    goOutreach() {
      this.$router.push({ name: 'investors_outreach' })
    },

    triggerCSVGeneration() {
      this.current.generatingCsv = true
      this.$store.dispatch(
        'GENERATE_SEARCH_CSV',
        { startupId: this.startup.id, searchId: this.current.id }
      ).then(() => {
        this.$store.dispatch('LOAD_SEARCH', { startupId: this.startup.id, searchId: this.current.id })
      }).catch((error) => {
        this.errors.set(this.current.id, error.response.data);  
      }).finally(() => {
        this.current.generatingCsv = false;
      })
    },
    
    generateCsv() {
      if(this.siteCompany.is_whitelabel){
        this.$store.dispatch('LOAD_USER').then(() => {
          if (this.user.remaining_download_limit == 0){
            Swal.fire({
              title: "Apologies, you have run out of download credits!",
              text: "Please reach out to muboriz@astelios.com to purchase more credits.",
              icon: "warning",
              footer: '<a href="/credits">View your credit usage</a>'
            })
          } else {
            Swal.fire({
              title: "Are you sure you want to download the CSV?",
              html: `Downloading CSV will use one of your download credits. You have ${this.user.remaining_download_limit} credits remaining.`,
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, download CSV",
              cancelButtonText: "Cancel"
            }).then((result) => {
              if (result.isConfirmed) {
                this.triggerCSVGeneration()
              }
            });
          }
        })
      } else {
        this.triggerCSVGeneration()
      }
    },

    triggerOldCSVGeneration() {
      this.current.generatingCsv = true
      this.$store.dispatch(
        'GENERATE_OLD_SEARCH_CSV',
        { startupId: this.startup.id, searchId: this.current.id }
      ).then(() => {
        this.$store.dispatch('LOAD_SEARCH', { startupId: this.startup.id, searchId: this.current.id })
      }).catch((error) => {
        this.errors.set(this.current.id, error.response.data);  
      }).finally(() => {
        this.current.generatingCsv = false;
      })
    },

    generateOldCsv() {
      if(this.siteCompany.is_whitelabel){
        this.$store.dispatch('LOAD_USER').then(() => {
          if (this.user.remaining_download_limit == 0){
            Swal.fire({
              title: "Apologies, you have run out of download credits!",
              text: "Please reach out to muboriz@astelios.com to purchase more credits.",
              icon: "warning",
              footer: '<a href="/credits">View your credit usage</a>'
            })
          } else {
            Swal.fire({
              title: "Are you sure you want to download the CSV?",
              html: `Downloading CSV will use one of your download credits. You have ${this.user.remaining_download_limit} credits remaining.`,
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, download CSV",
              cancelButtonText: "Cancel"
            }).then((result) => {
              if (result.isConfirmed) {
                this.triggerOldCSVGeneration()
              }
            });
          }
        })
      } else {
        this.triggerOldCSVGeneration()
      }
    },
    
    deleteSearch(search) {
      if (confirm(`Are you sure you want to delete '${search.saved_search_name}'?`)) {
        this.$store.dispatch('DELETE_SEARCH', { startupId: this.startup.id, searchId: search.id })
          .then(() => {
            this.$store.dispatch('LOAD_SEARCHES', { startupId: this.startup.id })
            this.$store.commit('SET_CURRENT_SEARCH', null)
          })
      }
    },
    
    setSearchForm (search) {
      const options = {
        default_exclude: search.default_exclude,
        hide_ring_fence: search.hide_ringfence,
        hide_undeliverable_contact: search.hide_undeliverable_contact
      }
      this.$store.commit('SET_SEARCH_OPTIONS', options)
      
      const query = this.getQuery(search)
      if (query) {
        var form = []
        for (const fieldGroup of query) {
          var group = {
            fields: [],
            operator: fieldGroup.operator
          }
          for (const field of fieldGroup.fields) {
            const fieldDef = this.searchFields.find((f) => f.field_name === field.field_name)
            const condition = fieldDef.conditions.find((c) => c.id === field.condition)
            group.fields.push({
              field_name: field.field_name,
              field: fieldDef,
              condition: condition,
              value: field.value,
              exclude: field.exclude
            })
          }
          form.push(group)
        }
        this.$store.commit('SET_SEARCH_FORM', form)
      } else {
        this.$store.commit('SET_SEARCH_FORM', null)
      }
    },
    setAstelSearchForm (search) {
      const options = {
        default_exclude: search.default_exclude,
        hide_ring_fence: search.hide_ringfence,
        hide_undeliverable_contact: search.hide_undeliverable_contact
      }
      this.$store.commit('SET_ASTEL_SEARCH_OPTIONS', options)
      
      const query = this.getQuery(search)
      if (query) {
        var form = []
        for (const fieldGroup of query) {
          var group = {
            fields: [],
            operator: fieldGroup.operator
          }
          for (const field of fieldGroup.fields) {
            const fieldDef = this.astelSearchFields.find((f) => f.field_name === field.field_name)
            const condition = fieldDef.conditions.find((c) => c.id === field.condition)
            group.fields.push({
              field_name: field.field_name,
              field: fieldDef,
              condition: condition,
              value: field.value,
              exclude: field.exclude
            })
          }
          form.push(group)
        }
        this.$store.commit('SET_ASTEL_SEARCH_FORM', form)
      } else {
        this.$store.commit('SET_ASTEL_SEARCH_FORM', null)
      }
    },
    viewCompanies (search) {
      if (search.is_astel_search){
        this.setAstelSearchForm(search)
        this.$router.push({ name: 'astel_campaigns', query: { search: 'true' } })
      } else {
        this.setSearchForm(search)
        this.$router.push({ name: 'investors_contacts', query: { search: 'true' } })
      }
    },
    viewContacts (search) {
      if (search.is_astel_search){
        this.setAstelSearchForm(search)
        this.$router.push({ name: 'astel_campaigns', query: { search: 'true' } })
      } else {
        this.setSearchForm(search)
        this.$router.push({ name: 'investors_contacts', query: { search: 'true' } })
      }
    },
    isCurrent (search) {
      return this.current ? this.current.id === search.id : false
    }
  }
}
</script>
