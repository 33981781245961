<template>
  <div class="wrapper">
    <div class="leftside-menu mt-0">

      <div class="p-3">
        <button v-if="current" class="float-end btn btn-sm btn-light rounded-pill" @click="setCurrent(null)">
          <i class="uil uil-times"></i>
        </button>
        <h5 class="mb-3">Selected list:</h5>
        <div v-if="current">
          <h3>
            {{ current.saved_search_name }}
          </h3>
          <div class="muted fw-bold">
            #{{ current.id }}
          </div>
          <div>
          by {{ current.account_manager_display }}
          </div>
          <div>
            Hide Ringfence: {{ current.hide_ringfence }}
          </div>
          <div class="pt-2">
            {{ $filters.datetimeformat(current.datetime) }}
          </div>
          <div class="mt-4" v-if="current.file_downloaded">
            <a :href="current.file_downloaded" target="_blank" class="pre fw-bold">
              <i class="uil uil-arrow-circle-down"></i> Download CSV
            </a>
          </div>
          <template v-if="hasReplyIoKey">
            <!-- Action Buttons -->
            <div class="mt-4 d-flex flex-column align-items-start" v-if="current.pushed_to_replyio">
              <a href="#" class="btn btn-outline-secondary" @click.prevent="">
                <i class="uil uil-pause"></i>
                Active Campaign
              </a>
            </div>
          </template>
          <div class="mt-4 d-flex flex-column align-items-start">
            <a href="#" class="btn btn-outline-secondary" @click.prevent="$router.push(`/startups/${startup.id}/emailintro`)">
              <i class="uil uil-comment-alt-info me-1"></i>
              Get Email Intro
            </a>
          </div>
        </div>
        <div v-else>
          <p class="muted">No list selected</p>
        </div>

        <div class="clearfix"></div>
          <!-- Sidebar -left -->
      </div>

    </div>
    <!-- Left Sidebar End -->
      
    <div class="content-page">
      <div class="content">
        <div class="row">
          
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12" v-if="current">

                  <template v-if="hasReplyIoKey">
                    <div v-if="current.pushed_to_replyio" class="table-data">
                      <div class="table-container">
                          <table class="table table-hover">
                            <thead>
                              <tr>
                                <th class="orderable">
                                  Name
                                </th>
                                <th class="orderable">
                                  Email
                                </th>
                                <th class="orderable">
                                  Timestamp
                                </th>
                                <th>
                                  Status
                                </th>
                              </tr>
                            </thead>

                            <tbody v-if="contacts && contacts.results && contacts.results.length">
                              <tr v-for="contact in contacts.results" :key="contact.id">
                                <td>
                                  {{ contact.investors_contact.first_name }} {{ contact.investors_contact.last_name }}
                                </td>
                                <td>
                                  {{ contact.investors_contact.email }}
                                </td>
                                <td>
                                  {{ $filters.datetimeformat(contact.created_date) }}
                                </td>
                                <td>
                                  <td>
                                    <span class="badge badge-secondary-lighten me-2" v-if="contact.pushed_to_reply_io">Pushed</span>
                                    <span class="badge badge-secondary-lighten me-2" v-if="contact.email_sent">Email sent</span>
                                    <span class="badge badge-primary-lighten me-2" v-if="contact.email_opened">Email opened</span>
                                    <span class="badge badge-success-lighten me-2" v-if="contact.email_replied">Replied</span>
                                    <template v-if="contact.error_message">
                                      <span class="badge badge-danger-lighten me-2">Error</span>
                                      <br>
                                      <span class="text-red small" v-if="contact.error_message">
                                        {{ contact.error_message }}
                                      </span>
                                    </template>
                                  </td>
                                </td>
                              </tr>
                          </tbody>
                          <tbody v-else>
                            <tr>
                              <td colspan="6">
                                <p class="muted">No contacts found</p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        
                        <pagination
                          :items="contacts"
                          :currentPage="page"
                          :perPage="perPage"
                          @pagechanged="onPageChanged"
                        >
                        </pagination>

                      </div>
                    </div>

                    <div v-else-if="current.push_started">
                      <div class="card">
                        <div class="card-body">
                          <h3>Pushing contacts to Reply.io</h3>
                          <p>
                            Please wait while we push the contacts to your Reply.io account.
                          </p>
                          <div class="mb-3">
                            <loader size="32px"></loader>
                          </div>
                          <div v-if="!current.push_in_progress">
                            Starting...
                          </div>
                          <div v-else>
                            Talking to Reply.io...
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="!current.pushed_to_replyio" class="card">
                      <div class="card-body">
                        <h3>Select campaign</h3>
                        <p>
                          Select a campaign in your Reply.io Account to push the contact list to.
                        </p>
                        <template v-if="campaignsLoaded">
                          <v-select
                            v-if="campaigns.length && campaigns.map"
                            name="campaign"
                            v-model="form.campaign"
                            :options="campaigns.map((x) => ({ id: x.id, label: x.name }))"
                            :reduce="(x) => x.id"
                          >
                          </v-select>
                          <p v-else-if="!error">
                            No campaigns (sequences) found in your Reply.io account.<br>
                            Create a new Sequence in your Reply.io account and come back here.<br>
                          </p>
                          <div v-if="form.campaign" class="mt-3">
                            <div class="form-group mb-3">
                              <label for="id_email_opener">Company Email Opener Field:</label>
                              <input
                                id="id_email_opener"
                                type="text"
                                name="email_opener"
                                class="form-control"
                                v-model="form.custom_fields.email_opener"
                              >
                            </div>
                            <div class="form-group mb-3">
                              <label for="id_contact_email_opener">Contact Email Opener Field:</label>
                              <input
                                id="id_contact_email_opener"
                                type="text"
                                name="contact_email_opener"
                                class="form-control"
                                v-model="form.custom_fields.contact_email_opener"
                              >
                            </div>
                          </div>
                        </template>
                        <loader v-else size="32px"></loader>

                        <div v-if="current.push_error" class="mt-4 text-red pre">
                          <p>Push failed:</p>
                          <p v-if="current.push_error_message">{{ current.push_error_message }}</p>
                          <p v-else>No error message given.</p>
                          <p>Please try again</p>
                        </div>
                        
                        <div v-if="error && error.detail" class="mt-4 text-red pre">
                          {{ error.detail }}
                        </div>

                        <a href="#" class="btn btn-primary mt-4" :class="{ disabled: isDisabled }" @click.prevent="start">
                          <i class="uil uil-envelope-add"></i>
                          Push Contacts to Reply.io
                        </a>
                      </div>
                    </div>
                  </template>

                  <div v-else class="card">
                    <div class="card-body">
                      <h3>Reply.io API Setup</h3>
                      <p>
                        You need to set up your Reply.io API key before you can start sending emails.
                      </p>
                      <a href="#" class="btn btn-primary" @click.prevent="goReplyIoKey">
                        <i class="uil uil-padlock me-1"></i>
                        <span>Set up Reply.io API Key</span>
                      </a>
                    </div>
                  </div>

                </div>
                <div class="col-12" v-else>
                  <p>Please select a Saved Search first.</p>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'outreach',

  data() {
    return {
      page: 1,
      perPage: 20,
      form: {
        campaign: null,
        campaign_name: null,
        custom_fields: {
          email_opener: null,
          contact_email_opener: null
        }
      },
      campaignsLoaded: false,
      pollTimer: null,
      error: null,
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      startup: state => state.startups.current,
      contacts: state => state.outreach.contacts,
      current: state => state.investors.currentSearch,
      campaigns: state => state.outreach.campaigns,
      replyIoKey: state => state.auth.replyIoKey,
      searches: state => state.investors.searches,
    }),
    hasReplyIoKey() {
      return !!this.replyIoKey.reply_io_api_key
    },
    isDisabled() {
      return !this.form.campaign || this.isSubmitting
    }
  },

  mounted() {
    this.loadCampaigns()

    if (this.current && this.current.id) {
      this.load()
        .then(() => {
          if (this.current.push_started) {
            this.poll()
          }
          if (this.current.pushed_to_replyio) {
            this.loadContacts()
          }
        })
    }

  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
  },

  methods: {

    load() {
      return this.$store.dispatch('LOAD_SEARCH', { startupId: this.startup.id, searchId: this.current.id })
    },

    poll() {
      this.pollTimer = setTimeout(() => {
        this.load()
          .then(() => {
            if (this.current.push_started) {
              this.poll()
            }
            if (this.current.pushed_to_replyio) {
              this.loadContacts()
            }
          })
      }, 5000)
    },

    loadCampaigns() {
      this.$store.dispatch('LOAD_REPLY_IO_KEY', this.startup.id)
        .then(() => {
          if (this.hasReplyIoKey) {
            this.$store.dispatch('LOAD_REPLY_IO_CAMPAIGNS', this.startup.id)
              .catch((error) => {
                this.error = error.response.data
              })
              .finally(() => {
                this.campaignsLoaded = true
              })
          }
        })
    },

    loadContacts(pageNumber=1) {
      const offset = (pageNumber - 1) * this.perPage
      const params = { offset }
      return this.$store.dispatch('LOAD_OUTREACH_CONTACTS', { startupId: this.startup.id, searchId: this.current.id, params })
    },

    onPageChanged(pageNumber) {
      this.loadContacts(pageNumber)
        .then(() => {
          this.page = pageNumber
        })
    },

    goReplyIoKey() {
      this.$router.push({ name: 'reply-io-key' })
    },

    start() {
      const payload = {
        startupId: this.startup.id,
        searchId: this.current.id,
        campaignId: this.form.campaign,
        campaignName: this.form.campaign_name,
        customFields: this.form.custom_fields
      }
      this.error = null
      this.isSubmitting = true
      
      this.$store.dispatch('START_OUTREACH', payload)
        .then(() => {
          this.load()
          this.poll()
        })
        .catch((error) => {
          this.error = error.response.data
        })
        .finally(() => {
          this.isSubmitting = false
        })
    }
    
  },

  watch: {
    'form.campaign': function (val) {
      if (val) {
        const campaign = this.campaigns.find((x) => x.id === val)
        if (campaign) {
          this.form.campaign_name = campaign.name
        }
      }
    }
  }

}
</script>
