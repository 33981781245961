<template>
  <div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
    <div class="row justify-content-center">
      <div class="col-xxl-4 col-lg-5">
        <div class="card">
          <!-- Logo -->
          <div class="card-header pt-4 pb-4 text-center">
            <a href="/">
              <template v-if="siteCompany && siteCompany.company_logo">
                <span class="topnav-logo-lg">
                  <img :src="siteCompany.company_logo" alt="" height="48">
                </span>
              </template>
              <template v-else>
                <span class="topnav-logo-lg">
                  <img src="/static/images/astel_logo.png" alt="" height="48">
                </span>
              </template>
            </a>
          </div>
          
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <h4 class="text-dark-50 text-center pb-0 fw-bold">Sign In</h4>
              <p class="text-muted mb-4">Enter your email address and password to access admin panel.</p>
            </div>
            
            <form method="post" @submit.stop.prevent="signIn">
              <div class="form-group mb-3">
                <label for="id_login">Email:</label>
                <input
                  id="id_login"
                  type="email"
                  name="email"
                  placeholder="Email"
                  autocomplete="email"
                  maxlength="150"
                  class="form-control"
                  required
                  v-model="email"
                >
              </div>
              
              <div class="form-group mb-3">
                <label for="id_password">Password</label>
                <input
                  id="id_password"
                  type="password"
                  name="password"
                  autocomplete="password"
                  class="form-control"
                  required
                  v-model="password"
                >
                
                <div class="invalid-feedback">
                  This field is required.
                </div>
              </div>
              
              <!-- <a href="#" class="text-muted float-right">Forgot your password?</a> -->
              <div class="form-group mb-0 text-center" v-if="!isLoading">
                <button class="btn btn-primary" type="submit" > Log In </button>
              </div>
              <div v-else class="text-center">
                <loader size="32px"></loader>
              </div>
              <div class="text-danger text-center my-3" v-if="error">
                <p v-if="error.code === 'auth/wrong-password'">That didn't work, please try again.</p>
                <p v-else>Something went wrong. Please try again.</p>
              </div>
            </form>
            
            <div class="row mt-3">
              <div class="col-12 text-center">
                <!-- <p class="text-muted">Don't have an account? <a href="#" class="text-muted ms-1"><b>Sign Up</b></a></p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- <div class="container">
    <div class="loader-overlay" v-if="isLoading">
      <loader></loader>
    </div>
    <div class="row mt-5">
      <div class="col-md-6 offset-md-3">
        <h1 class="h3 text-center mt-3 mb-4">Log in</h1>

        <form @submit.stop.prevent="signIn" class="primary px-md-5">
          <div class="mb-3">
            <label for="email" class="form-label">Email:</label>
            <input
              id="email"
              type="email"
              class="form-control"
              placeholder="name@example.com"
              v-model="email"
            >
          </div>

          <div class="mb-3">
            <label for="password" class="form-label">Password:</label>
            <input
              id="password"
              type="password"
              class="form-control"
              placeholder=""
              v-model="password"
              autocomplete="on"
            >
          </div>

          <div class="text-danger text-center my-3" v-if="error">
            <p v-if="error.code === 'auth/wrong-password'">That didn't work, please try again.</p>
            <p v-else>Something went wrong. Please try again.</p>
          </div>
          <div class="d-grid gap-2 mx-2 mt-4">
            <button class="btn btn-primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
              Sign in
            </button>
          </div>
        </form>

        <hr class="space space-sm">
        <p class="text-center"><router-link :to="{ name: 'password-reset' }" class='semibold'>Forgot password?</router-link></p>
        <p class="text-center mt-4 mb-0">Not registered? <router-link :to="{ name: 'register' }" class="semibold">Sign up</router-link></p>
        <hr class="space space-sm">
      </div>
    </div>
  </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'login',
  data () {
    return {
      isLoading: true,
      captchaDone: false,
      captchaToken: null,
      email: null,
      password: null,
      error: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      siteCompany: state => state.auth.siteCompany,
    })
  },
  mounted () {
    this.isLoading = false
    if (this.user) {
      this.$router.push({ name: 'home' }).catch(err => err)
    }
  },
  methods: {
    signIn () {
      const user = {
        username: this.email,
        password: this.password
      }
      this.isLoading = true
      this.error = null
      this.$store.dispatch('LOGIN', user)
        .then(() => {
          this.$router.push({ name: 'home' }).catch(err => err)
        })
        .catch((error) => {
          this.error = error
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style scoped>

</style>
