<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">

      <div v-if="feed" class="row mt-3">

        <div class="col pe-0">
          <div class="card">
            <div class="card-body">
              <h3>Insights Feed</h3>

              <div class="timeline-alt my-4 py-0">
                <div class="timeline-item" v-for="item in feed">
                  <i class="mdi timeline-icon" :class="getInsightIconClasses(item)"></i>
                    <div class="timeline-item-info">
                        <a href="javascript:void(0);" class="fw-bold d-block" :class="getInsightClass(item)">{{ item.title }}</a>
                        <span class="fw-bold mb-1">
                          <router-link class="text-muted" :to="{ name: 'contact_detail', params: { id: item.contact }}">{{ item.contact_name }}</router-link>
                        </span>
                        <br>
                        {{ item.content || item.details || item.title }}
                        <span class="small" v-if="item.link">
                          [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                        </span>
                        <p class="mb-0 pb-2">
                          <small class="text-muted" v-if="item.timestamp">{{ $filters.dateformat(item.timestamp) }}</small>
                          <small class="text-muted" v-else>Recently</small>
                        </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div v-else class="text-center">
        <loader></loader>
      </div>

      <hr class="space space-md" />
      <app-footer></app-footer>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'feed',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      feed: [],
      errors: {},
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)
    this.load()
  },

  methods: {

    load () {
      this.$store.dispatch('LOAD_INSIGHTS_FEED')
        .then((response) => {
          this.feed = response.data
        })
    },

    getDomainFromURL(url) {
      if (!url) return ''
      const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
      return domain
    },

    getInsightTopic(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'LinkedIn Post'
        }
        if (item.type.includes('Podcast')) {
          return 'Podcast'
        }
      }
      return 'Webpage'
    },

    getInsightClass(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'text-info'
        }
        if (item.type.includes('Podcast')) {
          return 'text-danger'
        }
      }
      return 'text-default'
    },

    getInsightIconClasses(item) {
      if (item.type) {
        if (item.type.includes('LinkedIn')) {
          return 'text-info bg-info-lighten mdi-linkedin'
        }
        if (item.type.includes('Podcast')) {
          return 'text-danger bg-danger-lighten mdi-podcast'
        }
      }
      return 'text-default bg-primary-lighten mdi-web'
    }

  }
}
</script>
