<template>
  <div></div>
</template>

<script>
  export default {
    name: 'logout',
    mounted () {
      this.$store.commit('CLEAR_INVESTORS')
      this.$store.dispatch('LOGOUT')
      this.$router.push('/')
      this.$store.commit('SET_HAS_UPLOADED_FILE', false)
      this.$store.commit('SET_SITE_COMPANY', null)
    }
  }
</script>
