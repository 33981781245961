<template>
  <div>
    <div v-if="!isLoading">
      <div class="row">
        <div class="col pe-0">

          <div class="card">
            <div class="card-body">
              
              <h3>Analytics</h3>

              <div class="d-flex flex-row mb-3">
                <div class="form-group">
                  <label for="id_start_date">Start Date:</label>
                  <input
                    id="id_start_date"
                    type="date"
                    name="start_date"
                    class="form-control"
                    v-model="form.start_date"
                  >
                </div>

                <div class="form-group ms-3">
                  <label for="id_start_date">End Date:</label>
                  <input
                    id="id_start_date"
                    type="date"
                    name="start_date"
                    class="form-control"
                    v-model="form.end_date"
                  >
                </div>

                <div class="form-group ms-3">
                  <label for="id_start_date">Select Campaign:</label>
                  <v-select
                    v-if="campaignOptions.length"
                    name="campaign"
                    v-model="form.campaigns"
                    label="name"
                    multiple
                    :clearable="true"
                    :options="campaignOptions"
                    :reduce="(x) => x.id"
                    style="width: 400px;"
                  >
                  </v-select>
                </div>

                <div class="form-group ms-3">
                  <label>&nbsp;</label>
                  <button class="form-control btn btn-primary" @click="load">Submit</button>
                </div>
                
              </div>

              <div v-if="analytics && analytics.emails_sent">
                <div class="row">
                  <div class="col-9">
                    <h3>Emails</h3>
                    <apexchart width="100%" type="area" :options="chartOptions" :series="chartSeries"></apexchart>
                  </div>
                  <div class="col-3">
                    <apexchart width="100%" type="radialBar" :options="openRateOptions" :series="analytics.open_rate.series"></apexchart>
                    <apexchart width="100%" type="radialBar" :options="replyRateOptions" :series="analytics.reply_rate.series"></apexchart>
                    <apexchart width="100%" type="radialBar" :options="bounceRateOptions" :series="analytics.bounce_rate.series"></apexchart>
                  </div>
                </div>
              </div>

              <div class="py-4">
                &nbsp;
              </div>

            </div>
          </div>
        </div> <!-- end col -->
      </div>

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            &nbsp;
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'
import { mapState } from 'vuex'

export default {
  name: 'startup',

  data() {
    return {
      startup: null,
      analytics: null,
      error: null,
      isLoading: true,
      form: {
        start_date: null,
        end_date: null,
        campaigns: [],
      },
      campaignOptions: [],
      chartOptions: {
          chart: {
            id: 'vuechart',
            animations: {
                enabled: false,
            },
            toolbar: {
              show: false
            }
          },
          stroke: {
            curve: 'smooth',
          },
          yaxis: {
            labels: {
              formatter: function(val) {
                return val.toFixed(0);
              }
            }
          },
          xaxis: {
            type: 'datetime'
          },
      },
      openRateOptions: {
        labels: ['Open Rate'],
        colors: ["#01E396"],
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                color: "#888",
                fontSize: "13px"
              },
              value: {
                color: "#111",
                fontSize: "20px",
              }
            }
          }
        }
      },
      replyRateOptions: {
        labels: ['Reply Rate'],
        colors: ["#FEB01A"],
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                fontSize: "13px"
              },
              value: {
                fontSize: "20px",
              }
            }
          }
        }
      },
      bounceRateOptions: {
        labels: ['Bounce Rate'],
        colors: ["#FF4560"],
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -10,
                fontSize: "13px"
              },
              value: {
                fontSize: "20px",
              }
            }
          }
        }
      },
    }
  },

  computed: {
    ...mapState({
      current: state => state.startups.current
    }),
    chartSeries() {
      if (this.analytics && this.analytics.emails_sent && this.analytics.emails_opened && this.analytics.emails_replied && this.analytics.emails_bounced) {
        const series = [
          this.analytics.emails_sent,
          this.analytics.emails_opened,
          this.analytics.emails_replied,
          this.analytics.emails_bounced,
        ]
        return series
      }
      return []
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)
    const startupId = this.$route.params.id || (this.current && this.current.id)
    if (startupId) {
      this.$store.dispatch('LOAD_STARTUP', startupId)
        .then((response) => {
          this.startup = response.data
          this.load()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('SET_CURRENT_STARTUP', null)
          this.$router.push({ name: 'startups_selector' })
        })
    } else {
      this.$router.push({ name: 'startups_selector' })
    }
  },

  methods: {
    load() {
      this.isLoading = true
      this.$store.dispatch('LOAD_ANALYTICS', this.form)
        .then((response) => {
          this.analytics = response.data
          this.form.start_date = response.data.start_date
          this.form.end_date = response.data.end_date
          this.campaignOptions = response.data.campaigns
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
