<template>
    <div class="svg-loader">
      <svg :style="{ width: size, height: size }" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="background: none; display: block; shape-rendering: auto;" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#727cf5" stroke="none">
          <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"></animateTransform>
        </path>
      </svg>
    </div>
</template>

<script>
export default {
  name: 'loader',
  props: {
    size: {
      type: String,
      default: '128px'
    }
  }
}
</script>

<style>
</style>
