import { createStore } from 'vuex'

import VuexPersistence from 'vuex-persist'

import auth from './modules/auth'
import loader from './modules/loader'
import investors from './modules/investors'
import astelInvestors from './modules/astelInvestors'
import startups from './modules/startups'
import personalise from './modules/personalise'
import outreach from './modules/outreach'
import engagementcallinfo from './modules/engagementcall'
import pitchDeck from './modules/pitchDeck'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'verse'
})

export default createStore({
  plugins: [vuexLocal.plugin],
  modules: {
    auth,
    loader,
    investors,
    astelInvestors,
    startups,
    personalise,
    outreach,
    engagementcallinfo,
    pitchDeck
  }
})
