<template>
    <div class="container mt-4">
      <div class="card">
        <div class="card-header d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <router-link to="/pitch-deck/reports" class="back-link me-2">
              <i class="uil uil-angle-left"></i>
            </router-link>
            <h2 class="header-title">Pitch Deck Analysis for {{ pitchDeck?.name }}</h2>
          </div>
          <button class="btn btn-primary" @click="printReport">
            <i class="uil uil-print me-1"></i>Print Report
          </button>
        </div>
        <div class="card-body pt-0">
          <v-md-preview :text="pitchDeck?.report"></v-md-preview>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import VMdPreview from '@kangc/v-md-editor/lib/preview'
  import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
  import '@kangc/v-md-editor/lib/theme/style/github.css'
  import { marked } from 'marked'

  // Configure v-md-editor
  VMdPreview.use(githubTheme)

  export default {
    name: 'PitchDeckDetail',
    components: {
      VMdPreview
    },
    computed: {
      ...mapState({
        pitchDeck: state => state.pitchDeck.pitchDeck,
      }),
    },
    created() {
      this.fetchReport()
    },
    methods: {
      fetchReport() {
        this.$store.dispatch('FETCH_PITCH_DECK', this.$route.params.id)
      },
      printReport() {
        const content = this.pitchDeck?.report || '';
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
          <html>
            <head>
              <title>${this.pitchDeck?.name} - Pitch Deck Analysis</title>
              <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/github-markdown-css">
              <style>
                @page {
                  size: A4;
                  margin: 20mm 20mm 40mm 20mm;
                }
                
                body { 
                  padding: 20px;
                  margin: 0; 
                }
                
                .markdown-body { 
                  max-width: 800px; 
                  margin: 0 auto; 
                }

                .footer {
                  position: running(footer);
                  text-align: center;
                  font-size: 12px;
                  color: #666;
                }

                @page {
                  @bottom-center {
                    content: element(footer);
                  }
                }
              </style>
            </head>
            <body>
              <div class="markdown-body">
                ${marked(content)}
              </div>
              <div class="footer">
                Astel Ventures: Pitch Deck Analysis for ${this.pitchDeck?.name}
              </div>
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.onload = () => {
          printWindow.print();
          printWindow.onafterprint = () => {
            printWindow.close();
          };
        };
      }
    }
  }
  </script>
  
  <style scoped>
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .back-link {
    color: #6c757d;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
  }
  
  .back-link:hover {
    color: #495057;
  }
  
  .header-title {
    font-size: 1.25rem;
    color: #6c757d;
    margin: 0;
    font-weight: normal;
  }
  </style>
  