<template>
  <div>
    <div v-if="instance">
      <div class="row">
        <div class="col pe-0">

          <div class="card">
            <div class="card-body">
              
              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goEdit">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete" v-if="user && user.is_staff">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
                <a href="#" class="btn btn-outline-info" @click.prevent="goRingfence" v-if="user && user.is_staff">
                  <i class="uil uil-bag-slash me-1"></i>
                  <span>Manage Ringfence</span>
                </a>
                <a href="#" class="btn btn-outline-primary" @click.prevent="goSwitch" v-if="user && user.is_staff">
                  <i class="uil uil-exchange me-1"></i>
                  <span>Switch Company</span>
                </a>
              </div>
              
              <h3>{{ instance.name }}</h3>

              <p class="mb-2">
                <span class="fw-bold me-2">Company Location City:</span>
                <template v-if="instance.company_location_city && instance.company_location_city.length">
                  <span v-for="item in instance.company_location_city" :key="item" class="d-block pre">
                    <template v-if="item">{{ item.name }}</template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2">
                <a :href="instance.website" target="_blank">{{ instance.website }}</a>
              </p>

              <p class="mb-2" v-if="instance.registered_country">
                <span class="fw-bold me-2">Registered Country:</span>
                <template v-if="instance.registered_country && instance.registered_country.length">
                  <span v-for="item in instance.registered_country" :key="item" class="d-block pre">
                    <template v-if="item">{{ item.name }}</template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2" v-if="instance.astel_shared_email">
                <span class="fw-bold me-2">Astel Shared Email:</span>
                {{ instance.astel_shared_email }}
              </p>

              <div class="mb-2" v-if="instance.description">
                <p class="fw-bold mb-0">Description:</p>
                <p class="pre">{{ instance.description }}</p>
              </div>

              <div class="mb-2">
                <span class="fw-bold me-2">Industry and sectors tags:</span>
                <div class="industry-sectors-container">
                  <template v-if="instance.industry_sector_tags && instance.industry_sector_tags.length">
                  <span v-for="item in instance.industry_sector_tags" :key="item" class="d-block pre">
                    {{ item }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
                </div>
              </div>
              <p class="mb-2">
                <span class="fw-bold me-2">Expansion Countries:</span>
                <template v-if="instance.expansion_countries && instance.expansion_countries.length">
                  <span v-for="item in instance.expansion_countries" :key="item" class="d-block pre">
                    <country-flag :country='item.code' size='small' />
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2">
                <span class="fw-bold me-2">Team Members:</span>
                <!-- Team members are in instance.team as contacts with first_name and last_name and email -->
                <ul v-if="instance.team && instance.team.length" class="">
                  <li v-for="item in instance.team" :key="item">
                    <router-link :to="{ name: 'contact_detail', params: { id: item.id } }">
                      {{ item.first_name }} {{ item.last_name }} ({{ item.email }})
                    </router-link>
                  </li>
                </ul>
                <br>
                <router-link :to="{ name: 'contact_new_startup', params: { startup_id: instance.id } }">Add new team member</router-link>
              </p>

            </div>
          </div>
        </div> <!-- end col -->
      </div>

      <div class="row">

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                <span v-if="siteCompany.is_whitelabel">Identifying Career Matches Status</span>
                <span v-else>Career Opener One Liner Status</span>
              </h4>

              <p class="mb-2">
                <span v-if="instance.has_email_opener">Career matches have been identified!</span>
                <span v-else>
                  Career matches are not yet identified, Kindly press 
                    <span class="badge text-white text-bg-dark">
                      <span v-if="siteCompany.is_whitelabel">Identify Career Matches</span>
                      <span v-else>Create Career Opener One Liner</span>
                    </span> 
                  Button to initiate identifying career matches!
                </span>
              </p>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Match Score Calculation Status
              </h4>

              <p class="mb-2">
                <span class="badge text-white text-bg-dark" v-if="instance.matching_score_calculation_status == 'PENDING'">Pending, Kindly press 'Calculate Match Score' Button to initiate calculating match score!</span>
                <span class="badge text-white text-bg-dark" v-if="instance.matching_score_calculation_status == 'IN_PROGRESS'">Kindly wait while we complete calculating Match Score for you!</span>
                <span class="badge text-white text-bg-dark" v-if="instance.matching_score_calculation_status == 'COMPLETED'">Match Score has been calculated, happy searching investors!</span>
              </p>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Team information
              </h4>

              <p class="mb-2" v-if="instance.title">
                <span class="fw-bold me-2">Title:</span>
                {{ instance.title }}
              </p>

              <p class="mb-2" v-if="instance.first_name">
                <span class="fw-bold me-2">First name:</span>
                {{ instance.first_name }}
              </p>

              <p class="mb-2" v-if="instance.last_name">
                <span class="fw-bold me-2">Last name:</span>
                {{ instance.last_name }}
              </p>

              <p class="mb-2" v-if="instance.email_address">
                <span class="fw-bold me-2">Email address:</span>
                {{ instance.email_address }}
              </p>

              <p class="mb-2" v-if="instance.cofounder_email">
                <span class="fw-bold me-2">Cofounder (n) Email:</span>
                {{ instance.cofounder_email }}
              </p>

              <div class="mb-2" v-if="instance.founders_bio">
                <p class="fw-bold mb-0">Bio:</p>
                <p class="pre">{{ instance.founders_bio }}</p>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Employee Headcount and Geography
              </h4>

              <p class="mb-2" v-if="instance.employee_number">
                <span class="fw-bold me-2">Number of employees:</span>
                {{ instance.employee_number }}
              </p>

              <p class="mb-2" v-if="instance.team_location">
                <span class="fw-bold me-2">Team location:</span>
                <template v-if="instance.team_location && instance.team_location.length">
                  <span v-for="item in instance.team_location" :key="item" class="d-block pre">
                    <template v-if="item">{{ item.name }}</template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2" v-if="instance.total_employee_growth_rate">
                <span class="fw-bold me-2">Total Employee Growth Rate (%):</span>
                {{ instance.total_employee_growth_rate }}
              </p>

            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Funding Round Details
              </h4>

              <p class="mb-2" v-if="instance.target_raise">
                <span class="fw-bold me-2">Target Raise:</span>
                {{ $filters.money(instance.target_raise) }}
              </p>

              <p class="mb-2" v-if="instance.minimum_ticket_size">
                <span class="fw-bold me-2">Minimum Ticket Size:</span>
                {{ $filters.money(instance.minimum_ticket_size) }}
              </p>

              <p class="mb-2" v-if="instance.ticket_size_from || instance.ticket_size_to">
                <span class="fw-bold me-2">What is average ticket size you are looking for on this round?</span>
                <br>
                <span v-if="instance.ticket_size_from">
                  from {{ $filters.money(instance.ticket_size_from) }}
                </span>
                <span v-if="instance.ticket_size_to">
                  to {{ $filters.money(instance.ticket_size_to) }}
                </span>
              </p>

              <p class="mb-2" v-if="instance.current_valuation">
                <span class="fw-bold me-2">Current Valuation:</span>
                {{ $filters.money(instance.current_valuation) }}
              </p>

              <div class="mb-2"><span class="fw-bold me-2">Current Investment Stage:</span>
                <div class="industry-stage-container">
                  <template v-if="instance.current_investment_stage && instance.current_investment_stage.length">
                    <span v-for="item in instance.current_investment_stage" :key="item" class="d-block pre">
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-else>
                    —
                  </template>
                </div>
              </div>
                  
              <p class="mb-2" v-if="instance.funding_close_date">
                <span class="fw-bold me-2">Funding Close Date:</span>
                {{ instance.funding_close_date }}
              </p>

              <p class="mb-2" v-if="instance.preferred_investor_type">
                <span class="fw-bold me-2">Preferred Investor Type:</span>
                <template v-if="instance.preferred_investor_type && instance.preferred_investor_type.length">
                  <span v-for="item in instance.preferred_investor_type" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2" v-if="instance.preferred_investor_region && instance.preferred_investor_region.length">
                <span class="fw-bold me-2">Preferred Investor regions:</span>
                <template v-if="instance.preferred_investor_region && instance.preferred_investor_region.length">
                  <span v-for="item in instance.preferred_investor_region" :key="item" class="d-block pre">
                    <template v-if="item">{{ item.name }}</template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2" v-if="instance.smart_money_tags">
                <span class="fw-bold me-2">Smart Money Tags:</span>
                {{ instance.smart_money_tags }}
              </p>
                  
              <p class="mb-2" v-if="instance.exit_strategy">
                <span class="fw-bold me-2">Exit Strategy:</span>
                {{ instance.exit_strategy }}
              </p>

              <p class="mb-2" v-if="instance.preferred_investor_location && instance.preferred_investor_location.length">
                <span class="fw-bold me-2">Preferred Investor location:</span>
                <template v-if="instance.preferred_investor_location && instance.preferred_investor_location.length">
                  <span v-for="item in instance.preferred_investor_location" :key="item" class="d-block pre">
                    <template v-if="item">
                      <country-flag :country='item.code' size='small' />
                      {{ item.name }}
                    </template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <div class="mb-2" v-if="instance.investment_opportunity">
                <p class="fw-bold mb-0">Investment Opportunity:</p>
                <p class="pre">{{ instance.investment_opportunity }}</p>
              </div>

              <p class="mb-2" v-if="instance.pre_money_valuation">
                <span class="fw-bold me-2">Pre-Money Valuation:</span>
                {{ $filters.money(instance.pre_money_valuation) }}
              </p>

              <p class="mb-2" v-if="instance.lead_investor_name">
                <span class="fw-bold me-2">Lead Investor Name and Reputation:</span>
                {{ instance.lead_investor_name }}
              </p>

              <div class="mb-2" v-if="instance.use_of_proceed">
                <p class="fw-bold mb-0">Use of Proceeds:</p>
                <p class="pre">{{ instance.use_of_proceed }}</p>
              </div>

              <div class="mb-2" v-if="instance.current_cap_table">
                <p class="fw-bold mb-0">Current Cap Table (CSV):</p>
                <a :href="instance.current_cap_table" target="_blank">{{ instance.current_cap_table }}</a>
              </div>

            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Financial Information
              </h4>

              <p class="mb-2" v-if="instance.profitable">
                <span class="fw-bold me-2">Currently Profitable:</span>
                <span v-if="instance.profitable === 1">Yes</span>
                <span v-else-if="instance.profitable === 0">No</span>
              </p>

              <div class="mb-2" v-if="instance.sales_valuation_multiple">
                <span class="fw-bold me-2">Sales Valuation Multiple:</span>
                {{ $filters.money(instance.sales_valuation_multiple) }}
              </div>

              <div class="mb-2" v-if="instance.runway_duration">
                <span class="fw-bold me-2">Runway Duration:</span>
                {{ instance.runway_duration }}
              </div>

              <div class="mb-2" v-if="instance.churn_rate">
                <span class="fw-bold me-2">Churn Rate:</span>
                {{ $filters.money(instance.churn_rate) }}
              </div>

              <div class="mb-2" v-if="instance.gross_profit">
                <span class="fw-bold me-2">Gross Profit (US$):</span>
                {{ $filters.money(instance.gross_profit) }}
              </div>

              <div class="mb-2" v-if="instance.gross_margin">
                <span class="fw-bold me-2">Gross Margin (%):</span>
                {{ $filters.money(instance.gross_margin) }}
              </div>

              <div class="mb-2" v-if="instance.ebitda">
                <span class="fw-bold me-2">EBITDA (US$):</span>
                {{ $filters.money(instance.ebitda) }}
              </div>

              <div class="mb-2" v-if="instance.revenue_generating_streams">
                <p class="fw-bold mb-0">Revenue Generating Streams:</p>
                <p class="pre">{{ instance.revenue_generating_streams }}</p>
              </div>

            </div>
          </div>


        </div>

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Previous Funding
              </h4>

              <div class="mb-2" v-if="instance.raised_so_far">
                <span class="fw-bold me-2">Raised so far (US$):</span>
                {{ $filters.money(instance.raised_so_far) }}
              </div>

              <div class="mb-2" v-if="instance.current_investors_location && instance.current_investors_location.length">
                <span class="fw-bold me-2">Current Investor Locations:</span>
                <template v-if="instance.current_investors_location && instance.current_investors_location.length">
                  <span v-for="item in instance.current_investors_location" :key="item" class="d-block pre">
                    <template v-if="item">
                      <country-flag :country='item.code' size='small' />
                      {{ item.name }}
                    </template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </div>

              <div class="form-group mb-2" v-if="instance.current_investors && instance.current_investors.length">
                <span class="fw-bold me-2">Current Investors:</span>
                <template v-if="instance.current_investors && instance.current_investors.length">
                  <span v-for="item in instance.current_investors" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>

                <p v-if="errors.current_investors && errors.current_investors.length">
                  <span v-for="error in errors.current_investors" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <p class="mb-2" v-if="instance.current_lead_investor">
                <span class="fw-bold me-2">Do you have Current Lead Investor on your current round?</span>
                <br>
                <span v-if="instance.profitable === 1">Yes</span>
                <span v-else-if="instance.profitable === 0">No</span>
              </p>

              <p class="mb-2" v-if="instance.current_investor_type && instance.current_investor_type.length">
                <span class="fw-bold me-2">Current Investor Type:</span>
                <template v-if="instance.current_investor_type && instance.current_investor_type.length">
                  <span v-for="item in instance.current_investor_type" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <div class="mb-2" v-if="instance.last_funding_valuation">
                <span class="fw-bold me-2">Last Funding Valuation:</span>
                {{ $filters.money(instance.last_funding_valuation) }}
              </div>

              <div class="mb-2" v-if="instance.total_funding_to_date">
                <span class="fw-bold me-2">Total Funding to date:</span>
                {{ $filters.money(instance.total_funding_to_date) }}
              </div>

              <div class="mb-2" v-if="instance.total_funding_to_date">
                <span class="fw-bold me-2">Total Funding to date:</span>
                {{ $filters.money(instance.total_funding_to_date) }}
              </div>

              <!-- <div class="mb-2" v-if="instance.notable_institutional_investors">
                <p class="fw-bold mb-0">Notable institutional investors:</p>
                <p class="pre">{{ instance.notable_institutional_investors }}</p>
              </div> -->
              <div class="mb-2">
                <span class="fw-bold me-2">Notable institutional investors:</span>
                <div class="industry-sectors-container">
                  <template v-if="instance.notable_institutional_investors && instance.notable_institutional_investors.length">
                  <span v-for="item in instance.notable_institutional_investors" :key="item" class="d-block pre">
                    {{ item }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>
                </div>
              </div>

            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Traction and Growth Metrics
              </h4>

              <p class="mb-2" v-if="instance.revenue_generating">
                <span class="fw-bold me-2">Revenue Generating:</span>
                <span v-if="instance.revenue_generating === 1">Yes</span>
                <span v-else-if="instance.revenue_generating === 0">No</span>
              </p>

              <div class="mb-2" v-if="instance.current_MRR">
                <span class="fw-bold me-2">Current revenue (MRR):</span>
                {{ $filters.money(instance.current_MRR) }}
              </div>

              <div class="mb-2" v-if="instance.awards">
                <p class="fw-bold mb-0">Awards in your industry:</p>
                <p class="pre">{{ instance.awards }}</p>
              </div>

              <div class="mb-2" v-if="instance.active_users">
                <span class="fw-bold me-2">No. of Active users:</span>
                {{ $filters.money(instance.active_users) }}
              </div>

              <div class="mb-2" v-if="instance.annual_growth_rate">
                <span class="fw-bold me-2">Annual Growth Rate:</span>
                {{ $filters.money(instance.annual_growth_rate) }}
              </div>

              <div class="mb-2" v-if="instance.monthly_burn_rate">
                <span class="fw-bold me-2">Monthly Burn Rate:</span>
                {{ $filters.money(instance.monthly_burn_rate) }}
              </div>

              <div class="mb-2" v-if="instance.revenue_growth">
                <span class="fw-bold me-2">Revenue Growth (YoY or CAGR):</span>
                {{ $filters.money(instance.revenue_growth) }}
              </div>

              <div class="mb-2" v-if="instance.customer_acquisition_cost">
                <span class="fw-bold me-2">Customer Acquisition Cost (CAC in US$):</span>
                {{ $filters.money(instance.customer_acquisition_cost) }}
              </div>

              <div class="mb-2" v-if="instance.customer_lifetime_value">
                <span class="fw-bold me-2">Customer Lifetime Value (CLTV in US$):</span>
                {{ $filters.money(instance.customer_lifetime_value) }}
              </div>

              <div class="mb-2" v-if="instance.current_ARR">
                <span class="fw-bold me-2">Annual Recurring Revenue (ARR):</span>
                {{ $filters.money(instance.current_ARR) }}
              </div>

              <div class="mb-2" v-if="instance.projected_revenue">
                <span class="fw-bold me-2">Projected Revenue:</span>
                {{ $filters.money(instance.projected_revenue) }}
              </div>

              <div class="mb-2" v-if="instance.LTV_CAC">
                <span class="fw-bold me-2">Lifetime Value to Customer Acquisition Cost Ratio (LTV/CAC):</span>
                {{ $filters.money(instance.LTV_CAC) }}
              </div>

              <div class="mb-2" v-if="instance.retention_rate">
                <span class="fw-bold me-2">Retention rate:</span>
                {{ $filters.money(instance.retention_rate) }}
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Product and Technology
              </h4>

              <p class="mb-2" v-if="instance.current_lead_investor">
                <span class="fw-bold me-2">Technology Developed In-house:</span>
                <br>
                <span v-if="instance.in_house_technology === 1">Yes</span>
                <span v-else-if="instance.in_house_technology === 0">No</span>
              </p>

              <div class="mb-2" v-if="instance.key_selling_points">
                <p class="fw-bold mb-0">Key Selling Points:</p>
                <p class="pre">{{ instance.key_selling_points }}</p>
              </div>

              <div class="mb-2" v-if="instance.competitive_advantages">
                <p class="fw-bold mb-0">Competitive Advantages:</p>
                <p class="pre">{{ instance.competitive_advantages }}</p>
              </div>

              <div class="mb-2" v-if="instance.demo">
                <p class="fw-bold mb-0">Link to Product Demo:</p>
                <a :href="instance.demo" target="_blank">{{ instance.demo }}</a>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Fundraising Strategy
              </h4>

              <div class="mb-2" v-if="instance.pitch_deck_url">
                <p class="fw-bold mb-0">Pitch Deck (link to Docsend / Google Drive / etc):</p>
                <a :href="instance.pitch_deck_url" target="_blank">{{ instance.pitch_deck_url }}</a>
              </div>
              
              <div class="mb-2" v-if="instance.pitch_deck_file">
                <p class="fw-bold mb-0">Pitch Deck (upload PDF):</p>
                <a :href="instance.pitch_deck_file" target="_blank">{{ instance.pitch_deck_file }}</a>
              </div>

              <div class="mb-2" v-if="instance.executive_summary_url">
                <p class="fw-bold mb-0">Executive Summary (link to Docsend / Google Drive / etc):</p>
                <a :href="instance.executive_summary_url" target="_blank">{{ instance.executive_summary_url }}</a>
              </div>

              <div class="mb-2" v-if="instance.executive_summary_file">
                <p class="fw-bold mb-0">Executive Summary (upload PDF):</p>
                <a :href="instance.executive_summary_file" target="_blank">{{ instance.executive_summary_file }}</a>
              </div>
            
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Coinvestors
              </h4>

              <template v-if="startup.is_fetching_coinvestors">
                <p>Processing... Please come back later.</p>
                <loader></loader>
              </template>

              <template v-else>
                <template v-if="startup.coinvestor_set && startup.coinvestor_set.length">
                  <ul>
                    <li v-for="coinvestor in startup.coinvestor_set" :key="coinvestor.id">
                      {{ coinvestor.name }}: {{ coinvestor.crunchbase_url }}
                    </li>
                  </ul>
                </template>
                <template v-else>
                  <p>No coinvestors found.</p>
                </template>
              </template>
            
            </div>
          </div>

        </div>

      </div>

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">
              <a href="#" class="btn btn-outline-info me-2" @click.prevent="generateCareerOpenerOneLiner" v-if="user.can_generate_openers">
                <i class="uil uil-comment-alt-info me-1"></i>
                <span v-if="siteCompany.is_whitelabel">Identify Career Matches</span>
                <span v-else>Create Career Opener One Liner</span>
              </a>
              <a href="#" class="btn btn-outline-info me-2" @click.prevent="goGenerateMatchScore" v-if="user.can_generate_match_score">
                <i class="uil uil-abacus me-1"></i>
                <span>Calculate Match Score</span>
              </a>
              <a href="#" class="btn btn-outline-primary me-2" @click.prevent="goEmailIntro" v-if="user && user.can_generate_openers">
                <i class="uil uil-comment-alt-info me-1"></i>
                <span>Generate Email Intro</span>
              </a>
              <a href="#" class="btn btn-primary" @click.prevent="goMatch" v-if="user.can_search">
                <i class="uil uil-adjust-circle me-1"></i>
                <span>Match Investors</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'startup',

  mixins: [SearchMixin],

  data() {
    return {
      instance: null
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      current: state => state.startups.current,
      siteCompany: state => state.auth.siteCompany,
    })
  },
  
  mounted() {
    window.scrollTo(0, 0)
    const startupId = this.$route.params.id || (this.current && this.current.id)
    if (startupId) {
      this.$store.dispatch('LOAD_STARTUP', startupId)
        .then((response) => {
          const res = response.data;
          if (res.notable_institutional_investors) {
            res.notable_institutional_investors = res.notable_institutional_investors
              .split(',')
              .map(item => item.trim());
          } else {
            res.notable_institutional_investors = '';
        }
          this.instance = res;
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('SET_CURRENT_STARTUP', null)
          this.$router.push({ name: 'startups_selector' })
        })
    } else {
      this.$router.push({ name: 'startups_selector' })
    }
  },

  methods: {
    goEdit() {
      this.$router.push(`/startups/${this.instance.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.instance.id}/delete`)
    },
    goMatch() {
      this.$store.commit('SET_MATCH_FIELDS', null);
      this.$store.commit('SET_MATCH_GROUP', null);
      this.$router.push('/investors/contacts?match=true')
    },
    goRingfence() {
      this.$router.push(`/startups/${this.instance.id}/ringfence`)
    },
    goSwitch() {
      this.$router.push({ name: 'startups_selector' })
    },
    goEmailIntro() {
      this.$router.push(`/startups/${this.instance.id}/emailintro`)
    },
    goGenerateMatchScore() {
      if (confirm('Are you sure you want to trigger generation of match scores for this startup?')) {
        this.$store.dispatch('GENERATE_MATCH_SCORE', this.instance.id)
      }
    },
    generateCareerOpenerOneLiner() {
      Swal.fire({
        title: "Generate Career Opener One Liner?",
        html: `Are you sure you want to trigger generation of career opener one liners for ${this.instance.name}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('GENERATE_CAREER_OPENER_ONE_LINER_FOR_STARTUP', this.instance.id)
            .then((response) => {
              Swal.fire({
                title: "Job Triggered!",
                text: "A background job has been triggered to generate the career opener one liner. Please check back later.",
                icon: "success"
              });
            })
            .catch((error) => {
              console.error(error);
              Swal.fire({
                title: "Error",
                text: error.response?.data?.error || "Failed to generate career opener one liner.",
                icon: "error"
              });
            });
        }
      });
    }
  }
}
</script>

<style scoped>
.industry-sectors-container {
  max-height: 20vh;
  overflow-y: auto;
  border: 1px;
  border-block-style: solid;
}
.industry-stage-container {
  max-height: 20vh;
  overflow-y: auto;
  border: 1px;
  border-block-style: solid;
}
</style>