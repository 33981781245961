<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">

      <div v-if="user" class="row mt-3">

        <div class="col pe-0">
          <div class="card">
            <div class="card-body">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goReplyIoKey">
                  <i class="uil uil-padlock me-1"></i>
                  <span>Reply.io API Key</span>
                </a>
              </div>

              <h3>Account</h3>

              <div class="form-group mb-3">
                <label for="id_first_name">First name:</label>
                <input id="id_first_name" type="text" name="first_name" class="form-control" required v-model="user.first_name">

                <p v-if="errors.first_name && errors.first_name.length">
                  <span v-for="error in errors.first_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_last_name">Last name:</label>
                <input id="id_last_name" type="text" name="last_name" class="form-control" required v-model="user.last_name">

                <p v-if="errors.last_name && errors.last_name.length">
                  <span v-for="error in errors.last_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_email">Email:</label>
                <input id="id_email" type="text" name="email" class="form-control" required v-model="user.email">

                <p v-if="errors.email && errors.email.length">
                  <span v-for="error in errors.email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_phone_number">Phone Number:</label>
                <input id="id_phone_number" type="text" name="phone_number" class="form-control" required v-model="user.phone_number">

                <p v-if="errors.phone_number && errors.phone_number.length">
                  <span v-for="error in errors.phone_number" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_linkedIn_URL">LinkedIn:</label>
                <input id="id_linkedIn_URL" type="text" name="linkedIn_URL" class="form-control" required v-model="user.linkedIn_URL">

                <p v-if="errors.linkedIn_URL && errors.linkedIn_URL.length">
                  <span v-for="error in errors.linkedIn_URL" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_linkedIn_about">About:</label>
                <input id="id_linkedIn_about" type="text" name="linkedIn_about" class="form-control" required v-model="user.linkedIn_about">

                <p v-if="errors.linkedIn_about && errors.linkedIn_about.length">
                  <span v-for="error in errors.linkedIn_about" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>

      </div>

      <div v-else class="text-center">
        <loader></loader>
      </div>

      <div class="action-footer">
        <div class="container-fluid">
          <div class="card mb-0">
            <div class="card-body">
              <div class="float-end">
                <div v-if="hasError" class="text-danger me-2">Error. Please check the form.</div>
                <button class="btn btn-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="space space-md" />
      <app-footer></app-footer>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'account',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      user: null,
      errors: {},
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      userState: state => state.auth.user
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)

    this.user = this.userState
  },

  methods: {
    submit () {
      this.isSubmitting = true
      this.errors = {}

      this.$store.dispatch('UPDATE_USER', this.user)
        .then(() => {
          this.isSubmitting = false
        })
        .catch((error) => {
          this.isSubmitting = false
          this.errors = error.response.data
        })
    },
    goReplyIoKey() {
      this.$router.push({ name: 'reply-io-key' })
    }
  }
}
</script>
