<template>
  <div id="app">
    <!-- <app-header></app-header> -->
    <router-view></router-view>
    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import { mapState } from 'vuex'

import Header from './components/Layout/Header.vue'
import Footer from './components/Layout/Footer.vue'

export default {
  name: 'app',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      siteCompany: state => state.auth.siteCompany,
    })
  },

  watch: {
    '$route' () {
      this.onRouteChange()
    },

    'user' () {
      this.onUserChange()
    },

    'siteCompany' () {
      document.title = this.siteCompany ? this.siteCompany.title : '...'
    }
  },

  methods: {
    onRouteChange () {},
    onUserChange () {}
  }
}
</script>

<style lang="scss"></style>
