<template>
  <div>
    <div v-if="!isLoading">
      <div class="row">
        <div class="col pe-0">

          <div class="card">
            <div class="card-body">
              
              <h3>Usage</h3>

              <div class="d-flex flex-row mb-3">
                <div class="form-group">
                  <label for="id_start_date">Filter by Start Date:</label>
                  <input
                    id="id_start_date"
                    type="date"
                    name="start_date"
                    class="form-control"
                    v-model="form.start_date"
                  >
                </div>

                <div class="form-group ms-3">
                  <label for="id_start_date">End Date:</label>
                  <input
                    id="id_start_date"
                    type="date"
                    name="start_date"
                    class="form-control"
                    v-model="form.end_date"
                  >
                </div>

                <div class="form-group ms-3">
                  <label>&nbsp;</label>
                  <button class="form-control btn btn-primary" @click="load">Update</button>
                </div>
                
              </div>

              <template v-if="usage">
                
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      href="#"
                      class="nav-link"
                      :class="{ active: activeTab == 'gpt_requests' }"
                      @click.prevent="setActiveTab('gpt_requests')"
                    >
                      AI Requests
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#"
                      class="nav-link"
                      :class="{ active: activeTab == 'scraper_requests' }"
                      @click.prevent="setActiveTab('scraper_requests')"
                    >
                      <span v-if="!siteCompany.is_whitelabel">ScraperAPI</span>
                      <span v-else>Scaped Data capture</span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      href="#"
                      class="nav-link"
                      :class="{ active: activeTab == 'rapidapi_requests' }"
                      @click.prevent="setActiveTab('rapidapi_requests')"
                    >
                    <span v-if="!siteCompany.is_whitelabel">RapidAPI LinkedIn</span>
                    <span v-else>Rapid Data Capture</span>
                    </a>
                  </li>
                </ul>

                <template v-if="activeTab == 'gpt_requests'">
                  <h4 class="p-2">Total tokens: {{ usage.gpt_requests_sum }}</h4>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Request type</th>
                        <th>Number of tokens</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in usage.gpt_requests">
                        <td>{{ item.created_date }}</td>
                        <td>{{ item.request_type }}</td>
                        <td>{{ item.number_of_tokens }}</td>
                      </tr>
                    </tbody>
                  </table>
                </template>

                <template v-else-if="activeTab == 'scraper_requests'">
                  <h4 class="p-2">Total credits: {{ usage.scraper_requests_sum }}</h4>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Request type</th>
                        <th>Number of credits</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in usage.scraper_requests">
                        <td>{{ item.created_date }}</td>
                        <td>{{ item.request_type }}</td>
                        <td>{{ item.number_of_credits }}</td>
                      </tr>
                    </tbody>
                  </table>
                </template>

                <template v-else-if="activeTab == 'rapidapi_requests'">
                  <h4 class="p-2">Total requests: {{ usage.rapidapi_requests_sum }}</h4>
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Request type</th>
                        <th>Number of requests</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in usage.rapidapi_requests">
                        <td>{{ item.created_date }}</td>
                        <td>{{ item.request_type }}</td>
                        <td>{{ item.number_of_request }}</td>
                      </tr>
                    </tbody>
                  </table>
                </template>

              </template>

              <div class="py-4">
                &nbsp;
              </div>

            </div>
          </div>
        </div> <!-- end col -->
      </div>

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            &nbsp;
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SearchMixin from '@/mixins/search'
import { mapState } from 'vuex'

export default {
  name: 'startup',

  data() {
    return {
      startup: null,
      usage: null,
      error: null,
      isLoading: true,
      activeTab: 'gpt_requests',
      form: {
        start_date: null,
        end_date: null
      }
    }
  },

  computed: {
    ...mapState({
      current: state => state.startups.current,
      siteCompany: state => state.auth.siteCompany,
    })
  },
  
  mounted() {
    window.scrollTo(0, 0)
    const startupId = this.$route.params.id || (this.current && this.current.id)
    if (startupId) {
      this.$store.dispatch('LOAD_STARTUP', startupId)
        .then((response) => {
          this.startup = response.data
          this.load()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('SET_CURRENT_STARTUP', null)
          this.$router.push({ name: 'startups_selector' })
        })
    } else {
      this.$router.push({ name: 'startups_selector' })
    }
  },

  methods: {
    load() {
      this.isLoading = true
      this.$store.dispatch('LOAD_USAGE', this.form)
        .then((response) => {
          this.usage = response.data
          this.form.start_date = response.data.start_date
          this.form.end_date = response.data.end_date
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    setActiveTab(tab) {
      this.activeTab = tab
    }
  }
}
</script>
