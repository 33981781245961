<template>
  <div>
    <div v-if="instance">
      <div class="row">
        <div class="col-12">

          <div class="card">
            <div class="card-body">
              <h3 v-if="startupId">{{ instance.name }}</h3>
              <h3 v-else>Set up Your Company Profile</h3>

              <div class="form-group mb-3">
                <label for="id_name">Name:</label>
                <input id="id_name" type="text" name="name" class="form-control" required v-model="instance.name">
                
                <p v-if="errors.name && errors.name.length">
                  <span v-for="error in errors.name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <span class="fw-bold me-2">Company Location City:</span>
                <v-select
                  name="registered_country"
                  v-model="instance.company_location_city"
                  @search="searchCities"
                  :options="optionsCities"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.company_location_city && errors.company_location_city.length">
                  <span v-for="error in errors.company_location_city" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_name">Website:</label>
                <input id="id_website" type="text" name="website" class="form-control" required v-model="instance.website">

                <p v-if="errors.website && errors.website.length">
                  <span v-for="error in errors.website" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_registered_country">Registered Country:</label>

                <v-select
                  name="registered_country"
                  v-model="instance.registered_country"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>
                
                <p v-if="errors.registered_country && errors.registered_country.length">
                  <span v-for="error in errors.registered_country" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-3">
                <label for="id_astel_shared_email">Astel Shared Email:</label>
                <input id="id_astel_shared_email" type="email" name="astel_shared_email" class="form-control" v-model="instance.astel_shared_email">
                
                <p v-if="errors.astel_shared_email && errors.astel_shared_email.length">
                  <span v-for="error in errors.astel_shared_email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_description">Description:</label>
                <textarea id="id_description" type="text" name="description" class="form-control" required rows="10"
                  v-model="instance.description">
                </textarea>

                <p v-if="errors.description && errors.description.length">
                  <span v-for="error in errors.description" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_industry_sector_tags">Industry and sectors tags:</label>

                <v-select
                  name="industry_sector_tags"
                  v-model="instance.industry_sector_tags"
                  :options="tagOptions"
                  @search="searchTags"
                  multiple
                  taggable
                  :close-on-select="false"
                  :clear-search-on-select="false"
                >
                </v-select>

                <p v-if="errors.industry_sector_tags && errors.industry_sector_tags.length">
                  <span v-for="error in errors.industry_sector_tags" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_expansion_countries">Expansion Countries:</label>

                <v-select name="expansion_countries" v-model="instance.expansion_countries" :options="optionsCountries"
                  :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.expansion_countries && errors.expansion_countries.length">
                  <span v-for="error in errors.expansion_countries" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Team Information
              </h4>

              <div class="form-group mb-3">
                <label for="id_title">Title:</label>
                <input id="id_title" type="text" name="title" class="form-control" v-model="instance.title">
                
                <p v-if="errors.title && errors.title.length">
                  <span v-for="error in errors.title" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-3">
                <label for="id_first_name">First Name:</label>
                <input id="id_first_name" type="text" name="first_name" class="form-control" v-model="instance.first_name">
                
                <p v-if="errors.first_name && errors.first_name.length">
                  <span v-for="error in errors.first_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-3">
                <label for="id_last_name">Last Name:</label>
                <input id="id_last_name" type="text" name="last_name" class="form-control" v-model="instance.last_name">
                
                <p v-if="errors.last_name && errors.last_name.length">
                  <span v-for="error in errors.last_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_email_address">Email address:</label>
                <input id="id_email_address" type="email" name="email_address" class="form-control" v-model="instance.email_address">
                
                <p v-if="errors.email_address && errors.email_address.length">
                  <span v-for="error in errors.email_address" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-3">
                <label for="id_cofounder_email">Cofounder (n) Email:</label>
                <input id="id_cofounder_email" type="email" name="cofounder_email" class="form-control" v-model="instance.cofounder_email">
                
                <p v-if="errors.cofounder_email && errors.cofounder_email.length">
                  <span v-for="error in errors.cofounder_email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_founders_bio">Bio:</label>
                <textarea id="id_founders_bio" name="founders_bio" class="form-control"
                  required rows="5" v-model="instance.founders_bio">
                </textarea>
                
                <p v-if="errors.founders_bio && errors.founders_bio.length">
                  <span v-for="error in errors.founders_bio" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>


          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Employee Headcount and Geography
              </h4>

              <div class="form-group mb-3">
                <label for="id_employee_number">Number of employees:</label>
                <v-number-input
                  v-model="instance.employee_number"
                  v-bind="numberInputConfig"
                />
                
                <p v-if="errors.employee_number && errors.employee_number.length">
                  <span v-for="error in errors.employee_number" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_team_location">Team location:</label>

                <v-select
                  name="team_location"
                  v-model="instance.team_location"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.team_location && errors.team_location.length">
                  <span v-for="error in errors.team_location" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_total_employee_growth_rate">Total Employee Growth Rate (%):</label>
                <v-number-input
                  v-model="instance.total_employee_growth_rate"
                  v-bind="numberInputConfig"
                />
                
                <p v-if="errors.total_employee_growth_rate && errors.total_employee_growth_rate.length">
                  <span v-for="error in errors.total_employee_growth_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Funding Round Details
              </h4>

              <div class="form-group mb-3">
                <label for="id_target_raise">Target Raise:</label>
                <v-number-input
                  v-model="instance.target_raise"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.target_raise && errors.target_raise.length">
                  <span v-for="error in errors.target_raise" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_minimum_ticket_size">Minimum Ticket Size:</label>
                <v-number-input
                  v-model="instance.minimum_ticket_size"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.minimum_ticket_size && errors.minimum_ticket_size.length">
                  <span v-for="error in errors.minimum_ticket_size" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>


              <div class="form-group mb-3">
                <p class="text-strong">What is average ticket size you are looking for on this round?</p>
                <div class="d-flex">
                  <div>
                    <label for="id_ticket_size_from">From:</label>
                    <v-number-input
                      v-model="instance.ticket_size_from"
                      v-bind="numberInputConfig"
                    />
                    
                    <p v-if="errors.ticket_size_from && errors.ticket_size_from.length">
                      <span v-for="error in errors.ticket_size_from" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                  <div>
                    <label for="id_ticket_size_to">To:</label>
                    <v-number-input
                      v-model="instance.ticket_size_to"
                      v-bind="numberInputConfig"
                    />

                    <p v-if="errors.ticket_size_to && errors.ticket_size_to.length">
                      <span v-for="error in errors.ticket_size_to" :key="error" class="text-danger">{{ error }}</span>
                    </p>
                  </div>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_valuation">Current Valuation:</label>
                <v-number-input
                  v-model="instance.current_valuation"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.current_valuation && errors.current_valuation.length">
                  <span v-for="error in errors.current_valuation" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_investment_stage">Current Investment Stage:</label>

                <v-select name="current_investment_stage" v-model="instance.current_investment_stage"
                  :options="optionsInvestmentStages" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.current_investment_stage && errors.current_investment_stage.length">
                  <span v-for="error in errors.current_investment_stage" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_funding_close_date">Funding Close Date:</label>
                <input
                  id="id_funding_close_date"
                  type="date"
                  name="funding_close_date"
                  class="form-control"
                  v-model="instance.funding_close_date"
                >

                <p v-if="errors.funding_close_date && errors.funding_close_date.length">
                  <span v-for="error in errors.funding_close_date" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-2">
                <label for="id_preferred_investor_type">Preferred Investor Type:</label>

                <v-select name="preferred_investor_type" v-model="instance.preferred_investor_type"
                  :options="optionsInvestorTypes" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.preferred_investor_type && errors.preferred_investor_type.length">
                  <span v-for="error in errors.preferred_investor_type" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_preferred_investor_region">Preferred Investor Region:</label>

                <v-select name="preferred_investor_region" v-model="instance.preferred_investor_region"
                  :options="optionsRegions" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.preferred_investor_region && errors.preferred_investor_region.length">
                  <span v-for="error in errors.preferred_investor_region" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_smart_money_tags">Are you looking for smart money:</label>

                <v-select name="smart_money_tags" v-model="instance.smart_money_tags"
                  :options="optionsSmartMoneyTags" :reduce="x => x.id">
                </v-select>

                <p v-if="errors.smart_money_tags && errors.smart_money_tags.length">
                  <span v-for="error in errors.smart_money_tags" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_exit_strategy">Exit Strategy:</label>

                <v-select name="exit_strategy" v-model="instance.exit_strategy"
                  :options="optionsExitStrategy" :reduce="x => x.id">
                </v-select>

                <p v-if="errors.exit_strategy && errors.exit_strategy.length">
                  <span v-for="error in errors.exit_strategy" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_preferred_investor_location">Preferred Investor location:</label>

                <v-select name="preferred_investor_location" v-model="instance.preferred_investor_location"
                  :options="optionsCountries" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.preferred_investor_location && errors.preferred_investor_location.length">
                  <span v-for="error in errors.preferred_investor_location" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_description">Investment Opportunity:</label>
                <textarea id="id_investment_opportunity" type="text" name="investment_opportunity" class="form-control"
                  required rows="10" v-model="instance.investment_opportunity">
                </textarea>

                <p v-if="errors.investment_opportunity && errors.investment_opportunity.length">
                  <span v-for="error in errors.investment_opportunity" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_pre_money_valuation">Pre-Money Valuation:</label>
                <v-number-input
                  v-model="instance.pre_money_valuation"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.pre_money_valuation && errors.pre_money_valuation.length">
                  <span v-for="error in errors.pre_money_valuation" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_lead_investor_name">Lead Investor Name and Reputation (if available):</label>
                <input
                  id="id_lead_investor_name"
                  type="text"
                  name="lead_investor_name"
                  class="form-control"
                  v-model="instance.lead_investor_name"
                >
                
                <p v-if="errors.lead_investor_name && errors.lead_investor_name.length">
                  <span v-for="error in errors.lead_investor_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_use_of_proceed">Use of Proceeds:</label>
                <textarea
                  id="id_use_of_proceed"
                  type="text"
                  name="use_of_proceed"
                  class="form-control"
                  rows="4"
                  v-model="instance.use_of_proceed"
                >
                </textarea>

                <p v-if="errors.use_of_proceed && errors.use_of_proceed.length">
                  <span v-for="error in errors.use_of_proceed" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_cap_table">Current Cap Table (CSV):</label>
                <input
                  ref="current_cap_table"
                  type="file"
                  class="form-control"
                  accept=".csv"
                  @change="onCapTableFileChange"
                >

                <p v-if="errors.current_cap_table && errors.current_cap_table.length">
                  <span v-for="error in errors.current_cap_table" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Financial Information
              </h4>

              <div class="form-group mb-2">
                <label for="id_profitable">Currently Profitable:</label>

                <v-select
                  name="profitable"
                  v-model="instance.profitable"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.profitable && errors.profitable.length">
                  <span v-for="error in errors.profitable" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_sales_valuation_multiple">Sales Valuation Multiple:</label>
                <v-number-input
                  v-model="instance.sales_valuation_multiple"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.sales_valuation_multiple && errors.sales_valuation_multiple.length">
                  <span v-for="error in errors.sales_valuation_multiple" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_runway_duration">Runway Duration:</label>

                <v-select name="runway_duration" v-model="instance.runway_duration"
                  :options="optionsRunwayDuration" :reduce="x => x.id">
                </v-select>

                <p v-if="errors.runway_duration && errors.runway_duration.length">
                  <span v-for="error in errors.runway_duration" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_churn_rate">Churn Rate:</label>
                <v-number-input
                  v-model="instance.churn_rate"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.churn_rate && errors.churn_rate.length">
                  <span v-for="error in errors.churn_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_gross_profit">Gross Profit ($):</label>
                <v-number-input
                  v-model="instance.gross_profit"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.gross_profit && errors.gross_profit.length">
                  <span v-for="error in errors.gross_profit" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_gross_margin">Gross Margin (%):</label>
                <v-number-input
                  v-model="instance.gross_margin"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.gross_margin && errors.gross_margin.length">
                  <span v-for="error in errors.gross_margin" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_ebitda">EBITDA ($):</label>
                <v-number-input
                  v-model="instance.ebitda"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.ebitda && errors.ebitda.length">
                  <span v-for="error in errors.ebitda" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_revenue_generating_streams">Revenue Generating Streams:</label>
                <textarea 
                  id="id_revenue_generating_streams"
                  name="revenue_generating_streams"
                  class="form-control"
                  rows="5"
                  v-model="instance.revenue_generating_streams"
                >
                </textarea>
                
                <p v-if="errors.revenue_generating_streams && errors.revenue_generating_streams.length">
                  <span v-for="error in errors.revenue_generating_streams" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>

        <div class="col-md-6 col-sm-12">

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Previous Funding
              </h4>
              
              <div class="form-group mb-3">
                <label for="id_target_raise">Raised so far:</label>
                <v-number-input
                  v-model="instance.raised_so_far"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.raised_so_far && errors.raised_so_far.length">
                  <span v-for="error in errors.raised_so_far" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_investors_location">Current Investor Locations:</label>

                <v-select
                  name="current_investors_location"
                  v-model="instance.current_investors_location"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.current_investors_location && errors.current_investors_location.length">
                  <span v-for="error in errors.current_investors_location" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2"><span class="fw-bold me-2">Current Investors:</span>
                <template v-if="instance.current_investors && instance.current_investors.length">
                  <span v-for="item in instance.current_investors" :key="item" class="d-block pre">
                    {{ item.name }}
                  </span>
                </template>
                <template v-else>
                  —
                </template>

                <p v-if="errors.current_investors && errors.current_investors.length">
                  <span v-for="error in errors.current_investors" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_lead_investor">Do you have Current Lead Investor on your current round?</label>

                <v-select
                  name="current_lead_investor"
                  v-model="instance.current_lead_investor"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.current_lead_investor && errors.current_lead_investor.length">
                  <span v-for="error in errors.current_lead_investor" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_investor_type">Current Investor Type:</label>

                <v-select
                  name="current_investor_type"
                  v-model="instance.current_investor_type"
                  :options="optionsInvestorTypes"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.current_investor_type && errors.current_investor_type.length">
                  <span v-for="error in errors.current_investor_type" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_last_funding_valuation">Last Funding Valuation:</label>
                <v-number-input
                  v-model="instance.last_funding_valuation"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.last_funding_valuation && errors.last_funding_valuation.length">
                  <span v-for="error in errors.last_funding_valuation" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_total_funding_to_date">Total Funding to date:</label>
                <v-number-input
                  v-model="instance.total_funding_to_date"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.total_funding_to_date && errors.total_funding_to_date.length">
                  <span v-for="error in errors.total_funding_to_date" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>


              <div class="form-group mb-3">
                <label for="id_notable_institutional_investors">Notable institutional investors:</label>
                <textarea id="id_notable_institutional_investors" type="text" name="notable_institutional_investors" class="form-control"
                  required rows="5" v-model="instance.notable_institutional_investors">
                </textarea>
                
                <p v-if="errors.notable_institutional_investors && errors.notable_institutional_investors.length">
                  <span v-for="error in errors.notable_institutional_investors" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Traction and Growth Metrics
              </h4>

              <div class="form-group mb-2">
                <label for="id_revenue_generating">Revenue Generating:</label>

                <v-select
                  name="revenue_generating"
                  v-model="instance.revenue_generating"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.revenue_generating && errors.revenue_generating.length">
                  <span v-for="error in errors.revenue_generating" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_MRR">Current revenue (MRR):</label>
                <v-number-input
                  v-model="instance.current_MRR"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.current_MRR && errors.current_MRR.length">
                  <span v-for="error in errors.current_MRR" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_awards">Awards in your industry:</label>
                <input
                  id="id_awards"
                  type="text"
                  name="awards"
                  class="form-control"
                  v-model="instance.awards"
                >
                
                <p v-if="errors.awards && errors.awards.length">
                  <span v-for="error in errors.awards" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_active_users">No. of Active users:</label>
                <v-number-input
                  v-model="instance.active_users"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.active_users && errors.active_users.length">
                  <span v-for="error in errors.active_users" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_annual_growth_rate">Annual Growth Rate:</label>
                <v-number-input
                  v-model="instance.annual_growth_rate"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.annual_growth_rate && errors.annual_growth_rate.length">
                  <span v-for="error in errors.annual_growth_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_monthly_burn_rate">Monthly Burn Rate:</label>
                <v-number-input
                  v-model="instance.monthly_burn_rate"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.monthly_burn_rate && errors.monthly_burn_rate.length">
                  <span v-for="error in errors.monthly_burn_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_revenue_growth">Revenue Growth (YoY or CAGR):</label>
                <v-number-input
                  v-model="instance.revenue_growth"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.revenue_growth && errors.revenue_growth.length">
                  <span v-for="error in errors.revenue_growth" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_customer_acquisition_cost">Customer Acquisition Cost (CAC):</label>
                <v-number-input
                  v-model="instance.customer_acquisition_cost"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.customer_acquisition_cost && errors.customer_acquisition_cost.length">
                  <span v-for="error in errors.customer_acquisition_cost" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_customer_lifetime_value">Customer Lifetime Value (CLTV):</label>
                <v-number-input
                  v-model="instance.customer_lifetime_value"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.customer_lifetime_value && errors.customer_lifetime_value.length">
                  <span v-for="error in errors.customer_lifetime_value" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_current_ARR">Annual Recurring Revenue (ARR):</label>
                <v-number-input
                  v-model="instance.current_ARR"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.current_ARR && errors.current_ARR.length">
                  <span v-for="error in errors.current_ARR" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_projected_revenue">Projected Revenue:</label>
                <v-number-input
                  v-model="instance.projected_revenue"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.projected_revenue && errors.projected_revenue.length">
                  <span v-for="error in errors.projected_revenue" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_LTV_CAC">Lifetime Value to Customer Acquisition Cost Ratio (LTV/CAC):</label>
                <v-number-input
                  v-model="instance.LTV_CAC"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.LTV_CAC && errors.LTV_CAC.length">
                  <span v-for="error in errors.LTV_CAC" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_retention_rate">Retention rate:</label>
                <v-number-input
                  v-model="instance.retention_rate"
                  v-bind="numberInputConfig"
                />

                <p v-if="errors.retention_rate && errors.retention_rate.length">
                  <span v-for="error in errors.retention_rate" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Product and Technology
              </h4>

              <div class="form-group mb-2">
                <label for="id_in_house_technology">Technology Developed In-house:</label>

                <v-select
                  name="in_house_technology"
                  v-model="instance.in_house_technology"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.in_house_technology && errors.in_house_technology.length">
                  <span v-for="error in errors.in_house_technology" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_key_selling_points">Key Selling Points:</label>
                <textarea id="id_key_selling_points" name="key_selling_points" class="form-control"
                  required rows="5" v-model="instance.key_selling_points">
                </textarea>
                
                <p v-if="errors.key_selling_points && errors.key_selling_points.length">
                  <span v-for="error in errors.key_selling_points" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_key_features">Key Features and Functionality:</label>
                <textarea id="id_key_features" name="key_features" class="form-control"
                  required rows="5" v-model="instance.key_features">
                </textarea>
                
                <p v-if="errors.key_features && errors.key_features.length">
                  <span v-for="error in errors.key_features" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_competitive_advantages">Competitive Advantages:</label>
                <textarea id="id_competitive_advantages" name="competitive_advantages" class="form-control"
                  required rows="5" v-model="instance.competitive_advantages">
                </textarea>
                
                <p v-if="errors.competitive_advantages && errors.competitive_advantages.length">
                  <span v-for="error in errors.competitive_advantages" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_demo">Link to Product Demo:</label>
                <input id="id_demo" type="text" name="demo" class="form-control" required v-model="instance.demo">

                <p v-if="errors.demo && errors.demo.length">
                  <span v-for="error in errors.demo" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>



            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Fundraising Strategy
              </h4>

              <div class="form-group mb-1">
                <label for="id_pitch_deck_url">Pitch Deck (link to Docsend / Google Drive / etc):</label>
                <input id="id_pitch_deck_url" type="text" name="pitch_deck_url" class="form-control" required v-model="instance.pitch_deck_url">

                <p v-if="errors.pitch_deck_url && errors.pitch_deck_url.length">
                  <span v-for="error in errors.pitch_deck_url" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_pitch_deck_file">Pitch Deck (upload PDF):</label>
                <input
                  ref="pitch_deck_file"
                  type="file"
                  class="form-control"
                  accept=".csv"
                  @change="onPitchDeckFileChange"
                >

                <p v-if="errors.pitch_deck_file && errors.pitch_deck_file.length">
                  <span v-for="error in errors.pitch_deck_file" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <hr>

              <div class="form-group mb-3">
                <label for="id_executive_summary_url">Executive Summary (link to Docsend / Google Drive / etc):</label>
                <input id="id_executive_summary_url" type="text" name="executive_summary_url" class="form-control" required v-model="instance.executive_summary_url">

                <p v-if="errors.executive_summary_url && errors.executive_summary_url.length">
                  <span v-for="error in errors.executive_summary_url" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_executive_summary_file">Executive Summary (upload PDF):</label>
                <input
                  ref="executive_summary_file"
                  type="file"
                  class="form-control"
                  accept=".csv"
                  @change="onExecutiveSummaryFileChange"
                >

                <p v-if="errors.executive_summary_file && errors.executive_summary_file.length">
                  <span v-for="error in errors.executive_summary_file" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
          
        </div>

      </div>
      <!-- end row -->

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="d-flex align-items-center float-end">
              <div v-if="hasError" class="text-danger me-2">Error. Please check the form.</div>
              <button class="btn btn-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'startup-edit',

  data() {
    return {
      instance: null,
      errors: {},
      isSubmitting: false,
      yesNoOptions: [{id: 1, label: 'YES'}, {id: 0, label: 'NO'}],
      tagOptions: [],
      numberInputConfig: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false,
        allowBlank: true,
      }
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      stateForm: state => state.startups.form,
      optionsInvestorTypes: state => state.investors.investorTypes,
      optionsInvestmentStages: state => state.investors.investmentStages,
      optionsCities: state => state.investors.cities,
      optionsCountries: state => state.investors.countries,
      optionsRegions: state => state.investors.regions,
      optionsSmartMoneyTags: state => state.startups.optionsSmartMoneyTags,
      optionsExitStrategy: state => state.startups.optionsExitStrategy,
      optionsRunwayDuration: state => state.startups.optionsRunwayDuration,
    }),
    startupId() {
      return this.$route.params.id || (this.current && this.current.id) || this.user.startup
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
  },

  mounted() {
    window.scrollTo(0, 0)

    if (this.startupId) {
      Promise.all([
        this.$store.dispatch('LOAD_SEARCH_OPTIONS'),
        this.$store.dispatch('LOAD_STARTUP_FIELD_OPTIONS'),
        this.$store.dispatch('LOAD_STARTUP', this.startupId)
          .then((response) => {
            this.instance = response.data
          })
      ]).then(() => {
        this.setM2Ms()
      })
    } else {
      this.instance = {}
      this.$store.dispatch('LOAD_SEARCH_OPTIONS')
    }
  },

  methods: {
    searchTags(query, loading) {
      loading(true)
      this.$store.dispatch('SEARCH_TAGS', query)
        .then((response) => {
          this.tagOptions = response.data
        })
        .finally(() => {
          loading(false)
        })
    },
    searchCities(query, loading) {
      const ids = this.instance.company_location_city
      loading(true)
      this.$store.dispatch('SEARCH_CITIES', { ids, query })
        .finally(() => {
          loading(false)
        })
    },
    setM2Ms() {
      this.instance.current_investment_stage = this.instance.current_investment_stage.map((x) => parseInt(x.id))
      this.instance.current_investor_type = this.instance.current_investor_type.map((x) => parseInt(x.id))
      this.instance.preferred_investor_type = this.instance.preferred_investor_type.map((x) => parseInt(x.id))
      this.instance.preferred_investor_region = this.instance.preferred_investor_region.map((x) => parseInt(x.id))
      this.instance.company_location_city = this.instance.company_location_city.map((x) => parseInt(x.id))

      this.$store.dispatch('SEARCH_CITIES', { ids: this.instance.company_location_city })

      const countryFields = [
        'registered_country',
        'team_location',
        'preferred_investor_location',
        'current_investors_location',
        'expansion_countries'
      ]

      for (const field of countryFields) {
        this.instance[field] = this.instance[field].map ? this.instance[field].map((x) => x.code) : this.instance[field].code
      }
    },
    onCapTableFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.instance.current_cap_table = files[0]
    },
    onPitchDeckFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.instance.pitch_deck_file = files[0]
    },
    onExecutiveSummaryFileChange (e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.instance.executive_summary_file = files[0]
    },
    goView() {
      this.$router.push(`/startups/${this.instance.id}`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.instance.id}/delete`)
    },
    goMatch() {
      this.$router.push('/investors/contacts?match=true')
    },
    goRingfence() {
      this.$router.push(`/startups/${this.instance.id}/ringfence`)
    },
    goSwitch() {
      this.$router.push({ name: 'startups_selector' })
    },
    submit() {
      this.isSubmitting = true
      this.errors = {}

      if (this.startupId) {
        this.$store.dispatch('UPDATE_STARTUP', this.instance)
          .then(() => {
            this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId: this.startupId }),
            this.isSubmitting = false
            this.$router.push('/investors/contacts?match=true')
          })
          .catch((error) => {
            console.log('error', error)
            this.isSubmitting = false
            this.errors = error.response.data
          })
      } else {
        this.$store.dispatch('CREATE_STARTUP', this.instance)
          .then((response) => {
            this.$store.dispatch('LOAD_MATCH_FIELDS', { startupId:  response.data.id }),
            this.isSubmitting = false
            this.$router.push('/investors/contacts?match=true')
          })
          .catch((error) => {
            this.isSubmitting = false
            this.errors = error.response.data
          })
      }

    }
  }
}
</script>
