<template>
  <div>
    <div class="container slide">
      <div class="row">
        <div class="col">

          <h1 class="title">404 Not found</h1>

          <p>The page you're looking for doesn't seem to exist.</p>

          <router-link :to="{ name: 'home' }">&larr; Start from the Homepage</router-link>

        </div>
      </div>
    </div>
    <hr class="divider">
  </div>
</template>

<script>
  export default {}
</script>
