import axios from 'axios'

const state = {
  active: false,
  progress: 0
}

const actions = {
  LOADER_START ({ commit, dispatch }) {
    commit('SET_LOADER_ACTIVE', true)
    commit('SET_LOADER_PROGRESS', 10)
    dispatch('LOADER_TIMER')
  },

  LOADER_TIMER ({ state, commit, dispatch }) {
    if (!state.active || state.progress >= 80) {
      return
    }
    commit('SET_LOADER_PROGRESS', state.progress + 0.1)
    setTimeout(() => {
      dispatch('LOADER_TIMER')
    }, 10)
  },

  LOADER_STOP ({ commit, dispatch }) {
    commit('SET_LOADER_PROGRESS', 100)
    setTimeout(() => {
      dispatch('LOADER_CLEAR')
    }, 500)
  },

  LOADER_CLEAR ({ commit }) {
    commit('SET_LOADER_ACTIVE', false)
    commit('SET_LOADER_PROGRESS', 0)
  }
}

const getters = { }

const mutations = {
  SET_LOADER_ACTIVE (state, payload) {
    state.active = payload
  },
  SET_LOADER_PROGRESS (state, payload) {
    state.progress = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
