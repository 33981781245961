<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="text-md-end footer-links d-none d-md-block">
            &copy; {{ new Date().getFullYear() }} Astel Ventures Ltd.
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'app-footer',
  data() {
    return {}
  },
  created() {
  }
}
</script>

<style lang="scss"></style>
