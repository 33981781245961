<template>
  <div>
    <div v-if="company">

      <div class="row">
        <div class="col-lg-12">

          <div class="card">
            <div class="card-body">

              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goView">
                  <i class="uil uil-eye me-1"></i>
                  <span>View</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
              </div>

              <h3 class="mb-4">{{ company.name }}</h3>

              <span>
                <h4 class="header-title mb-3">Investor Information:
                  <span v-if="company.unsubscribed === 'YES'" class="fw-bold me-2 mb-2 "
                    style="color:red">UNSUBSCRIBED</span>
                </h4>
              </span>

              <div class="form-group mb-3">
                <label for="id_name">Name:</label>
                <input id="id_name" type="text" name="name" class="form-control" required v-model="company.name">
              </div>

              <div class="form-group mb-3">
                <label for="id_short_description">Short Description:</label>
                <textarea id="id_short_description" type="text" name="short_description" class="form-control" required
                  rows="10" v-model="company.short_description">
                </textarea>
              </div>

              <div class="form-group mb-3">
                <label for="id_full_description">Full Description:</label>
                <textarea id="id_full_description" type="text" name="full_description" class="form-control" required
                  rows="10" v-model="company.full_description">
                </textarea>
              </div>

              <div class="form-group mb-3">
                <label for="id_name">Website:</label>
                <input id="id_website" type="text" name="website" class="form-control" required v-model="company.website">
              </div>

              <div class="form-group mb-2">
                <label for="id_investor_location">Investor Location:</label>

                <v-select name="investor_location" v-model="company.investor_location" :options="optionsCountries"
                  :reduce="x => x.id" label="name" multiple>
                </v-select>
              </div>

              <div class="form-group mb-2">
                <label for="id_investor_city">Investor Cities:</label>

                <v-select name="investor_city" v-model="company.investor_city"
                  :options="optionsInvestorCities" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.investor_city && errors.investor_city.length">
                  <span v-for="error in errors.investor_city" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_profitable">Unsubscribed:</label>

                <v-select
                  name="unsubscribed"
                  v-model="company.unsubscribed"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.unsubscribed && errors.unsubscribed.length">
                  <span v-for="error in errors.unsubscribed" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3" v-if="company.unsubscribed === 'YES'">
                <label for="id_unsubscribe_comment">Unsubscribe Comment:</label>
                <input id="id_unsubscribe_comment" type="text" name="unsubscribe_comment" class="form-control" v-model="company.unsubscribe_comment">
                
                <p v-if="errors.unsubscribe_comment && errors.unsubscribe_comment.length">
                  <span v-for="error in errors.unsubscribe_comment" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
        </div> <!-- end col -->
      </div>
      <div class="row">

        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Investment Profile</h4>

              <div class="form-group mb-3">
                <label for="id_investment_sectors">Sectors:</label>
                <textarea id="id_investment_sectors" type="text" name="investment_sectors" class="form-control" required
                  rows="10" v-model="company.investment_sectors">
                </textarea>
              </div>
         
              <div class="form-group mb-2">
                <label for="id_current_investment_stage">Stages:</label>

                <v-select name="investment_stages" v-model="company.investment_stages"
                  :options="optionsInvestmentStages" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.investment_stages && errors.investment_stages.length">
                  <span v-for="error in errors.investment_stages" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_investment_stage">Type:</label>

                <v-select name="investor_type" v-model="company.investor_type"
                  :options="optionsInvestorTypes" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.investor_type && errors.investor_type.length">
                  <span v-for="error in errors.investor_type" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_investment_countries">Expansion Countries:</label>

                <v-select
                  name="investment_countries"
                  v-model="company.investment_countries"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.investment_countries && errors.investment_countries.length">
                  <span v-for="error in errors.investment_countries" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_industry_sector_tags">Industry and sectors tags:</label>

                <v-select
                  name="industry_sector_tags"
                  v-model="company.industry_sector_tags"
                  :options="tagOptions"
                  @search="searchTags"
                  multiple
                  taggable
                  :close-on-select="false"
                >
                </v-select>

                <p v-if="errors.industry_sector_tags && errors.industry_sector_tags.length">
                  <span v-for="error in errors.industry_sector_tags" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_profitable">Profitable:</label>

                <v-select
                  name="profitable"
                  v-model="company.profitable"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.profitable && errors.profitable.length">
                  <span v-for="error in errors.profitable" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
              <div class="form-group mb-2">
                <label for="id_profitable">Invests in in-house technology:</label>

                <v-select
                  name="profitable"
                  v-model="company.invest_in_house_technology"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.invest_in_house_technology && errors.invest_in_house_technology.length">
                  <span v-for="error in errors.invest_in_house_technology" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_profitable">Strategic Value:</label>

                <v-select
                  name="profitable"
                  v-model="company.strategic_value"
                  :options="valueOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.strategic_value && errors.strategic_value.length">
                  <span v-for="error in errors.strategic_value" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_profitable">Revenue Preference:</label>

                <v-select
                  name="profitable"
                  v-model="company.revenue_preference"
                  :options="revenuePreferenceOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.revenue_preference && errors.revenue_preference.length">
                  <span v-for="error in errors.revenue_preference" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_profitable">Investment partners location:</label>

                <v-select
                  name="investment_partners_location"
                  v-model="company.investment_partners_location"
                  :options="optionsCountries"
                  :reduce="x => x.id"
                  label="name"
                  multiple
                >
                </v-select>

                <p v-if="errors.investment_partners_location && errors.investment_partners_location.length">
                  <span v-for="error in errors.investment_partners_location" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_historic_investments">Historic Investments:</label>
                <input
                  id="id_historic_investments"
                  type="number"
                  step="1"
                  name="historic_investments"
                  class="form-control"
                  required
                  v-model="company.historic_investments"
                >

                <p v-if="errors.historic_investments && errors.historic_investments.length">
                  <span v-for="error in errors.historic_investments" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-2">
                <label for="id_current_investment_stage">Preferred Investor Region:</label>

                <v-select name="investment_stages" v-model="company.preferred_investor_region"
                  :options="optionsRegion" :reduce="x => x.id" label="name" multiple>
                </v-select>

                <p v-if="errors.preferred_investor_region && errors.preferred_investor_region.length">
                  <span v-for="error in errors.preferred_investor_region" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
             

              <div class="form-group mb-3">
                <label for="id_name">Profile Link:</label>
                <input id="id_profile_link" type="text" name="profile_link" class="form-control" required v-model="company.profile_link">

                <p v-if="errors.profile_link && errors.profile_link.length">
                  <span v-for="error in errors.profile_link" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <h4 class="header-title">
                Questions On The Fund
              </h4><br>
               <div class="form-group mb-3">
                <label for="id_past_funds">Past Funds:</label>
                <input id="id_past_funds" type="text" name="past_funds" class="form-control" required v-model="company.past_funds">

                <p v-if="errors.past_funds && errors.past_funds.length">
                  <span v-for="error in errors.past_funds" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div> 
               <div class="form-group mb-3">
                <label for="id_current_fundraise">current Fundraise:</label>
                <input id="id_current_fundraise" type="text" name="current_fundraise" class="form-control" required v-model="company.current_fundraise">

                <p v-if="errors.current_fundraise && errors.current_fundraise.length">
                  <span v-for="error in errors.current_fundraise" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div> 
              <div class="form-group mb-2">
                <label for="id_US_major_fund">US major fund:</label>

                <v-select
                  name="US_major_fund"
                  v-model="company.US_major_fund"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.US_major_fund && errors.US_major_fund.length">
                  <span v-for="error in errors.US_major_fund" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
        
              
              <div class="form-group mb-2">
                <label for="id_other_investment_funds">Other Investment Funds:</label>

                <v-select
                  name="other_investment_funds"
                  v-model="company.other_investment_funds"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.other_investment_funds && errors.other_investment_funds.length">
                  <span v-for="error in errors.other_investment_funds" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_top_invested_funds">Top Invested Funds:</label>
                <input id="id_top_invested_funds" type="text" name="top_invested_funds" class="form-control" v-model="company.top_invested_funds">
                
                <p v-if="errors.top_invested_funds && errors.top_invested_funds.length">
                  <span v-for="error in errors.top_invested_funds" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_lp_invested_in_fund">LP invested in fund:</label>
                <input id="id_lp_invested_in_fund" type="text" name="lp_invested_in_fund" class="form-control" v-model="company.lp_invested_in_fund">
                
                <p v-if="errors.lp_invested_in_fund && errors.lp_invested_in_fund.length">
                  <span v-for="error in errors.lp_invested_in_fund" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
             
              <div class="form-group mb-3">
                <label for="id_ownership_target">Ownership Target:</label>
                <input id="id_ownership_target" type="text" name="ownership_target" class="form-control" v-model="company.ownership_target">
                
                <p v-if="errors.ownership_target && errors.ownership_target.length">
                  <span v-for="error in errors.ownership_target" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_other_company_metrics">Other Company Metrics:</label>
                <input id="id_other_company_metrics" type="text" name="other_company_metrics" class="form-control" v-model="company.other_company_metrics">
                
                <p v-if="errors.other_company_metrics && errors.other_company_metrics.length">
                  <span v-for="error in errors.other_company_metrics" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
          


           </div>
          </div> 
          <div class="card">
            <div class="card-body">
            <h4 class="header-title">
                Deal Dashboard
              </h4><br>
             <div class="form-group mb-3">
                <label for="id_is_deal_dashboard">Deal Dashboard:</label>
                <input id="id_is_deal_dashboard" type="checkbox" name="is_deal_dashboard" class="ms-2" required v-model="company.is_deal_dashboard" >
                <p v-if="errors.is_deal_dashboard && errors.is_deal_dashboard.length">
                    <span v-for="error in errors.is_deal_dashboard" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              
          </div>
          </div>

        </div> <!-- end col -->

        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Investment Financials</h4>

              <div class="form-group mb-3">
                <label for="id_number_of_investments">No. of Investments:</label>
                <input
                  id="id_number_of_investments"
                  type="number"
                  step="1"
                  name="number_of_investments"
                  class="form-control"
                  required
                  v-model="company.number_of_investments"
                >

                <p v-if="errors.number_of_investments && errors.historic_investments.length">
                  <span v-for="error in errors.number_of_investments" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_number_of_exits">No. of exits:</label>
                <input
                  id="id_number_of_exits"
                  type="number"
                  step="1"
                  name="number_of_exits"
                  class="form-control"
                  required
                  v-model="company.number_of_exits"
                >

                <p v-if="errors.number_of_exits && errors.number_of_exits.length">
                  <span v-for="error in errors.number_of_exits" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_number_of_active_investments">No. of Active Investments:</label>
                <input
                  id="id_number_of_active_investments"
                  type="number"
                  step="1"
                  name="number_of_active_investments"
                  class="form-control"
                  required
                  v-model="company.number_of_active_investments"
                >
                <p v-if="errors.number_of_active_investments && errors.number_of_active_investments.length">
                  <span v-for="error in errors.number_of_active_investments" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_number_of_diversity_investments">No. of Diversity Investments:</label>
                <input
                  id="id_number_of_diversity_investments"
                  type="number"
                  step="1"
                  name="number_of_diversity_investments"
                  class="form-control"
                  required
                  v-model="company.number_of_diversity_investments"
                >
                <p v-if="errors.number_of_diversity_investments && errors.number_of_diversity_investments.length">
                  <span v-for="error in errors.number_of_diversity_investments" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_assets_under_management">Assets Under Management:</label>
                <input
                  id="id_assets_under_management"
                  type="number"
                  step="1"
                  name="assets_under_management"
                  class="form-control"
                  required
                  v-model="company.assets_under_management"
                >
                <p v-if="errors.assets_under_management && errors.assets_under_management.length">
                  <span v-for="error in errors.assets_under_management" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_ticket_size_from">Ticket size - From:</label>
                <input
                  id="id_ticket_size_from"
                  type="number"
                  step="1"
                  name="ticket_size_from"
                  class="form-control"
                  required
                  v-model="company.ticket_size_from"
                >
                <p v-if="errors.ticket_size_from && errors.ticket_size_from.length">
                  <span v-for="error in errors.ticket_size_from" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="ticket_size_to">Ticket size - To:</label>
                <input
                  id="ticket_size_to"
                  type="number"
                  step="1"
                  name="ticket_size_to"
                  class="form-control"
                  required
                  v-model="company.ticket_size_to"
                >
                <p v-if="errors.ticket_size_to && errors.ticket_size_to.length">
                  <span v-for="error in errors.ticket_size_to" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="valuation_from">Valuation - From:</label>
                <input
                  id="valuation_from"
                  type="number"
                  step="1"
                  name="valuation_from"
                  class="form-control"
                  required
                  v-model="company.valuation_from"
                >
                <p v-if="errors.valuation_from && errors.valuation_from.length">
                  <span v-for="error in errors.valuation_from" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="valuation_to">Valuation - To:</label>
                <input
                  id="valuation_to"
                  type="number"
                  step="1"
                  name="valuation_to"
                  class="form-control"
                  required
                  v-model="company.valuation_to"
                >
                <p v-if="errors.valuation_to && errors.valuation_to.length">
                  <span v-for="error in errors.valuation_to" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="minimum_monthly_recurring_from">MMR - From:</label>
                <input
                  id="minimum_monthly_recurring_from"
                  type="number"
                  step="1"
                  name="minimum_monthly_recurring_from"
                  class="form-control"
                  required
                  v-model="company.minimum_monthly_recurring_from"
                >
                <p v-if="errors.minimum_monthly_recurring_from && errors.minimum_monthly_recurring_from.length">
                  <span v-for="error in errors.minimum_monthly_recurring_from" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="minimum_monthly_recurring_to">MMR - To:</label>
                <input
                  id="minimum_monthly_recurring_to"
                  type="number"
                  step="1"
                  name="minimum_monthly_recurring_to"
                  class="form-control"
                  required
                  v-model="company.minimum_monthly_recurring_to"
                >
                <p v-if="errors.minimum_monthly_recurring_to && errors.minimum_monthly_recurring_to.length">
                  <span v-for="error in errors.minimum_monthly_recurring_to" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="EBITDA_requirements_from">EBITDA requirements - From:</label>
                <input
                  id="EBITDA_requirements_from"
                  type="number"
                  step="1"
                  name="EBITDA_requirements_from"
                  class="form-control"
                  required
                  v-model="company.EBITDA_requirements_from"
                >
                <p v-if="errors.EBITDA_requirements_from && errors.EBITDA_requirements_from.length">
                  <span v-for="error in errors.EBITDA_requirements_from" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="EBITDA_requirements_to">EBITDA requirements - To:</label>
                <input
                  id="EBITDA_requirements_to"
                  type="number"
                  step="1"
                  name="EBITDA_requirements_to"
                  class="form-control"
                  required
                  v-model="company.EBITDA_requirements_to"
                >
                <p v-if="errors.EBITDA_requirements_to && errors.EBITDA_requirements_to.length">
                  <span v-for="error in errors.EBITDA_requirements_to" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="ARR_requirement_from">ARR Requirement From:</label>
                <input
                  id="ARR_requirement_from"
                  type="number"
                  step="1"
                  name="ARR_requirement_from"
                  class="form-control"
                  required
                  v-model="company.ARR_requirement_from"
                >
                <p v-if="errors.ARR_requirement_from && errors.ARR_requirement_from.length">
                  <span v-for="error in errors.ARR_requirement_from" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="ARR_requirement_to">ARR Requirement To:</label>
                <input
                  id="ARR_requirement_to"
                  type="number"
                  step="1"
                  name="ARR_requirement_to"
                  class="form-control"
                  required
                  v-model="company.ARR_requirement_to"
                >
                <p v-if="errors.ARR_requirement_to && errors.ARR_requirement_to.length">
                  <span v-for="error in errors.ARR_requirement_to" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              

            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Astelios Contact Information</h4>
              <div class="form-group mb-3">
                <label for="id_astel_account_manager_name">Astel Account Manager Name:</label>
                <input id="id_astel_account_manager_name" type="text" name="astel_account_manager_name" class="form-control" v-model="company.astel_account_manager_name">
                
                <p v-if="errors.astel_account_manager_name && errors.astel_account_manager_name.length">
                  <span v-for="error in errors.astel_account_manager_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
 

               <div class="form-group mb-3">
                <label for="id_astel_account_manager_email">Astel Account Manager Email:</label>
                <input id="id_astel_account_manager_email" type="email" name="astelios_contact_email" class="form-control" v-model="company.astel_account_manager_email">
                
                <p v-if="errors.astel_account_manager_email && errors.astel_account_manager_email.length">
                  <span v-for="error in errors.astel_account_manager_email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
               <div class="form-group mb-3">
                <label for="id_date_of_call">Date of Call:</label>
                <input
                  id="id_date_of_call"
                  type="date"
                  name="date_of_call"
                  class="form-control"
                  v-model="company.date_of_call"
                >

                <p v-if="errors.date_of_call && errors.date_of_call.length">
                  <span v-for="error in errors.date_of_call" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Other Questions</h4>
              <div class="form-group mb-3">
                <label for="id_other_information">Other Information</label>
                <input id="id_other_information" type="text" name="other_information" class="form-control" v-model="company.other_information">
                
                <p v-if="errors.other_information && errors.other_information.length">
                  <span v-for="error in errors.other_information" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_video_interview">Video Interview:</label>
                <input id="id_video_interview" type="text" name="video_interview" class="form-control" v-model="company.video_interview">
                
                <p v-if="errors.video_interview && errors.video_interview.length">
                  <span v-for="error in errors.video_interview" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-2">
                <label for="id_re_engagement_call">Re Engagement Call:</label>

                <v-select
                  name="re_engagement_call"
                  v-model="company.re_engagement_call"
                  :options="yesNoOptions"
                  :reduce="x => x.id"
                >
                </v-select>

                <p v-if="errors.re_engagement_call && errors.re_engagement_call.length">
                  <span v-for="error in errors.re_engagement_call" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_kyc_partner">KYC Partner:</label>
                <input id="id_kyc_partner" type="text" name="kyc_partner" class="form-control" v-model="company.kyc_partner">
                
                <p v-if="errors.kyc_partner && errors.kyc_partner.length">
                  <span v-for="error in errors.kyc_partner" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
             
               <div class="form-group mb-3">
                <label for="id_muboriz_linkedin_oneliner">Muboriz Linkedin Oneliner:</label>
                <input id="id_muboriz_linkedin_oneliner" type="text" name="muboriz_linkedin_oneliner" class="form-control" v-model="company.muboriz_linkedin_oneliner">
                
                <p v-if="errors.muboriz_linkedin_oneliner && errors.muboriz_linkedin_oneliner.length">
                  <span v-for="error in errors.muboriz_linkedin_oneliner" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

          </div>
          </div>
        </div> <!-- end col -->


      </div>
      <!-- end row -->

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">
              <button class="btn btn-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'company',

  data() {
    return {
      company: null,
      errors: {},
      isSubmitting: false,
      tagOptions: [],
      yesNoOptions: [{id: 'YES', label: 'YES'}, {id: 'No', label: 'NO'}],
      valueOptions: [
        {id: 'INTRODUCTIONS', label: 'INTRODUCTIONS'},
        {id: 'NETWORK', label: 'NETWORK'},
        {id: 'TECHNOLOGY', label: 'TECHNOLOGY'},
        {id: 'LEGAL', label: 'LEGAL'},
        {id: 'WORKSPACE', label: 'WORKSPACE'},
        {id: 'CREDITS', label: 'CREDITS'},
        {id: 'OTHER', label: 'OTHER'},
      ],
      revenuePreferenceOptions: [
        {id: 'PRE-REVENUE', label: 'PRE-REVENUE'},
        {id: 'REVENUE GENERATING', label: 'REVENUE GENERATING'},
      ]
    }
  },

  computed: {
    ...mapState({
      optionsInvestorTypes: state => state.investors.investorTypes,
      optionsInvestmentStages: state => state.investors.investmentStages,
      optionsInvestorCities: state => state.investors.cities,
      optionsRegion: state => state.investors.regions,
      optionsCountries: state => state.investors.countries
    }),
    companyId() {
      return this.$route.params.id || (this.company ? this.company.id : null)
    },
  },

  mounted() {
    window.scrollTo(0, 0)
    Promise.all([
      this.$store.dispatch('LOAD_COMPANY', this.$route.params.id),
      this.$store.dispatch('LOAD_SEARCH_OPTIONS')
    ])
      .then((response) => {
        this.company = response[0].data
        this.setM2Ms()
      })
  },

  methods: {
    searchTags(query, loading) {
      loading(true)
      this.$store.dispatch('SEARCH_TAGS', query)
        .then((response) => {
          this.tagOptions = response.data
        })
        .finally(() => {
          loading(false)
        })
    },
    setM2Ms() {
      this.company.investment_stages = this.company.investment_stages.map((x) => parseInt(x.id))
      this.company.investor_type = this.company.investor_type.map((x) => parseInt(x.id))
      this.company.investor_city = this.company.investor_city.map((x) => parseInt(x.id))

      const countryFields = [
        'investment_countries',
        'investment_partners_location',
      ]

      for (const field of countryFields) {
        this.company[field] = this.company[field].map ? this.company[field].map((x) => x.code) : this.company[field].code
      }
    },
    goView() {
      this.$router.push(`/investors/companies/${this.company.id}`)
    },
    goEdit() {
      this.$router.push(`/investors/companies/${this.company.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/investors/companies/${this.company.id}/delete`)
    },
    submit () {
      this.isSubmitting = true
      this.errors = {}

      if (this.companyId) {
        this.$store.dispatch('UPDATE_COMPANY', this.company)
          .then(() => {
            this.isSubmitting = false
            this.$router.push(`/investors/companies/${this.company.id}`)
          })
          .catch((error) => {
            this.isSubmitting = false
            this.errors = error.response.data
          })
      } else {
        this.$store.dispatch('CREATE_COMPANY', this.company)
          .then((response) => {
            this.isSubmitting = false
            this.$router.push(`/investors/companies/${response.data.id}`)
          })
          .catch((error) => {
            this.isSubmitting = false
            this.errors = error.response.data
          })
      }
    }
  }
}
</script>
