<template>
  <div>
    <div v-if="company">

      <div class="row">
        <div class="col-lg-12">

          <div class="card">
            <div class="card-body">

              <div v-if="company && company.has_access" class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goEdit">
                  <i class="uil uil-pen me-1"></i>
                  <span>Edit</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
              </div>

              <h3 class="mb-4">
                {{ company.name }}
                <span class="badge text-white text-bg-dark" v-if="company.is_good_relation">GR</span>
                <span class="badge text-green" style="background-color: green; color: white;" v-if="company.is_deal_dashboard">DD</span>
                <span
                  v-if="company.unsubscribed === 'YES'"
                  class="small fw-bold me-2 mb-2 "
                  style="color:red"
                >
                  UNSUBSCRIBED
                </span>
              </h3>

              <ul class="nav nav-tabs nav-bordered mb-4" role="tablist">
                <li class="nav-item" role="presentation">
                  <a href="#" class="nav-link active" role="tab" @click.prevent="goProfile">
                    Profile
                  </a>
                </li>
                <li v-if="company && company.has_access" class="nav-item" role="presentation">
                  <a href="#" class="nav-link" role="tab" @click.prevent="goContacts">
                    Contacts ({{ company.contact_count }})
                  </a>
                </li>
              </ul>

              <div v-if="matchScore" class="mb-4">
                <h4 class="header-title mb-1">Match Score: {{ matchScore.score }}%</h4>
                <a href="#" @click.prevent="viewingMatchLog = !viewingMatchLog">Toggle Log</a>
                <div v-if="viewingMatchLog" v-html="matchScore.scoring_log" class="pre p-1" style="border: 1px solid #ccc"></div>
              </div>

              <span>
                <h4 class="header-title mb-3">Investor Information:</h4>
              </span>

              <p class="mb-2">
                {{ company.short_description }}
              </p>

              <h5>Full Description</h5>

              <p class="mb-2">
                {{ company.full_description }}
              </p>

              <p class="mb-2">
                <a :href="getFullUrl(company.website)" target="_blank">{{ company.website }}</a>
              </p>

              <p class="mb-2">
                <span class="fw-bold me-2">Investor location:</span>
                <template v-if="company.investor_location && company.investor_location.length">
                  <span v-for="item in company.investor_location" :key="item" class="d-block pre">
                    <template v-if="item">
                      <country-flag :country='item.code' size='small' />
                      {{ item.name }}
                    </template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2" v-if="company.investor_location_preferences">
                <span class="fw-bold me-2">Investor Location Preferences:</span>
                {{ company.investor_location_preferences }}
              </p>

              <p class="mb-2">
                <span class="fw-bold me-2">Investor cities:</span>
                <template v-if="company.investor_city && company.investor_city.length">
                  <span v-for="item in company.investor_city" :key="item" class="me-2">
                    <template v-if="item">{{ item.name }}</template>
                  </span>
                </template>
                <template v-else>
                  —
                </template>
              </p>

              <p class="mb-2" v-if="company.unsubscribed === 'YES'">
                <span class="fw-bold me-2">Unsubscribe Comment:</span>
                {{ company.unsubscribe_comment }}
              </p>

              <hr>
                     <span>
                      <h4 class="mt-4 header-title">Insights:</h4>
                    </span>
                   
                    <div class="mb-2" v-if="company.insights && company.insights.length && !company.insights_loading">                      
                      <div class="mb-3">
                        Filter:
                        <span class="badge badge-dark-lighten cursor-pointer ms-2" @click.prevent="this.insightType = null" :class="this.insightType === null ? 'border border-dark': ''">All</span>
                        <span class="badge badge-primary-lighten cursor-pointer ms-2" @click.prevent="this.insightType = 'Webpage'" :class="this.insightType === 'Webpage' ? 'border border-primary': ''">Webpage</span>
                        <span class="badge badge-success-lighten cursor-pointer ms-2" @click.prevent="this.insightType = 'Newsletter'" :class="this.insightType === 'Newsletter' ? 'border border-dark': ''">Newsletter</span>
                      </div>
                      <div class="timeline-alt py-0" v-if="Array.isArray(company.insights)">
                        <div class="timeline-item" v-for="item in filteredInsights" :key="item.id || item.timestamp">
                          <i class="mdi timeline-icon" :class="getInsightIconClasses(item)"></i>
                            <div class="timeline-item-info">
                                <a href="javascript:void(0);" class="fw-bold mb-1 d-block" :class="getInsightClass(item)">{{ item.title }}</a>
                                {{ item.content }}<br>
                                <template v-if="item.link">
                                  [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                                </template>
                                <p class="mb-0 pb-2">
                                  <template v-if="item.type === 'Newsletter'">
                                    <small class="text-muted">Email to ai@astelventures.com on {{ $filters.dateformat(item.timestamp) }}</small>
                                  </template>
                                  <template v-else>
                                    <small class="text-muted" v-if="item.timestamp">{{ $filters.dateformat(item.timestamp) }}</small>
                                    <small class="text-muted" v-else>Recently</small>
                                  </template>
                                </p>
                            </div>
                        </div>
                        <div v-if="!filteredInsights.length" class="text-muted">
                          No matching insights found yet.
                        </div>
                      </div>
                      <div v-else class="pre">
                        {{ company.insights }}
                      </div>
                    </div>

                    <div v-if="company.insights_timestamp" class="small text-muted mt-2">
                      Last updated: {{ $filters.dateformat(company.insights_timestamp) }}
                    </div>

                    <div class="pt-2" v-if="loadingInsights || company.insights_loading">
                      <img src="/static/gifs/loader.gif" alt="Loading" style="height: 48px;">
                      <p class="pt-2">
                        Our AI is generating insights on this company and will display the results once completed.
                      </p>
                    </div>
                    <div v-if="company.website" class="mt-2">
                      <button class="btn btn-outline-primary" @click="updateInsights" v-if="!loadingInsights">
                        <i class="uil uil-redo"></i>
                        <template v-if="!company.insights_loading">
                          Update Insights
                        </template>
                        <template v-else>
                          Force Update Insights
                        </template>
                      </button>
                    </div>
                    <div v-else>
                      <p class="small text-muted">Website address required to generate insights.</p>
                    </div>

            </div>
          </div>
        </div> <!-- end col -->
      </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">  
            <div class="card-body">      
            
              <h4 class="header-title mb-4">Portfolio Companies</h4>
            
              <div class="mb-2" v-if="company.online_portfolio && company.online_portfolio.length && !company.online_portfolio_loading">
                <ul v-if="Array.isArray(company.online_portfolio)">
                  <li v-for="item in company.online_portfolio" :key="item.id">
                    <b>Company Name :</b> {{ item.company_name }}<br>
                    <b>Announce Date :</b> {{ item.announce_date }}<br>
                    <b>Investment Stage :</b> {{ item.investment_stage }}<br>
                    <b>Money Raised :</b> {{ item.money_raised }}<br>
                    <b>Company Location :</b> {{ item.company_location }}<br>
                    <b>Industries :</b> {{ item.industries }}<br>
                    <span class="small" v-if="item.link">
                      [via <a :href="item.link" target="_blank">{{ getDomainFromURL(item.link) }}</a>]
                    </span>
                  </li>
                </ul>
                <div v-else class="pre">
                    {{ company.online_portfolio }}
                </div>
                <div v-if="company.online_portfolio_timestamp" class="small text-muted">
                    Last updated: {{ $filters.dateformat(company.online_portfolio_timestamp) }}
                </div>
              </div>
              <div v-else-if="!(loadingOnlinePortfolio || company.online_portfolio)">
                ---
              </div>

              <div class="pt-2" v-if="loadingOnlinePortfolio || company.online_portfolio_loading">
                <img src="/static/gifs/loader.gif" alt="Loading" style="height: 48px;">
                <p class="pt-2">
                  Our AI is gathering data on portfolio companies of this investor, we will display the results once completed.
                </p>
              </div> 


              <!-- Online Portfolio content (you can customize this part) -->
              <div v-if="company.website" class="mt-2">
                  <button class="btn btn-outline-primary" @click="companyPortfolio" v-if="!loadingOnlinePortfolio">
                    <i class="uil uil-redo"></i>
                    <template v-if="!company.online_portfolio">
                      Portfolio Companies
                    </template>
                    <template v-else>
                      Research Portfolio Investments
                    </template>
                  </button>
              </div>
            </div>
        </div>
      </div>
    </div>

      
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Investment Profile</h4>

              <ul class="list-unstyled mb-0">
                <li>
                  <p class="mb-2">
                    <span class="fw-bold me-2">Sectors:</span>
                    {{ company.investment_sectors }}
                  </p>
                  <p class="mb-2">
                    <span class="fw-bold me-2">Stages:</span>
                    <template v-if="company.investment_stages && company.investment_stages.length">
                      <span v-for="stage in company.investment_stages" :key="stage" class="d-block pre">
                        {{ stage.name }}
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>
                  <p class="mb-2">
                    <span class="fw-bold me-2">Type:</span>
                    <template v-if="company.investor_type && company.investor_type.length">
                      <span v-for="item in company.investor_type" :key="item" class="d-block pre">
                        {{ item.name }}
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>
                  <p class="mb-2">
                    <span class="fw-bold me-2">Countries invested in:</span>
                    <template v-if="company.investment_countries && company.investment_countries.length">
                      <span v-for="item in company.investment_countries" :key="item" class="d-block pre">
                        <template v-if="item">
                          <country-flag :country='item.code' size='small' />
                          {{ item.name }}
                        </template>
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>
                  <p class="mb-2">
                    <span class="fw-bold me-2">Tags:</span>
                    <template v-if="company.industry_sector_tags && company.industry_sector_tags.length">
                      <span v-for="item in company.industry_sector_tags" :key="item" class="d-block pre">
                        {{ item }}
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
                  </p>
                  <p class="mb-2" v-if="company.profitable">
                    <span class="fw-bold me-2">Profitable:</span>
                    {{ company.profitable }}
                  </p>
                  <p class="mb-2" v-if="company.invest_in_house_technology">
                    <span class="fw-bold me-2">Invest in house technology:</span>
                    {{ company.invest_in_house_technology }}
                  </p>
                  <p class="mb-2" v-if="company.strategic_value">
                    <span class="fw-bold me-2">Strategic Value:</span>
                    {{ company.strategic_value }}
                  </p>
                  <p class="mb-2" v-if="company.revenue_preference">
                    <span class="fw-bold me-2">Revenue Preference:</span>
                    {{ company.revenue_preference }}
                  </p>
                  <p class="mb-2" v-if="company.investment_partners_location.length">
                    <span class="fw-bold me-2">Investment partners location:</span>
                    <span v-for="item in company.investment_partners_location" :key="item" class="d-block pre">
                      <template v-if="item">
                        <country-flag :country='item.code' size='small' />
                        {{ item.name }}
                      </template>
                    </span>
                  </p>
                  <p class="mb-2" v-if="company.historic_investments">
                    <span class="fw-bold me-2">Historic Investments:</span>
                    {{ $filters.money(company.historic_investments) }}
                  </p>
                 
                   <p class="mb-2">
                    <span class="fw-bold me-2">Preferred Investor Region:</span>
                    <template v-if="company.preferred_investor_region && company.preferred_investor_region.length">
                      <span v-for="item in company.preferred_investor_region" :key="item" class="d-block pre">
                        {{ item.name }}
                      </span>
                    </template>
                    <template v-else>
                      —
                    </template>
              </p>
                  <p class="mb-0" v-if="company.profile_link">
                    <span class="fw-bold me-2">Profile Link:</span>
                    <a :href="company.profile_link" target="_blank">{{ company.profile_link }}</a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div class="card" v-if="!siteCompany.is_whitelabel">
            <div class="card-body">
              <h4 class="header-title">
                Questions On The Fund
              </h4>
              <p class="mb-2">
                    <span class="fw-bold me-2">Past Funds:</span>
                    {{ company.past_funds }}
              </p>
              <p class="mb-2">
                    <span class="fw-bold me-2">Current Fundraise:</span>
                    {{ company.current_fundraise }}
              </p>
              <p class="mb-2">
                    <span class="fw-bold me-2">US major fund:</span>
                  {{company.US_major_fund}}
                 
              </p>
              <p class="mb-2">
                    <span class="fw-bold me-2">Other Investment Funds:</span>
                  {{company.other_investment_funds}}
                 
              </p>
              <p class="mb-2">
                    <span class="fw-bold me-2">Top 3 Investment Funds:</span>
                    {{ company.top_invested_funds }}
              </p>
              <p class="mb-2">
                    <span class="fw-bold me-2">LP Invested in Fund:</span>
                    {{ company.lp_invested_in_fund }}
              </p>
              
              <p class="mb-2">
                    <span class="fw-bold me-2">Ownership Target:</span>
                    {{ company.ownership_target }}
              </p>
              <p class="mb-2">
                    <span class="fw-bold me-2">Other Company Metrics:</span>
                    {{ company.other_company_metrics }}
              </p>
              
              
           </div>
          </div> 
        </div> <!-- end col -->

        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Investment Financials</h4>

              <ul class="list-unstyled mb-0">
                <li>
                  <p class="mb-2" v-if="company.number_of_investments">
                    <span class="fw-bold me-2">No. of Investments:</span>
                    {{ company.number_of_investments }}
                  </p>
                  <p class="mb-2" v-if="company.number_of_exits">
                    <span class="fw-bold me-2">No. of exits:</span>
                    {{ company.number_of_exits }}
                  </p>
                  <p class="mb-2" v-if="company.number_of_active_investments">
                    <span class="fw-bold me-2">No. of Active Investments:</span>
                    {{ company.number_of_active_investments }}
                  </p>
                  <p class="mb-2" v-if="company.number_of_diversity_investments">
                    <span class="fw-bold me-2">No. of Diversity Investments:</span>
                    {{ company.number_of_diversity_investments }}
                  </p>
                  <p class="mb-2" v-if="company.assets_under_management">
                    <span class="fw-bold me-2">Assets Under Management:</span>
                    ${{ $filters.money(company.assets_under_management) }}
                  </p>
                  <p class="mb-2" v-if="company.ticket_size_from || company.ticket_size_to">
                    <span class="fw-bold me-2">Ticket size:</span>
                    ${{ $filters.money(company.ticket_size_from) }} - {{ $filters.money(company.ticket_size_to) }}
                  </p>
                  <p class="mb-2" v-if="company.valuation_from || company.valuation_to">
                    <span class="fw-bold me-2">Valuation range:</span>
                    ${{ $filters.money(company.valuation_from) }} - {{ $filters.money(company.valuation_to) }}
                  </p>
                  <p class="mb-2" v-if="company.minimum_monthly_recurring_from || company.minimum_monthly_recurring_to">
                    <span class="fw-bold me-2">MMR:</span>
                    ${{ $filters.money(company.minimum_monthly_recurring_from) }} - {{
                      $filters.money(company.minimum_monthly_recurring_to) }}
                  </p>
                  <p class="mb-2" v-if="company.EBITDA_requirements_from || company.EBITDA_requirements_to">
                    <span class="fw-bold me-2">EBITDA requirements:</span>
                    ${{ company.EBITDA_requirements_from }} - {{ company.EBITDA_requirements_to }}
                  </p>
                  <p class="mb-2" v-if="company.ARR_requirement_from || company.ARR_requirement_to">
                    <span class="fw-bold me-2">ARR Requirement:</span>
                    ${{ $filters.money(company.ARR_requirement_from) }} - {{ $filters.money(company.ARR_requirement_to) }}
                 </p>
                </li>
              </ul>

            </div>
          </div>
          <div  class="card" v-if="!siteCompany.is_whitelabel">
            <div class="card-body">
          
              <h4>Astelios Contact Information</h4><br>

              <p class="mb-2">
                    <span class="fw-bold me-2">Astel Account Manager Name:</span>
                    {{ company.astel_account_manager_name}}
              </p>
             
              <p class="mb-2">
                    <span class="fw-bold me-2">Astel Account Manager Email:</span>
                    {{ company.astel_account_manager_email }}
              </p>
               <p class="mb-2">
                    <span class="fw-bold me-2">Date of Call:</span>
                    {{ company.date_of_call }}
              </p>
              
            </div>
          </div>
          <div  class="card" v-if="!siteCompany.is_whitelabel">
            <div class="card-body">
          
              <h4>Other Questions</h4><br>
              <p class="mb-2">
                    <span class="fw-bold me-2">Other Information:</span>
                    {{ company.other_information }}
              </p>
               <p class="mb-2">
                    <span class="fw-bold me-2">Video Interview:</span>
                    {{ company.video_interview }}
              </p>
               <p class="mb-2">
                    <span class="fw-bold me-2">Re Engagement Call:</span>
                  
                   {{company.re_engagement_call}}
              </p>
               <p class="mb-2">
                    <span class="fw-bold me-2">KYC Partner:</span>
                    {{ company.kyc_partner }}
              </p>
              
               <p class="mb-2">
                    <span class="fw-bold me-2">Muboriz Linkedin Oneliner:</span>
                    {{ company.muboriz_linkedin_oneliner }}
              </p>

            </div>
          </div>

        </div> <!-- end col -->


      </div>
      <!-- end row -->
    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'company',

  data() {
    return {
      company: null,
      loadingInsights: false,
      loadingOnlinePortfolio: false,
      matchScore: null,
      insightType: null,
      viewingMatchLog: false,
    }
  },

  computed: {
    ...mapState({
      startup: state => state.startups.current,
      siteCompany: state => state.auth.siteCompany,
    }),
    hasNewsletterInsights() {
      return this.company.insights.filter(item => item.type === 'Newsletter');
    },
    hasNotNewsletterInsights() {
      return this.company.insights.filter(item => item.type !== 'Newsletter');
    },
    filteredInsights() {
      return this.company.insights.filter((item) => {
        if (this.insightType) {
          if (this.insightType === 'Webpage') {
            return !item.type || item.type === this.insightType
          }
          return item.type === this.insightType
        }
        return !item.type || item.type === 'Newsletter'
      })
    },
  },

  mounted() {
    window.scrollTo(0, 0)
    this.load()
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
  },

  methods: {
    load() {
      this.$store.dispatch('LOAD_COMPANY_MATCH_SCORE', {startupId: this.startup.id, companyId: this.$route.params.id })
        .then((response) => {
          this.matchScore = response.data
        })
      
      return this.$store.dispatch('LOAD_COMPANY', this.$route.params.id)
        .then((response) => {
          this.company = response.data
        })
    },

    goView() {
      this.$router.push(`/investors/companies/${this.company.id}`)
    },

    goEdit() {
      this.$router.push(`/investors/companies/${this.company.id}/edit`)
    },

    goDelete() {
      this.$router.push(`/investors/companies/${this.company.id}/delete`)
    },

    goProfile() {
      this.$router.push(`/investors/companies/${this.company.id}`)
    },

    goContacts() {
      this.$router.push(`/investors/companies/${this.company.id}/contacts`)
    },

    updateInsights() {
      this.loadingInsights = true
      this.$store.dispatch('UPDATE_COMPANY_INSIGHTS', this.company)
        .then((response) => {
          this.company = response.data
        })
    },
    companyPortfolio() {
      this.loadingOnlinePortfolio = true
      this.$store.dispatch('ONLINE_PORTFOLIO', this.company)
        .then((response) => {
          this.company = response.data
        })
    },

    getFullUrl(url) {
      if (!url) return ''
      if (url.indexOf('http') === 0) return url
      return `http://${url}`
    },

    getDomainFromURL(url) {
      if (!url) return ''
      const domain = url.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
      return domain
    },

    getInsightTopic(item) {
      if (item.type) {
       
        if (item.type.includes('Newsletter')) {
          return 'Newsletter'
        }
      }
      return 'Webpage'
    },

    getInsightClass(item) {
      if (item.type) {
       
        if (item.type.includes('Newsletter')) {
          return 'text-success'
        }
      }
      return 'text-default'
    },

    getInsightIconClasses(item) {
      if (item.type) {
        
        if (item.type.includes('Newsletter')) {
          return 'text-success bg-success-lighten mdi-gmail'
        }
      }
      return 'text-default bg-primary-lighten mdi-web'
    }

  }
}
</script>
