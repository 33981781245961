<template>
  <div>
    <div v-if="contact">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box">
            <h4 class="page-title">{{ contact.first_name }} {{ contact.last_name }}</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->

      
      <div class="row">

        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4>Are you sure you want to delete '{{ contact.first_name }} {{ contact.last_name }}'?</h4>
              <button class="btn btn-outline-primary me-2 mt-4" :class="{ disabled: isSubmitting }" @click.prevent="goView">
                Cancel
              </button>
              <button class="btn btn-danger me-2 mt-4" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Delete Contact
              </button>
            </div>
          </div>
        </div> <!-- end col -->

        </div>
    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'contact',

  data() {
    return {
      contact: null,
      isSubmitting: false
    }
  },

  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('LOAD_CONTACT', { contactId: this.$route.params.id })
          .then((response) => {
            this.contact = response.data
          })
  },

  methods: {
    goView() {
      this.$router.push(`/contacts/${this.contact.id}`)
    },
    goEdit() {
      this.$router.push(`/contacts/${this.contact.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/contacts/${this.contact.id}/delete`)
    },
    submit() {
      this.isSubmitting = true
      this.$store.dispatch('DELETE_CONTACT', this.$route.params.id)
        .then(() => {
          this.isSubmitting = false
          this.$router.push('/investors/contacts')
        })
    }
  }
}
</script>
