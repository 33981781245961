// In navigationUtils.js

// In navigationUtils.js

let isFirstLoad = true;

export function isInitialLoadOrReload() {
  if (isFirstLoad) {
    isFirstLoad = false;
    sessionStorage.setItem('appLoadTimestamp', Date.now().toString());
    return true;
  }

  if ('performance' in window && 'getEntriesByType' in performance) {
    const navEntries = performance.getEntriesByType('navigation');
    if (navEntries.length > 0) {
      const navEntry = navEntries[0];
      const loadTimestamp = parseInt(sessionStorage.getItem('appLoadTimestamp') || '0', 10);
      
      // Check if it's a reload and not just a route change
      if (navEntry.type === 'reload' && navEntry.startTime > loadTimestamp) {
        sessionStorage.setItem('appLoadTimestamp', Date.now().toString());
        return true;
      }
    }
  }
  return false;
}