import axios from 'axios'

const state = {

  engagementcallinfo: null,
  current: null,
 

}

const actions = {


  LOAD_ENGAGEMENT_CALL_INFO ({ commit, dispatch, state }, engagementCallId) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}engagement_call_info/${engagementCallId}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },
  LOAD_ENGAGEMENT_CALL_PROFILE ({ commit, dispatch, state }, contactEmail ) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}engagement_call_profile/${contactEmail}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  LIST_ENGAGEMENT_CALL_INFO ({ commit, dispatch, state }, params) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}engagement_call_info/`, { params })
        .then((response) => {
          resolve(response)
          commit('SET_ENGAGEMENT_CALL_INFO', response.data)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },


  CREATE_ENGAGEMENT_CALL_INFO ({ commit, dispatch, state }, payload) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}engagement_call_info/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

}

const getters = {}

const mutations = {
  SET_ENGAGEMENT_CALL_INFO (state, payload) {
    state.engagementcallinfo = payload
  },
  SET_CURRENT_ENGAGEMENT_CALL_INFO (state, payload) {
    state.current = payload
  },
  SET_ENGAGEMENT_CALL_PROFILE (state, payload) {
    state.engagementcallprofile = payload
  },


}

export default {
  state,
  getters,
  actions,
  mutations
}
