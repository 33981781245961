import axios from 'axios'

const state = {
  contacts: null,
  warmIntroTemplates: [],
}

const actions = {

  LOAD_PERSONALISE_CONTACTS ({ commit, dispatch, state }, { startupId, searchId, params }) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/personalise/${searchId}/`, { params })
        .then((response) => {
          commit('SET_PERSONALISE_CONTACTS', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

  GENERATE_CONTACT_EMAIL_OPENERS ({ commit, dispatch, state }, { startupId, searchId }) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/personalise/${searchId}/`, { contacts: true })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  GENERATE_CAREER_EMAIL_OPENERS ({ commit, dispatch, state }, { startupId, searchId }) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/personalise/${searchId}/`, { career: true })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  GENERATE_CAREER_OPENER_ONE_LINER_FOR_STARTUP ({ commit, dispatch, state }, startupId) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/personalise/career/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  GENERATE_MANAGER_EMAIL_OPENERS ({ commit, dispatch, state }, { startupId, searchId }) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/personalise/${searchId}/`, { manager: true })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  SEND_WARM_INTROS ({ commit, dispatch, state }, { startupId, searchId }) {
    const payload = {
      startup_id: startupId,
      saved_search_id: searchId,
    }
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}send_email/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  CREATE_CONTACTS_TO_APOLLO ({ commit, dispatch, state }, searchId) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}apollo_contact_create/${searchId}/`, {})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  ENRICH_CONTACTS_WITH_APOLLO ({ commit, dispatch, state }, searchId) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}apollo_contact_enrich/${searchId}/`, {})
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  UPDATE_EMAIL_OPENER ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${import.meta.env.VITE_API_URL}email_opener/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  UPDATE_CONTACT_EMAIL_OPENER ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.put(`${import.meta.env.VITE_API_URL}contact_email_opener/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  LOAD_WARM_INTRO_TEMPLATES ({ commit, dispatch, state }, { startupId }) {
    return new Promise((resolve, reject) => {
      axios.get(`${import.meta.env.VITE_API_URL}startups/${startupId}/warm_intro_templates/`)
        .then((response) => {
          commit('SET_WARM_INTRO_TEMPLATES', response.data)
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  SAVE_WARM_INTRO_TEMPLATE ({ commit, dispatch, state }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${payload.startup}/warm_intro_templates/`, payload)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },

  GENERATE_WARM_INTROS ({ commit, dispatch, state }, { startupId, searchId, templateId }) {
    return new Promise((resolve, reject) => {
      axios.post(`${import.meta.env.VITE_API_URL}startups/${startupId}/personalise/${searchId}/`, { warm_intros: true, warm_intro_template_id: templateId })
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  },
  
  REMOVE_SAVED_SEARCH_CONTACT ({ commit, dispatch, state }, {searchId, contactId}) {
    dispatch('LOADER_START')
    return new Promise((resolve, reject) => {
      axios.delete(`${import.meta.env.VITE_API_URL}saved_search/${searchId}/contact_delete/${contactId}/`)
        .then((response) => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
        .finally(() => {
          dispatch('LOADER_STOP')
        })
    })
  },

}

const getters = {}

const mutations = {
  SET_PERSONALISE_CONTACTS (state, payload) {
    state.contacts = payload
  },
  SET_WARM_INTRO_TEMPLATES (state, payload) {
    state.warmIntroTemplates = payload
  },
  CLEAR_PERSONALISE (state) {
    state.contacts = null
    state.warmIntroTemplates = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
