<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">

      <div v-if="!isLoading" class="row mt-3">

        <div class="col pe-0">
          <div class="card">
            <div class="card-body">
              <h3>Email Accounts</h3>

              <p v-if="!accounts.length" class="text-center">No email accounts added yet.</p>

              <div v-else class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Email</th>
                      <th>Account ID</th>
                      <th>Name</th>
                      <th>Last Sync</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="account in accounts" :key="account.id">
                      <td>{{ account.email }}</td>
                      <td>{{ account.account }}</td>
                      <td>{{ account.name }}</td>
                      <td>
                        <span v-if="account.syncTime">{{ $filters.datetimeformat(account.syncTime) }}</span>
                        <span v-else>No sync yet</span>
                      </td>
                      <td class="text-end">
                        <a href="#" class="btn btn-sm btn-outline-primary me-4" @click.prevent="sync(account)">
                          <i class="uil uil-sync me-1"></i>
                          <span>Sync</span>
                        </a>
                        <a href="#" class="btn btn-sm btn-outline-danger" @click.prevent="remove(account)">
                          <i class="uil uil-trash me-1"></i>
                          <span>Remove</span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr>

              <p>Add new account:</p>
              <loader v-if="isSubmitting"></loader>
              <form v-if="!isSubmitting" @submit.prevent="submit" class="col-md-6">
                
                <div class="form-group mb-3">
                  <label for="id_account_id">Account ID:</label>
                  <input id="id_account_id" type="text" name="account_id" class="form-control" required v-model="form.account_id">

                  <p v-if="errors.account_id && errors.account_id.length">
                    <span v-for="error in errors.account_id" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                </div>

                <div class="form-group mb-3">
                  <label for="id_name">Name:</label>
                  <input id="id_name" type="text" name="name" class="form-control" required v-model="form.name">

                  <p v-if="errors.name && errors.name.length">
                    <span v-for="error in errors.name" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                </div>

                <div class="form-group mb-3">
                  <label for="id_email">Email:</label>
                  <input id="id_email" type="text" name="email" class="form-control" required v-model="form.email">

                  <p v-if="errors.email && errors.email.length">
                    <span v-for="error in errors.email" :key="error" class="text-danger">{{ error }}</span>
                  </p>
                </div>

                <!-- used_for_sending checkbox -->
                <div class="form-check mb-3">
                  <input id="id_used_for_sending" type="checkbox" name="used_for_sending" class="form-check-input" v-model="form.used_for_sending">
                  <label for="id_used_for_sending" class="form-check-label">Used for sending</label>
                </div>

                <div v-if="hasError" class="text-danger me-2">Error. Please check the form.</div>
                <button class="btn btn-outline-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                  Create Email Account
                </button>

              </form>


            </div>
          </div>
        </div>

      </div>

      <div v-else class="text-center">
        <loader></loader>
      </div>

      <hr class="space space-md" />
      <app-footer></app-footer>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'email-accounts',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      form: {
        account_id: '',
        name: '',
        email: '',
        redirect_url: window.location.href
      },
      errors: {},
      accounts: [],
      isLoading: true,
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      userState: state => state.auth.user
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)

    this.load()
  },

  methods: {

    load() {
      this.$store.dispatch('LOAD_EMAIL_ACCOUNTS')
        .then((response) => {
          this.accounts = response.data
        })
        .catch((error) => {
          this.errors = error.response.data
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    submit () {
      this.isSubmitting = true
      this.errors = {}

      this.$store.dispatch('CREATE_EMAIL_ACCOUNT', this.form)
        .then((response) => {
          window.location.href = response.data
        })
        .catch((error) => {
          this.isSubmitting = false
          this.errors = error.response.data
        })
    },

    sync(account) {
      this.$store.dispatch('SYNC_EMAIL_ACCOUNT', account.account)
        .then(() => {
          this.load()
          alert('Sync started. It may take a few minutes to complete.')
        })
        .catch((error) => {
          this.errors = error.response.data
        })
    },

    remove(account) {
      if (!confirm(`Are you sure you want to remove ${account.email} account?`)) {
        return
      }

      this.isLoading = true

      this.$store.dispatch('DELETE_EMAIL_ACCOUNT', account.account)
        .then(() => {
          this.load()
        })
        .catch((error) => {
          this.errors = error.response.data
        })
        .finally(() => {
          this.isLoading = false
        })
    }

  }
}
</script>
