<template>
  <div>
    <div v-if="startup">

      <div class="row">
        <div class="col-lg-12">

          <div class="card" v-if="startup">
            <div class="card-body">
              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <template v-if="startup">
                  <a href="#" class="btn btn-outline-primary" @click.prevent="goView">
                    <i class="uil uil-eye me-1"></i>
                    <span>View</span>
                  </a>
                  <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                    <i class="uil uil-trash me-1"></i>
                    <span>Delete</span>
                  </a>
                  <a href="#" class="btn btn-outline-info" @click.prevent="goRingfence">
                    <i class="uil uil-bag-slash me-1"></i>
                    <span>Manage Ringfence</span>
                  </a>
                </template>
                <a href="#" class="btn btn-outline-primary" @click.prevent="goSwitch">
                  <i class="uil uil-exchange me-1"></i>
                  <span>Switch Company</span>
                </a>
              </div>

              <h3>{{ startup.name }}</h3>

              <p class="mb-2 pre">
                {{ startup.description }}
              </p>

            </div>
          </div>
        </div> <!-- end col -->
      </div>
      <div class="row">

        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4>Are you sure you want to delete '{{ startup.name }}'?</h4>
              <button class="btn btn-outline-primary me-2 mt-4" :class="{ disabled: isSubmitting }" @click.prevent="goView">
                Cancel
              </button>
              <button class="btn btn-danger me-2 mt-4" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Delete Company
              </button>
            </div>
          </div>
        </div> <!-- end col -->

      </div>
      <!-- end row -->

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'startup-edit',

  data() {
    return {
      startup: null,
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      optionsInvestorTypes: state => state.investors.investorTypes,
      optionsInvestmentStages: state => state.investors.investmentStages,
      optionsCountries: state => state.investors.countries
    })
  },

  mounted() {
    window.scrollTo(0, 0)
    this.$store.dispatch('LOAD_STARTUP', this.$route.params.id)
      .then((response) => {
        this.startup = response.data
      })
  },

  methods: {
    goView() {
      this.$router.push(`/startups/${this.startup.id}`)
    },
    goDelete() {
      this.$router.push(`/startups/${this.startup.id}/delete`)
    },
    goMatch() {
      this.$router.push('/investors/contacts?match=true')
    },
    goRingfence() {
      this.$router.push(`/startups/${this.startup.id}/ringfence`)
    },
    submit() {
      this.isSubmitting = true
      this.$store.dispatch('DELETE_STARTUP', this.startup)
        .finally(() => {
          this.isSubmitting = false
          this.$router.push(`/startups/selector`)
        })
    }
  }
}
</script>
