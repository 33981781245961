<template>
  <div>
    <app-header></app-header>
    <div class="container-fluid">

      <div v-if="user" class="row mt-3">

        <div class="col pe-0">
          <div class="card">
            <div class="card-body">
              <h3>Startup Invitation Links</h3>

              <!-- list of created invitations with a link and delete button + form to create new one -->

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Created by</th>
                    <th scope="col">Date</th>
                    <th scope="col">Link</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invitation in invitations" :key="invitation.id">
                    <td>
                      {{ invitation.created_by.email }}
                      <template v-if="invitation.created_by.first_name || invitation.created_by.last_name">
                        ({{ invitation.created_by.first_name }} {{ invitation.created_by.last_name }})
                      </template>
                    </td>
                    <td>
                      {{ $filters.dateformat(invitation.created_at) }}
                    </td>
                    <td>
                      <a :href="invitation.link" target="_blank" rel="noopener noreferrer">
                        {{ invitation.link }}
                      </a>
                    </td>
                    <td>
                      <button class="btn btn-danger" @click="deleteInvitation(invitation.id)">
                        <i class="uil uil-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>

              <button class="btn btn-outline-primary" @click="createInvitation" v-if="!isSubmitting">
                Create new invitation
              </button>
              <loader v-else></loader>

            </div>
          </div>
        </div>

      </div>

      <div v-else class="text-center">
        <loader size="32px"></loader>
      </div>

      <hr class="space space-md" />
      <app-footer></app-footer>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Header from '@/components/Layout/Header.vue'
import Footer from '@/components/Layout/Footer.vue'

export default {
  name: 'account',

  components: {
    'app-header': Header,
    'app-footer': Footer
  },

  data() {
    return {
      user: null,
      errors: {},
      isSubmitting: false,
    }
  },

  computed: {
    ...mapState({
      userState: state => state.auth.user,
      invitations: state => state.auth.invitations
    }),
    hasError() {
      return Object.keys(this.errors).length > 0
    }
  },
  
  mounted() {
    window.scrollTo(0, 0)

    this.user = this.userState

    this.$store.dispatch('LOAD_INVITATIONS')
  },

  methods: {
    submit () {
      this.isSubmitting = true
      this.errors = {}

      this.$store.dispatch('UPDATE_USER', this.user)
        .then(() => {
          this.isSubmitting = false
        })
        .catch((error) => {
          this.isSubmitting = false
          this.errors = error.response.data
        })
    },
    goReplyIoKey() {
      this.$router.push({ name: 'reply-io-key' })
    },
    createInvitation() {
      this.isSubmitting = true
      this.$store.dispatch('CREATE_INVITATION')
        .finally(() => {
          this.$store.dispatch('LOAD_INVITATIONS')
            .finally(() => {
              this.isSubmitting = false
            })
        })
    },
  }
}
</script>
