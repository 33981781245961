import Loader from '@/components/Includes/Loader.vue'
import Pagination from '@/components/Includes/Pagination.vue'

import { Field, Form, ErrorMessage } from 'vee-validate'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import CountryFlag from 'vue-country-flag-next'

import { Money3Component } from 'v-money3'


/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    // components
    Vue.component('loader', Loader)
    Vue.component('pagination', Pagination)
    // vee-validate
    Vue.component('v-field', Field)
    Vue.component('v-form', Form)
    Vue.component('v-errormessage', ErrorMessage)
    // vue-multiselect
    Vue.component('v-select', vSelect)
    // v-money3
    Vue.component('v-number-input', Money3Component)
    // vue-country-flag
    Vue.component('country-flag', CountryFlag)
  }
}

export default GlobalComponents
