<template>
  <div v-if="user">
    <div class="largest px-4 bg-grey" @click.prevent="isShown = !isShown">
      <i
        class="uil uil-bell cursor-pointer"
        :class="{ 'text-black': isShown }"
      ></i>
      <span
        v-if="activeJobs.length"
        class="badge text-white text-bg-danger small position-absolute"
      >
        {{ activeJobs.length }}
      </span>
    </div>
    <div class="job-monitor" v-if="isShown">
      <div class="job-monitor-scroll">
        <div v-if="jobs && jobs.length" class="d-flex flex-column flex-grow-1">
          <div
            class="d-flex justify-content-between align-items-center pt-2 pb-2 border-bottom"
            v-for="job in jobs"
          >
            <div class="w-100">
              <p class="my-1 lh1">
                <a href="#" @click.prevent="setJob(job)">{{ job.title }}</a>
              </p>

              <div
                class="progress-w-percent mb-0"
                style="width: 100%"
                v-if="job.is_active"
              >
                <span class="progress-value fw-bold"
                  >{{ job.percent_complete }}%</span
                >
                <div class="progress progress-sm">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    :style="`width: ${job.percent_complete}%;`"
                    :aria-valuenow="job.percent_complete"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div v-else-if="job.is_cancelled">
                <p>Cancelled</p>
                <template v-if="job.cancelled_at"
                    >at {{ $filters.datetimeformat(job.cancelled_at) }}</template
                  >
              </div>
              <div v-else-if="job.is_completed">
                <p>
                  Completed
                  <template v-if="job.completed"
                    >at {{ $filters.datetimeformat(job.completed) }}</template
                  >
                </p>
              </div>
              <div v-else-if="job.is_failed">
                <p>
                  Failed
                  <template v-if="job.failed_at"
                    >at {{ $filters.datetimeformat(job.failed_at) }}</template
                  >
                </p>
              </div>
            </div>
            <div class="ms-2" style="width: 80px">
              <loader
                v-if="job.is_active && isCancelling === job.id"
                size="32px"
              ></loader>
              <button
                v-else-if="job.is_active"
                class="btn btn-sm btn-outline-danger"
                @click.prevent="cancelJob(job.id)"
              >
                Cancel
              </button>
              <i
                v-else-if="job.is_completed"
                class="uil uil-check-circle text-success larger"
              ></i>
              <i v-else class="uil uil-times-circle text-warning larger"></i>
            </div>
          </div>
        </div>
        <div v-else class="ms-2 py-2 d-flex flex-column">
          <p class="small mb-0">No notifications yet.</p>
          <button
            type="button"
            class="btn btn-primary"
            v-if="this.jobNotificationCandidates.length > 0 && !this.showNextNotification"
            @click="showNextNotificationMethod"
          >
            Show Next Notification
          </button>
        </div>
        <div class="job-monitor-overlay" v-if="current">
          <div class="job-monitor-overlay-inner">
            <button
              class="btn btn-primary-outline float-end"
              @click.prevent="setJob(null)"
            >
              X
            </button>
            <h1>Job</h1>
            <h4>{{ current.title }}</h4>
            <p>Execution log:</p>
            <div class="job-monitor-log" v-html="current.execution_log"></div>
          </div>
        </div>
      </div>
      <div class="job-monitor-footer">
        <button
          class="btn btn-outline-primary btn-sm w-100"
          @click="showAllJobs"
        >
          Show All
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'job-monitor',

  data () {
    return {
      isShown: false,
      pollTimer: null,
      isCancelling: null,
      current: null,
      jobNotificationCandidates: [],
      notificationTypes: {
        "generate_contact_email_openers_for_search": {
          "successMessage": "Congratulations! Your contact email openers have been brilliantly generated!",
          "failureMessage": "We couldn't generate the contact email openers. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "generate_career_email_openers_for_search": {
          "successMessage": "Fantastic job! Your career email openers are ready to impress!",
          "failureMessage": "We couldn't generate the career email openers. Please retry.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "generate_manager_email_openers_for_search": {
          "successMessage": "Excellent work! Your manager email openers are crafted and ready to go!",
          "failureMessage": "We couldn't generate the manager email openers. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "fetch_insights": {
          "successMessage": "Congratulations! Your valuable insights have been successfully retrieved!",
          "failureMessage": "We couldn't fetch the insights. Please try again later.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "fetch_contact_insights": {
          "successMessage": "Great news! Your contact insights are now at your fingertips!",
          "failureMessage": "We couldn't fetch the contact insights. Please retry.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "push_saved_search_to_replyio": {
          "successMessage": "Wonderful! Your saved search has been successfully pushed to Reply.io!",
          "failureMessage": "We couldn't push the saved search to Reply.io. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "generate_csv_from_save_search": {
          "successMessage": "Congratulations! Your CSV from the saved search is ready for download!",
          "failureMessage": "We couldn't generate the CSV from the saved search. Please retry.",
          "CTAButton": "Download CSV",
          "CTAAction": "investors_searches",
        },
        "calculate_investor_match_score": {
          "successMessage": "Fantastic! Your investor match score has been successfully calculated!",
          "failureMessage": "We couldn't calculate the investor match score. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "save_companies_and_contacts_to_save_search": {
          "successMessage": "Great job! Companies and contacts have been saved to your search successfully!",
          "failureMessage": "We couldn't save the companies and contacts to the search. Please retry.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "generate_warm_intros_for_search": {
          "successMessage": "Congratulations! Your warm introductions are ready to make connections!",
          "failureMessage": "We couldn't generate the warm introductions. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "make_online_company_portfolio": {
          "successMessage": "Bravo! Your online company portfolio has been beautifully created!",
          "failureMessage": "We couldn't create the online company portfolio. Please retry.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "fetch_location_and_sectors_of_company": {
          "successMessage": "Excellent! Company location and sectors have been successfully retrieved!",
          "failureMessage": "We couldn't fetch the company location and sectors. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "fetch_email_account_messages": {
          "successMessage": "Great news! Your email account messages have been successfully retrieved!",
          "failureMessage": "We couldn't fetch the email account messages. Please retry.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "fetch_responses_from_sent_mail": {
          "successMessage": "Wonderful! Responses from your sent mail have been successfully gathered!",
          "failureMessage": "We couldn't fetch the responses from sent mail. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "send_email_to_saved_search_contacts": {
          "successMessage": "Congratulations! Your email has been sent to all saved search contacts!",
          "failureMessage": "We couldn't send the email to saved search contacts. Please retry.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "push_saved_search_contacts_to_apollo_io": {
          "successMessage": "Excellent work! Your saved search contacts are now in Apollo.io!",
          "failureMessage": "We couldn't push the saved search contacts to Apollo.io. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "enrich_saved_search_contacts_with_apollo_io": {
          "successMessage": "Fantastic! Your saved search contacts are now enriched with Apollo.io data!",
          "failureMessage": "We couldn't enrich the saved search contacts with Apollo.io data. Please retry.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        },
        "astel_network_data_request": {
          "successMessage": "Great job! Your Astel network data request has been successfully completed!",
          "failureMessage": "We couldn't complete the Astel network data request. Please try again.",
          "CTAButton": "Mark as Read",
          "CTAAction": null,
        }
      }
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      jobs: state => state.investors.jobs,
      currentJobNotification: state => state.investors.currentJobNotification,
      showNextNotification: state => state.investors.showNextNotification,
    }),
    activeJobs() {
      if (!this.jobs) {
        return []
      }
      return this.jobs.filter(job => job.is_active)
    },
    pastJobs() {
      if (!this.jobs) {
        return []
      }
      return this.jobs.filter(job => !job.is_active)
    }
  },

  mounted () {
    this.$store.commit('SET_NOTIFICATION', null);
    this.$store.commit('SET_SHOW_NEXT_NOTIFICATION', true);
    this.load()
    this.poll()
  },

  beforeUnmount() {
    if (this.pollTimer) {
      clearTimeout(this.pollTimer)
    }
  },

  methods: {
    showAllJobs() {
      this.$router.push({ name: 'jobs' });
    },
    load() {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('LOAD_BACKGROUND_JOBS')
            .then(result => {
              // Create notification if there are any jobs
              this.checkAndGenerateNotification();

              // Resolve the promise with the result
              resolve(result);
            })
            .catch(error => {
              // Handle any errors
              console.error('Error loading background jobs:', error);
              reject(error);
            });
      });
    },

    getNotificationDetail(type){
      if (this.currentJobNotification !== null) {
        return this.currentJobNotification[type];
      }
      return '';
    },

    markNotificationAsRead(){
      return new Promise((resolve, reject) => {
        this.$store.dispatch('MARK_JOB_NOTIFICATION_AS_READ', this.currentJobNotification.id)
          .then(response => {
            // If the action is successful, resolve the promise
            resolve(response);
          })
          .catch(error => {
            // If there's an error, reject the promise
            reject(error);
          });
      });
    },

    showNextNotificationMethod(){
      this.$store.commit('SET_SHOW_NEXT_NOTIFICATION', true);
    },

    checkAndGenerateNotification(){
      if (this.jobs.length > 0 && this.currentJobNotification === null) {
        this.jobNotificationCandidates = this.jobs.filter(item => item.is_completed && !item.status == 'READ');
        if (this.jobNotificationCandidates.length > 0 && this.showNextNotification) {
          const selectedJob = this.jobNotificationCandidates[0];
          this.$store.commit('SET_NOTIFICATION', selectedJob);
          const nofiticationDetail = this.notificationTypes[selectedJob.job_type];
          if (selectedJob.is_completed){
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: nofiticationDetail.successMessage,
              confirmButtonText: nofiticationDetail.CTAButton,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              backdrop: `
                rgba(0,0,0,0.4)
                left top
                no-repeat
              `,
              preConfirm: () => {
                // Custom action here
                return this.markNotificationAsRead()
                  .then(response => {
                    // Handle successful response
                    if (nofiticationDetail.CTAAction !== null){
                      this.$store.commit('SET_SHOW_NEXT_NOTIFICATION', false);
                      this.$store.commit('SET_NOTIFICATION', null);
                      this.$router.push({ name: nofiticationDetail.CTAAction });
                    }else{
                      this.$store.commit('SET_SHOW_NEXT_NOTIFICATION', true);
                    }
                  })
                  .catch(error => {
                    // Handle errors
                    Swal.showValidationMessage(
                      "Request failed: Sorry could not mark it as read!"
                    )
                  })
              }
            });
          } else if (selectedJob.is_completed){
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: nofiticationDetail.failureMessage,
              confirmButtonText: nofiticationDetail.CTAButton,
              showConfirmButton: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              backdrop: `
                rgba(0,0,0,0.4)
                left top
                no-repeat
              `,
              preConfirm: () => {
                // Custom action here
                return this.markNotificationAsRead()
                  .then(response => {
                    // Handle successful response
                    return response
                  })
                  .catch(error => {
                    // Handle errors
                    Swal.showValidationMessage(
                      "Request failed: Sorry could not mark it as read!"
                    )
                  })
              }
            });
          }
        }
      }
    },

    poll() {
      this.pollTimer = setTimeout(() => {
        this.load()
          .finally(() => {
            this.poll()
          })
      }, 5000)
    },

    setJob(job) {
      // Execution logs makes the locationStorage full hence, 
      // not storing it in the state by default to make it full 
      // in no time. Hence an API call to only get this in the
      // event of user opening up the job details.
      if (job !== null){  
        this.$store.dispatch('RETRIEVE_JOB', job.id)
          .then(retrievedJob => {
          this.current = retrievedJob.data;
        })
        .catch(error => {
          console.log(error);
          this.current = job;
        })
      } else {
        this.current = null;
      }
    },

    cancelJob(jobId) {
      this.isCancelling = jobId
      return this.$store.dispatch('CANCEL_BACKGROUND_JOB', jobId)
    }
  },

  // watch: {
  //   jobs() {
  //     if (this.current) {
  //       this.current = this.jobs.find(job => job.id === this.current.id)
  //     }
  //   }
  // }
}
</script>
