<template>
  <nav>
    <ul class="pagination justify-content-center">
      <li class="previous page-item" v-if="!isInFirstPage">
        <a class="page-link" href="#" @click.prevent="onClickFirstPage">
          <span aria-hidden="true">«</span>
          first
        </a>
      </li>
      <li class="previous page-item" v-if="!isInFirstPage">
        <a class="page-link" href="#" @click.prevent="onClickPreviousPage">
          <span aria-hidden="true">«</span>
          previous
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: isPageActive(page.name) }"
        v-for="page in pages"
      >
        <a
          class="page-link"
          href="#"
          @click.prevent="onClickPage(page.name)"
        >
          {{ page.name }}
        </a>
      </li>
      <li class="next page-item" v-if="!isInLastPage">
        <a class="page-link" href="#" @click.prevent="onClickNextPage">
          next
          <span aria-hidden="true">»</span>
        </a>
      </li>
      <li class="previous page-item" v-if="!isInLastPage">
        <a class="page-link" href="#" @click.prevent="onClickLastPage">
          last
          <span aria-hidden="true">»</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    items: {
      type: Object,
      default: null
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 9
    },
    perPage: {
      type: Number,
      required: false,
      default: 20
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    totalPages() {
      if (this.items && this.items.count) {
        const pageSize = 20
        return Math.ceil(this.items.count / pageSize)
      }
      return 1
    },
    startPage() {
      if (this.currentPage === 1) {
        return 1
      }

      if (this.currentPage === this.totalPages) {
        const start = this.totalPages - (this.maxVisibleButtons - 1);

        if (start <= 0) {
          return 1;
        } else {
          return start;
        }
      }

      return this.currentPage - 1
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      )
    },
    pages() {
      const range = []

      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        })
      }

      return range
    },
    isInFirstPage() {
      return this.currentPage === 1
    },
    isInLastPage() {
      return this.currentPage === this.totalPages
    }
  },
  methods: {
    onClickFirstPage() {
      this.$emit("pagechanged", 1)
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1)
    },
    onClickPage(page) {
      this.$emit("pagechanged", page)
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1)
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages)
    },
    isPageActive(page) {
      return this.currentPage === page
    }
  }
}
</script>