<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">Startups</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card">
        <div class="card-body">
          <div class="row">
              <div class="col-12">
                <div class="table-data">
                  <div class="table-container">
                    <table class="table table-hover">
                      <thead>
                        <tr>
                          <th class="orderable">
                            <a href="?sort=">Name</a>
                          </th>
                          <th class="orderable">
                            <a href="?sort=">Description</a>
                          </th>
                          <th class="orderable">
                            <a href="?sort=">Registered country</a>
                          </th>
                          <th class="orderable">
                            <a href="?sort=">Current investment stage</a>
                          </th>
                        </tr>
                      </thead>
                      <tbody v-if="startups && startups.results">
                        <tr v-for="startup in startups.results" :key="startup.id">
                          <td>
                            <router-link :to="`/startups/${startup.id}/`">{{ startup.name }}</router-link>
                          </td>
                          <td>
                            {{ startup.description || '—' }}
                          </td>
                          <td>
                            {{ startup.registered_country || '—' }}
                          </td>
                          <td>
                            {{ startup.current_investment_stage || '—' }}
                          </td>
                        </tr>
                    </tbody>
                  </table>

                  <pagination
                    :items="startups"
                    :currentPage="page"
                    :perPage="perPage"
                    @pagechanged="onPageChanged"
                  >
                  </pagination>

                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'startups',

  data() {
    return {
      page: 1,
      perPage: 20
    }
  },

  computed: {
    ...mapState({
      startups: state => state.startups.startups
    })
  },

  mounted() {
    this.$store.dispatch('LOAD_STARTUPS')
  },

  methods: {
    onPageChanged(pageNumber) {
      const offset = (pageNumber - 1) * this.perPage
      this.$store.dispatch('LOAD_STARTUPS', { offset })
        .then(() => {
          this.page = pageNumber
        })
    }
  }
}
</script>
