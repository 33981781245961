<template>
    <div>
      <app-header></app-header>
      <div class="m-4">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h1 class="mb-0">Credit Usage History</h1>
            <button class="btn btn-primary" @click="refreshCreditHistory" :disabled="isLoading">
              <span v-if="isLoading" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
              <i v-else class="uil uil-sync me-1"></i>
              Refresh
            </button>
          </div>
          <div class="card-body">
            <div v-if="isLoading" class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
            <div v-else-if="error" class="alert alert-danger">Error: {{ error }}</div>
            <div v-else>
              <table class="table table-hover table-fixed">
                <thead>
                  <tr>
                    <th class="col-id">ID</th>
                    <th class="col-title">Title</th>
                    <th class="col-progress">Credits Used</th>
                    <th class="col-date">Credits Used At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="usage in creditHistoryUsage?.results" :key="usage.id">
                    <td>{{ usage.id }}</td>
                    <td>[#{{ usage.search }}] {{ usage.search_name }}</td>
                    <td>{{ usage.download_count }}</td>
                    <td>{{ $filters.datetimeformat(usage.created_at) }}</td>
                  </tr>
                </tbody>
              </table>
  
              <pagination
                :items="creditHistoryUsage"
                :currentPage="page"
                :perPage="perPage"
                @pagechanged="onPageChanged"
              >
              </pagination>
            </div>
          </div>
        </div>
      </div>
      <app-footer></app-footer>
      <div class="modal fade" id="logsModal" tabindex="-1" aria-labelledby="logsModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header bg-dark text-light">
              <h5 class="modal-title" id="logsModalLabel">
                <i class="bi bi-terminal"></i> Logs for Job {{ selectedJob?.id }}
              </h5>
              <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-0">
              <div class="shell-output">
                <pre><code>{{ selectedJob?.execution_log || 'No logs available for this usage.' }}</code></pre>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapState } from 'vuex'
  import { Modal } from 'bootstrap'
  
  import Header from '@/components/Layout/Header.vue'
  import Footer from '@/components/Layout/Footer.vue'
  
  export default {
    name: 'Jobs',
    components: {
      'app-header': Header,
      'app-footer': Footer,
    },
    data() {
      return {
        page: 1,
        perPage: 15,
        isLoading: false,
        error: null,
        selectedJob: null,
        modal: null,
        isCancelling: null,
      }
    },
    computed: {
      ...mapState({
        creditHistoryUsage: state => state.investors.creditHistoryUsage,
      }),
    },
    created() {
      this.loadCreditHistory()
    },
    mounted() {
      this.modal = new Modal(document.getElementById('logsModal'))
    },
    methods: {
      openLogsModal(job) {
        this.selectedJob = job
        this.modal.show()
      },
      getTimestamp(job) {
        if (usage.is_completed && usage.completed) return `Completed at ${this.$filters.datetimeformat(usage.completed)}`
        if (usage.is_cancelled && usage.cancelled_at) return `Cancelled at ${this.$filters.datetimeformat(usage.cancelled_at)}`
        if (usage.is_failed && usage.failed_at) return `Failed at ${this.$filters.datetimeformat(usage.failed_at)}`
        return usage.is_active ? 'In progress' : ''
      },
      refreshCreditHistory() {
        this.loadCreditHistory();
      },
      loadCreditHistory() {
        this.isLoading = true
        this.error = null
        this.$store.dispatch('LOAD_CREDIT_HISTORY', { page: this.page, page_size: this.perPage })
          .then(() => {
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            this.error = error.message || 'An error occurred while loading notifications'
          })
      },
      onPageChanged(pageNumber) {
        this.page = pageNumber
        this.loadCreditHistory()
      },
      getStatusBadgeClass(job) {
        if (usage.is_completed) return 'text-bg-success'
        if (usage.is_failed) return 'text-bg-danger'
        if (usage.is_cancelled) return 'text-bg-warning'
        if (usage.is_active) return 'text-bg-primary'
        return 'text-bg-secondary'
      },
      getStatusClass(job) {
        if (usage.is_completed) return 'status-completed'
        if (usage.is_failed) return 'status-failed'
        if (usage.is_cancelled) return 'status-cancelled'
        if (usage.is_active) return 'status-active'
        return 'status-pending'
      },
      getStatusText(job) {
        if (usage.is_completed) return 'Completed'
        if (usage.is_failed) return 'Failed'
        if (usage.is_cancelled) return 'Cancelled'
        if (usage.is_active) return 'Active'
        return 'Pending'
      },
      formatDate(dateString) {
        return new Date(dateString).toLocaleString()
      },
      cancelJob(jobId) {
        this.isCancelling = jobId
        return this.$store.dispatch('CANCEL_BACKGROUND_JOB', jobId)
          .then(() => {
            this.loadCreditHistory()
          })
          .finally(() => {
            this.isCancelling = null
          })
      },
    },
    watch: {
      jobs() {
        if (this.selectedJob) {
          this.selectedJob = this.jobs.find(job => usage.id === this.selectedusage.id)
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .jobs-container {
    padding: 20px;
  }
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  .table-fixed {
    table-layout: fixed;
    width: 100%;
  }
  
  .col-id { width: 5%; }
  .col-title { width: 20%; }
  .col-progress { width: 6%; }
  .col-status { width: 10%; }
  .col-date { width: 15%; }  /* Increased from 12% to 15% */
  .col-timestamp { width: 15%; }  /* Increased from 15% to 19% */
  .col-actions { width: 10%; }
  
  .table th, .table td {
    word-wrap: break-word;
    white-space: normal;
  }
  
  .table th {
    vertical-align: top;
  }
  
  .table td {
    vertical-align: middle;
  }
  
  .table td.timestamp {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .status-completed { color: green; }
  .status-failed { color: red; }
  .status-cancelled { color: orange; }
  .status-active { color: blue; }
  .status-pending { color: gray; }
  .modal-body {
    padding: 0 !important;
  }
  
  .shell-output {
    background-color: #1e1e1e;
    color: #f0f0f0;
    font-family: 'Courier New', Courier, monospace;
    padding: 1rem;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .shell-output pre {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .shell-output code {
    display: block;
    line-height: 1.5;
  }
  </style>