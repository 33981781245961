<template>
  <div>
    <div v-if="contact">

      <div class="row">
        <div class="col pe-0">

          <div class="card">
            <div class="card-body">
              
              <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-2 float-end">
                <a href="#" class="btn btn-outline-primary" @click.prevent="goView">
                  <i class="uil uil-eye me-1"></i>
                  <span>View</span>
                </a>
                <a href="#" class="btn btn-outline-danger" @click.prevent="goDelete">
                  <i class="uil uil-trash me-1"></i>
                  <span>Delete</span>
                </a>
              </div>
              
              <h3>{{ contact.first_name }} {{ contact.last_name }}</h3>
              <h4 class="header-title mt-3">Contact Profile</h4>

              <div class="form-group mb-3">
                <label for="id_first_name">First name:</label>
                <input id="id_first_name" type="text" name="first_name" class="form-control" required v-model="contact.first_name">

                <p v-if="errors.first_name && errors.first_name.length">
                  <span v-for="error in errors.first_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_last_name">Last name:</label>
                <input id="id_last_name" type="text" name="last_name" class="form-control" required v-model="contact.last_name">

                <p v-if="errors.last_name && errors.last_name.length">
                  <span v-for="error in errors.last_name" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_email">Email:</label>
                <input id="id_email" type="text" name="email" class="form-control" required v-model="contact.email">

                <p v-if="errors.email && errors.email.length">
                  <span v-for="error in errors.email" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_first_name">Title:</label>
                <v-select
                    name="id_title"
                    v-model="contact.title"
                    :options="optionsTitles"
                    :reduce="x => x.id"
                    label="name"
                    multiple
                  >
                  </v-select>
              </div>

              <div class="form-group mb-3">
                <label for="id_phone_number">Phone Number:</label>
                <input id="id_phone_number" type="text" name="phone_number" class="form-control" required v-model="contact.phone_number">

                <p v-if="errors.phone_number && errors.phone_number.length">
                  <span v-for="error in errors.phone_number" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_linkedIn_URL">LinkedIn:</label>
                <input id="id_linkedIn_URL" type="text" name="linkedIn_URL" class="form-control" required v-model="contact.linkedIn_URL">

                <p v-if="errors.linkedIn_URL && errors.linkedIn_URL.length">
                  <span v-for="error in errors.linkedIn_URL" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_linkedIn_about">About:</label>
                <input id="id_linkedIn_about" type="text" name="linkedIn_about" class="form-control" required v-model="contact.linkedIn_about">

                <p v-if="errors.linkedIn_about && errors.linkedIn_about.length">
                  <span v-for="error in errors.linkedIn_about" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3" v-if="isStartupContactPage || contact.startup">
                <label for="id_deliverable">Outreach sent from this contact?</label>
                <input id="id_deliverable" type="checkbox" name="deliverable" class="ms-2" v-model="contact.is_startup_outreach_contact" >
                <p v-if="errors.is_startup_outreach_contact && errors.is_startup_outreach_contact.length">
                    <span v-for="error in errors.is_startup_outreach_contact" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

              <div class="form-group mb-3">
                <label for="id_deliverable">Deliverable:</label>
                <input id="id_deliverable" type="checkbox" name="deliverable" class="ms-2" required v-model="contact.deliverable" >
                <p v-if="errors.deliverable && errors.deliverable.length">
                    <span v-for="error in errors.deliverable" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
              <div class="form-group mb-3">
                <label for="id_unsubscribe">Unsubscribe:</label>
                <input id="id_unsubscribe" type="checkbox" name="unsubscribe" class="ms-2" required v-model="contact.unsubscribe" >
                <p v-if="errors.unsubscribe && errors.unsubscribe.length">
                    <span v-for="error in errors.unsubscribe" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>
         
             <div class="form-group mb-3">
                <label for="id_is_deal_dashboard">Deal Dashboard:</label>
                <input id="id_is_deal_dashboard" type="checkbox" name="is_deal_dashboard" class="ms-2" required v-model="contact.is_deal_dashboard" >
                <p v-if="errors.is_deal_dashboard && errors.is_deal_dashboard.length">
                    <span v-for="error in errors.is_deal_dashboard" :key="error" class="text-danger">{{ error }}</span>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
    <div v-else class="text-center">
      <loader></loader>
    </div>

    <div class="action-footer">
      <div class="container-fluid">
        <div class="card mb-0">
          <div class="card-body">
            <div class="float-end">
              <div v-if="hasError" class="text-danger me-2">Error. Please check the form.</div>
              <button class="btn btn-primary" :class="{ disabled: isSubmitting }" @click.prevent="submit">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'contact',

  data() {
    return {
      contact: null,
      errors: {},
      isSubmitting: false
    }
  },

  computed: {
    ...mapState({
      optionsTitles: state => state.investors.titles,
    }),
    contactId() {
      return this.$route.params.id || (this.contact ? this.contact.id : null)
    },
    hasError() {
      return Object.keys(this.errors).length > 0
    },
    isStartupContactPage() {
      return !!this.$route.params.startup_id
    },
  },
  
  mounted() {
    window.scrollTo(0, 0)

    if (this.contactId) {
      Promise.all([
        this.$store.dispatch('LOAD_SEARCH_OPTIONS'),
        this.$store.dispatch('LOAD_CONTACT', { contactId: this.contactId })
          .then((response) => {
            this.contact = response.data
          })
      ]).then(() => {
        this.contact.title = this.contact.title.map(x => x.id)
      })
    } else {
      this.contact = {}
      this.$store.dispatch('LOAD_SEARCH_OPTIONS')
    }
  },

  methods: {
    goView() {
      this.$router.push(`/contacts/${this.contact.id}`)
    },
    goEdit() {
      this.$router.push(`/contacts/${this.contact.id}/edit`)
    },
    goDelete() {
      this.$router.push(`/contacts/${this.contact.id}/delete`)
    },
    submit () {
      this.isSubmitting = true
      this.errors = {}

      if (this.contact.company && this.contact.company.id) {
        this.contact.company = this.contact.company.id
      }

      if (this.$route.params.startup_id) {
        this.contact.startup = this.$route.params.startup_id
      }

      if (this.contactId) {
        this.$store.dispatch('UPDATE_CONTACT', this.contact)
          .then(() => {
            this.isSubmitting = false
            this.$router.go(-1)
          })
          .catch((error) => {
            this.isSubmitting = false
            this.errors = error.response.data
          })
      } else {
        this.contact.created_date = new Date()

        this.$store.dispatch('CREATE_CONTACT', this.contact)
          .then((response) => {
            this.isSubmitting = false
            this.$router.go(-1)
          })
          .catch((error) => {
            this.isSubmitting = false
            this.errors = error.response.data
          })
      }
    }
  }
}
</script>
